import { config, useSpring } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import ExpertisePersonProjects from "state/search/expertise/ExpertisePersonProjects";
import { Result } from "state/search/expertise/ExpertiseSearch";

import Container from "./Container";
import Message from "./Message";
import ProjectDisplay from "./ProjectDisplay";

export default function Projects({ person }: { person: Partial<Result> }) {
  const { t } = useTranslation();
  const projects = useRecoilValue(
    ExpertisePersonProjects(person.userAlias as string)
  );
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { ...config.gentle, clamp: true },
  });
  return (
    <Container style={spring}>
      {projects.length > 0 ? (
        projects.map((project, index) => (
          <ProjectDisplay
            project={project}
            userAlias={person.userAlias as string}
            key={index}
          />
        ))
      ) : (
        <Message>{t("No projects found")}</Message>
      )}
    </Container>
  );
}
