import { rest } from "msw";

import { useUrl } from "services/network";
import { apiDelay, apiStatus, apiResponse } from "services/testing";
import {
  Request,
  Response,
  endpoint,
} from "state/search/external/ExternalSearch";

import page1 from "../data/externalSearch/page1";
import page2 from "../data/externalSearch/page2";
import page3 from "../data/externalSearch/page3";

const pages = [page1, page2, page3];

export default rest.post<Request, Response>(
  `${useUrl}${endpoint}`,
  function (req, res, ctx) {
    const page = req.body.page;
    return res(
      ctx.delay(apiDelay(endpoint, 100)),
      ctx.status(apiStatus(endpoint, 200)),
      ctx.json(apiResponse(endpoint, pages[page] || null))
    );
  }
);
