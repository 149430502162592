import styled from "styled-components/macro";

export default styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  place-items: center start;
  width: 100%;
  margin-bottom: ${({ theme }) => `${theme.spacing(0.5)}px`};
`;
