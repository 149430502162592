import { REFINER_BANNER_CONSTANTS } from "services/constants";
import styled from "styled-components";

const Container = styled.div`
  height: 40px;
  width: 100vw;
  background-color: ${REFINER_BANNER_CONSTANTS.SKY_SOLID_50};
  display: flex;
  gap: 16px;
  align-items: center;
`;

export default Container;
