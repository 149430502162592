import { useTranslation } from "react-i18next";
import DropDown from "components/DropDown";

import TreeFilter from "./TreeFilter";
import { amplitudeTracker } from "services/helpers";
import { AMPLITUDE_EVENTS } from "services/constants";

export interface Dirty {
  isDirty: boolean;
  setDirty: () => void;
}

function SourceFilters() {
  const { t } = useTranslation();
  return (
    <DropDown
      amplitudeFunc={() => amplitudeTracker(AMPLITUDE_EVENTS.FILTER_BY_SOURCE_LIBRARY)}
      label={<>{t("Sharepoint Sites & Libraries")}</>}
      align="right"
    >
      {({ close }) => <TreeFilter close={close} />}
    </DropDown>
  );
}

export default SourceFilters;
