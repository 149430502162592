import { useState, useRef, ReactNode, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useClickAway, useKey } from "react-use";
import StyledArrowDropUpIcon from "./StyledArrowDropUpIcon";
import StyledArrowDropDownIcon from "./StyledArrowDropDownIcon";

import LoadingDisplay from "components/LoadingDisplay";
import ErrorDisplay from "components/ErrorDisplay";

import Container from "./Container";
import Content from "./Content";
import Label from "./Label";

interface DropDownProps {
  children: (props: { close: () => void }) => ReactNode;
  label: ReactNode;
  lineHeight?: number;
  amplitudeFunc?: () => void;
  align?: "left" | "right";
  border?: boolean;
  style?: any;
  className?: string;
  ErrorDisplay?: any;
}

function DropDown({
  children,
  label,
  lineHeight,
  amplitudeFunc,
  align = "left",
  border = true,
  ErrorDisplay: ErrorComponent = ErrorDisplay,
  ...props
}: DropDownProps) {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useKey("Escape", close);
  useClickAway(containerRef, close);

  function toggle() {
    if (amplitudeFunc) {
      amplitudeFunc();
    }
    setIsOpen(!isOpen);
  }
  function close() {
    setIsOpen(false);
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <Container
        onClick={toggle}
        open={isOpen}
        ref={containerRef}
        border={border}
        {...props}
      >
        <Label lineHeight={lineHeight}>
          {label}
          {isOpen ? <StyledArrowDropUpIcon /> : <StyledArrowDropDownIcon />}
        </Label>
        <Content
          open={isOpen}
          align={align}
          onClick={(event: MouseEvent) => event.stopPropagation()}
        >
          <Suspense
            fallback={<LoadingDisplay style={{ minHeight: "100px" }} />}
          >
            {children({ close })}
          </Suspense>
        </Content>
      </Container>
    </ErrorBoundary>
  );
}

export default DropDown;
