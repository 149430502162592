import { Response } from "state/search/all/AllSearch";

const documents: Response = [
  {
    source: "SPO",
    rowCount: 3,
    totalRows: 3,
    totalRowsIncludingDuplicates: 3,
    refinerResults: [
      {
        entries: [
          {
            RefinementCount: 1,
            RefinementName: "docx",
            RefinementToken: '"ǂǂ646f6378"',
            RefinementValue: "docx",
          },
          {
            RefinementCount: 2,
            RefinementName: "pptx",
            RefinementToken: '"ǂǂ70707478"',
            RefinementValue: "pptx",
          },
        ],
        name: "FileType",
      },
      {
        entries: [
          {
            RefinementCount: 3,
            RefinementName: "Older than a year",
            RefinementToken: "range(min, 2020-08-27T00:00:00.0000000Z)",
            RefinementValue: "Before 2020-08-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Year",
            RefinementToken:
              "range(2020-08-27T00:00:00.0000000Z, 2021-07-27T00:00:00.0000000Z)",
            RefinementValue:
              "From 2020-08-27T00:00:00.0000000Z up to 2021-07-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Month",
            RefinementToken:
              "range(2021-07-27T00:00:00.0000000Z, 2021-08-26T00:00:00.0000000Z)",
            RefinementValue:
              "From 2021-07-27T00:00:00.0000000Z up to 2021-08-26T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past 24 hours",
            RefinementToken:
              'range(2021-08-26T00:00:00.0000000Z, max, to="le")',
            RefinementValue: "2021-08-26T00:00:00.0000000Z or later",
          },
        ],
        name: "Write",
      },
    ],
    results: [
      {
        docId: 17597524164584,
        dlcDocId: "AAAAA1351-724471295-180",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaaa1351",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={beefc59a-656e-4a88-a50a-1694c476c899}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Birbiglia, Gino V SEPCO-UPX/W/G",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaaa1351",
        siteTitle: "Exploration Conference",
        people:
          "Gino.Birbiglia@shell.com | Birbiglia, Gino V SEPCO-UPX/W/G | 693A30232E667C6D656D626572736869707C67696E6F2E6269726269676C6961407368656C6C2E636F6D i:0#.f|membership|gino.birbiglia@shell.com;Birbiglia, Gino V SEPCO-UPX/W/G",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={beefc59a-656e-4a88-a50a-1694c476c899}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.docx.svg",
        title: "2020 Exploration Conf",
        path: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission/UPXW_GC_Birbiglia_Constraining_NtSd.docx",
        summary:
          "stratigraphic age and relative mini-<strong>basin</strong> position <ddd/> net sand predictions in <strong>basin</strong> margin traps in Gulf <ddd/> ...",
        resultType: "docx",
        size: 6667639,
        write: "2020-04-01T14:54:00.0000000Z",
        rank: 0.0,
      },
      {
        docId: 17603427684450,
        dlcDocId: "AAAAA2582-1528471937-14971",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa1454",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA1454",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAFAA1454/_layouts/15/WopiFrame.aspx?sourcedoc={712cc638-b5e9-4856-8018-e4ae361e2252}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Gino.Birbiglia@shell.com;Bashinski, Felicia A SEPCO-UPD/P/C",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aafaa1454",
        siteTitle: "Olympus OWE",
        people:
          "felicia.a.bashinski@shell.com | Bashinski, Felicia A SEPCO-UPD/P/C | 693A30232E667C6D656D626572736869707C66656C696369612E612E62617368696E736B69407368656C6C2E636F6D i:0#.f|membership|felicia.a.bashinski@shell.com;Gino.Birbiglia@shell.com;Bashinski, Felicia A SEPCO-UPD/P/C",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAFAA1454/_layouts/15/WopiFrame.aspx?sourcedoc={712cc638-b5e9-4856-8018-e4ae361e2252}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA1454/Archive Folder/__HANDOVER/Exploration - Alina",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pptx.svg",
        title: "GSB_Stakeholder_Poster_Session_APR2019",
        path: "https://eu001-sp.shell.com/sites/AAFAA1454/Archive Folder/__HANDOVER/Exploration - Alina/GSB_Stakeholder_Poster_Session_APR2019.pptx",
        summary:
          "Greater Shadow <strong>Basin</strong> Seismic Repro <ddd/> set of opportunities in the Greater Shadow <strong>Basin</strong> <ddd/> ...",
        resultType: "pptx",
        size: 9513057,
        write: "2019-04-24T14:01:27.0000000Z",
        rank: 0.0,
      },
      {
        docId: 17603448094423,
        dlcDocId: "AAAAA2582-1528471937-14876",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa1454",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA1454",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAFAA1454/_layouts/15/Doc.aspx?sourcedoc={205899b0-11c3-4d35-b630-d4104b16c280}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Gino.Birbiglia@shell.com;Bashinski, Felicia A SEPCO-UPD/P/C",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aafaa1454",
        siteTitle: "Olympus OWE",
        people:
          "felicia.a.bashinski@shell.com | Bashinski, Felicia A SEPCO-UPD/P/C | 693A30232E667C6D656D626572736869707C66656C696369612E612E62617368696E736B69407368656C6C2E636F6D i:0#.f|membership|felicia.a.bashinski@shell.com;Gino.Birbiglia@shell.com;Bashinski, Felicia A SEPCO-UPD/P/C",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAFAA1454/_layouts/15/Doc.aspx?sourcedoc={205899b0-11c3-4d35-b630-d4104b16c280}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA1454/Archive Folder/190506_ULT_Engagement_Houston",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pptx.svg",
        title: "190503_Mars_Corridor_Urban_Plan_Poster",
        path: "https://eu001-sp.shell.com/sites/AAFAA1454/Archive Folder/190506_ULT_Engagement_Houston/190503_Mars_Corridor_Urban_Plan_Poster.pptx",
        summary:
          "Maximizing Value of the Mars Corridor \r\nEnd-to-End <ddd/> CORRIDOR – PROLIFIC <strong>BASINS</strong> WITH NUMEROUS STACKED <ddd/> ...",
        resultType: "pptx",
        size: 8114457,
        write: "2019-05-07T00:22:19.0000000Z",
        rank: 0.0,
      },
    ],
    spellingSuggestion: "",
  },
];

export default documents;
