import SharePointSelectedRefiners from "components/Refiners/SharePointSelectedRefiners";
import { selector } from "recoil";
import {
  sourcesToRefiner,
  fileTypeToRefiner,
  dateRangeToRefiner,
  SPSiteUrltoRefiner,
} from "services/helpers";
import DateRange from "state/DateRange";
import FileType from "state/FileType";
import AppliedSources from "state/sources/AppliedSources";
import RedirectState from "state/Redirect";
import SelectedRedirectState from "state/SelectedRedirect";
import { spoGroup } from "./AllSearch";

/**
 * The refiner/filter that is used during the all search request
 */
export default selector<string>({
  key: "AllSearchRefiner",
  get: ({ get }) => {
    let refiner = "";
    const appliedSources = get(AppliedSources);
    const fileType = get(FileType(spoGroup));
    const dateRange = get(DateRange(spoGroup));
    const sharePointRefiners = get(SharePointSelectedRefiners(spoGroup));
    const SPSiteUrlRefiner = get(RedirectState).SPSiteUrl;
    const redirectedIdRefiner = get(SelectedRedirectState).redirectId;

    const finalRedirectRefiner = redirectedIdRefiner
      ? redirectedIdRefiner
      : SPSiteUrlRefiner;

    const refiners = [
      sourcesToRefiner(appliedSources),
      fileTypeToRefiner(fileType),
      dateRangeToRefiner(dateRange),
      SPSiteUrltoRefiner(finalRedirectRefiner),
      ...(sharePointRefiners?.map(
        (r) => `${r.name}:${r.token.replace(/\"/g, "")}`
      ) ?? []),
    ].filter((refiner) => refiner && refiner.length > 0);

    if (refiners.length === 1) {
      refiner = refiners[0] as string;
    } else if (refiners.length > 1) {
      refiner = `and(` + refiners.join(",") + `)`;
    }

    return refiner;
  },
});
