import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";

import ExpertiseSearchPage from "state/search/expertise/ExpertiseSearchPage";
import ExpertiseLayout from "state/search/expertise/ExpertiseLayout";
import ColumnIcon from "assets/ColumnIcon";
import RowIcon from "assets/RowIcon";
import { useRef } from "react";

export default function Layout() {
  const { t } = useTranslation();
  const [layout, setLayout] = useRecoilState(ExpertiseLayout);
  const setExpertisePage = useSetRecoilState(ExpertiseSearchPage);

  const oldLayout = useRef(layout);
  useEffect(() => {
    if (oldLayout.current !== layout) {
      setExpertisePage(0);
      oldLayout.current = layout;
    }
  }, [layout, setExpertisePage]);

  function toggleLayout() {
    setLayout(layout === "column" ? "row" : "column");
  }

  return (
    <IconButton
      aria-label="layout"
      size="medium"
      onClick={toggleLayout}
      title={t("Toggle layout")}
    >
      {layout === "column" ? (
        <ColumnIcon fontSize="inherit" />
      ) : (
        <RowIcon fontSize="inherit" />
      )}
    </IconButton>
  );
}
