import { selectorFamily } from "recoil";
import { AxiosResponse } from "axios";

import { useAPI } from "services/network";
import Query from "state/Query";
import ExternalSearchRefiner from "state/search/external/ExternalSearchRefiner";

export const endpoint = "/api/v1/EbscoApi/pageResults";

export interface Request {
  page: number;
  queryText: string;
  refiner: string;
  scope: "Ebsco";
  source: "EBSCO";
}

export interface Record {
  resultId: string;
  header: {
    dbId: string;
    dbLabel: string;
    an: string;
    relevancyScore: string;
    pubType: string;
    pubTypeId: string;
  };
  pLink: string;
  customLinks?: Array<{
    url: string;
    name: string;
    category: string;
    text: string;
    icon: string;
    mouseOverText: string;
  }> | null;
  fullText: {
    text: {
      availability: string;
    };
  };
  items?: Array<{
    name: string;
    label: string;
    group: string;
    data: string;
  }> | null;
  recordInfo: {
    bibRecord: {
      bibEntity: {
        identifiers?: Array<{
          type: string;
          value: string;
        }> | null;
        dates?: null;
      };
      bibRelationships: {
        isPartOfRelationships?: Array<{
          bibEntity: {
            identifiers?: Array<{
              type: string;
              value: string;
            }> | null;
            dates?: Array<{
              d: number;
              m: number;
              y: number;
              type: string;
            }> | null;
          };
        }> | null;
      };
    };
  };
}

export interface Response {
  searchRequestGet: {
    queryString: string;
    searchCriteriaWithActions: {
      queriesWithAction: Array<{
        query: {
          booleanOperator: string;
          term: string;
        };
        removeAction: string;
      }>;
      facetFiltersWithActions?: null;
    };
  };
  searchResult: {
    statistics: {
      totalHits: number;
      totalSearchTime: string;
      databases: Array<{
        id: string;
        label: string;
        status: string;
        hits: string;
      }>;
    };
    data: {
      recordFormat: string;
      records: Array<Record>;
    };
    availableCriteria: {
      dateRange: {
        minDate: string;
        maxDate: string;
      };
    };
  };
}

export function issueRequest(requestBody: Request) {
  return useAPI.post<Request, AxiosResponse<Response>>(endpoint, requestBody);
}

/**
 * Fetches the external-search result from the backend for a given page
 */
export default selectorFamily({
  key: "ExternalSearch",
  get:
    (page: number) =>
    async ({ get }) => {
      const { data } = await issueRequest({
        page,
        queryText: get(Query),
        refiner: get(ExternalSearchRefiner),
        scope: "Ebsco",
        source: "EBSCO",
      });
      return data;
    },
});
