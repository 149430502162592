import { Response } from "state/search/all/AllSearch";

const documents: Response = [
  {
    source: "SPO",
    rowCount: 4,
    totalRows: 4,
    totalRowsIncludingDuplicates: 4,
    refinerResults: [
      {
        entries: [
          {
            RefinementCount: 1,
            RefinementName: "docx",
            RefinementToken: '"ǂǂ646f6378"',
            RefinementValue: "docx",
          },
          {
            RefinementCount: 3,
            RefinementName: "msg",
            RefinementToken: '"ǂǂ6d7367"',
            RefinementValue: "msg",
          },
        ],
        name: "FileType",
      },
      {
        entries: [
          {
            RefinementCount: 4,
            RefinementName: "Older than a year",
            RefinementToken: "range(min, 2020-08-27T00:00:00.0000000Z)",
            RefinementValue: "Before 2020-08-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Year",
            RefinementToken:
              "range(2020-08-27T00:00:00.0000000Z, 2021-07-27T00:00:00.0000000Z)",
            RefinementValue:
              "From 2020-08-27T00:00:00.0000000Z up to 2021-07-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Month",
            RefinementToken:
              "range(2021-07-27T00:00:00.0000000Z, 2021-08-26T00:00:00.0000000Z)",
            RefinementValue:
              "From 2021-07-27T00:00:00.0000000Z up to 2021-08-26T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past 24 hours",
            RefinementToken:
              'range(2021-08-26T00:00:00.0000000Z, max, to="le")',
            RefinementValue: "2021-08-26T00:00:00.0000000Z or later",
          },
        ],
        name: "Write",
      },
    ],
    results: [
      {
        docId: 17596947954492,
        dlcDocId: "AAAAA1351-724471295-71",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaaa1351",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={1cf2d351-4210-4fed-a3d9-d89c589e6f39}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author:
          "Sally.Gregerson@shell.com;Justin.Ugbo@shell.com;Jason.Robe@shell.com;Andrew.Nolan@shell.com;Nabanita.Das@shell.com;Cherie.Blyth@shell.com;I.Donaldson@shell.com;Michael.Harvey@shell.com;Gregerson, Sally T SDA-STX/L/AU",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaaa1351",
        siteTitle: "Exploration Conference",
        people:
          "Sally.Gregerson@shell.com | Gregerson, Sally T SDA-STX/L/AU | 693A30232E667C6D656D626572736869707C73616C6C792E677265676572736F6E407368656C6C2E636F6D i:0#.f|membership|sally.gregerson@shell.com;Sally.Gregerson@shell.com;Justin.Ugbo@shell.com;Jason.Robe@shell.com;Andrew.Nolan@shell.com;Nabanita.Das@shell.com;Cherie.Blyth@shell.com;I.Donaldson@shell.com;Michael.Harvey@shell.com;Gregerson, Sally T SDA-STX/L/AU",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={1cf2d351-4210-4fed-a3d9-d89c589e6f39}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.docx.svg",
        title: "Bratwurst, the largest Gas Discovery in 2019",
        path: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission/UPX_A_Z_Sally_Gregerson et al. Bratwurst Gas Discovery_Abstract.docx",
        summary:
          "of the Browse <strong>Basin</strong> and focused its efforts to <ddd/> knowledge of the Jurassic Play in the Browse <strong>Basin</strong> <ddd/> ...",
        resultType: "docx",
        size: 1213164,
        write: "2020-03-20T06:13:00.0000000Z",
        rank: 0.0,
      },
      {
        docId: 991203518494895928,
        dlcDocId: "AAFAA3273-1248411372-44539",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa3273",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA3273",
        ServerRedirectedEmbedURL: null,
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Harvey, Michael J SDA-UPX/A",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aafaa3273",
        siteTitle: "Wells",
        people:
          "Michael.Harvey@shell.com | Harvey, Michael J SDA-UPX/A | 693A30232E667C6D656D626572736869707C6D69636861656C2E686172766579407368656C6C2E636F6D i:0#.f|membership|michael.harvey@shell.com;Harvey, Michael J SDA-UPX/A",
        displayUrl: null,
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA3273/WellFile Australia/Crux/LIBRA-1/01. Well Planni/01. Subsurface/Libra-A WDP3 em",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.msg.svg",
        title:
          "Kunert. RE  WDP3 for Libra (AC P-41 year 1 exploration commitment well).msg",
        path: "https://eu001-sp.shell.com/sites/AAFAA3273/WellFile Australia/Crux/LIBRA-1/01. Well Planni/01. Subsurface/Libra-A WDP3 em/Kunert. RE  WDP3 for .MSG",
        summary:
          "J SDA-EPX-A-AU23&quot; &lt;<strong>Michael.Harvey@shell.com</strong>&gt;\r\nCc <ddd/> In terms of final location pls use the coordinates <ddd/> ...",
        resultType: "MSG",
        size: 124416,
        write: "2015-05-28T20:47:51.0000000Z",
        rank: 0.0,
      },
      {
        docId: 991203518635605821,
        dlcDocId: "AAFAA3273-1248411372-44542",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa3273",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA3273",
        ServerRedirectedEmbedURL: null,
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Harvey, Michael J SDA-UPX/A",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aafaa3273",
        siteTitle: "Wells",
        people:
          "Michael.Harvey@shell.com | Harvey, Michael J SDA-UPX/A | 693A30232E667C6D656D626572736869707C6D69636861656C2E686172766579407368656C6C2E636F6D i:0#.f|membership|michael.harvey@shell.com;Harvey, Michael J SDA-UPX/A",
        displayUrl: null,
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA3273/WellFile Australia/Crux/LIBRA-1/01. Well Planni/01. Subsurface/Libra-A WDP3 em",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.msg.svg",
        title:
          "Sutherland. RE  WDP3 for Libra (AC P-41 year 1 exploration commitment well).msg",
        path: "https://eu001-sp.shell.com/sites/AAFAA3273/WellFile Australia/Crux/LIBRA-1/01. Well Planni/01. Subsurface/Libra-A WDP3 em/Sutherland. RE  WDP3 .MSG",
        summary:
          "CN=AUMHA4<strong>Michael.Harvey@shell.com</strong> <ddd/> J SDA-EPX-A-AU23&quot; &lt;<strong>Michael.Harvey@shell.com</strong>&gt;\r\nTo <ddd/> ...",
        resultType: "MSG",
        size: 152064,
        write: "2015-05-28T20:48:50.0000000Z",
        rank: 0.0,
      },
      {
        docId: 991203518687765730,
        dlcDocId: "AAFAA3273-1248411372-44541",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa3273",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA3273",
        ServerRedirectedEmbedURL: null,
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Harvey, Michael J SDA-UPX/A",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aafaa3273",
        siteTitle: "Wells",
        people:
          "Michael.Harvey@shell.com | Harvey, Michael J SDA-UPX/A | 693A30232E667C6D656D626572736869707C6D69636861656C2E686172766579407368656C6C2E636F6D i:0#.f|membership|michael.harvey@shell.com;Harvey, Michael J SDA-UPX/A",
        displayUrl: null,
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA3273/WellFile Australia/Crux/LIBRA-1/01. Well Planni/01. Subsurface/Libra-A WDP3 em",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.msg.svg",
        title:
          "Quint. RE  WDP3 for Libra (AC P-41 year 1 exploration commitment well).msg",
        path: "https://eu001-sp.shell.com/sites/AAFAA3273/WellFile Australia/Crux/LIBRA-1/01. Well Planni/01. Subsurface/Libra-A WDP3 em/Quint. RE  WDP3 for L.MSG",
        summary:
          "<strong>Michael.Harvey@shell.com</strong>&gt;\r\nReturn-Path <ddd/> field in the Browse <strong>Basin</strong>, which will fulfill the <ddd/> ...",
        resultType: "MSG",
        size: 89088,
        write: "2015-05-28T20:48:04.0000000Z",
        rank: 0.0,
      },
    ],
    spellingSuggestion: "",
  },
];

export default documents;
