import styled, { css } from "styled-components/macro";
import chroma from "chroma-js";

interface ContainerInterface {
  active: "true" | "false";
}

const light = css<ContainerInterface>`
  border: ${({ theme, active }) =>
    `1px solid ${
      active === "true"
        ? theme.palette.primary.main
        : chroma
            .mix(
              theme.palette.background.paper,
              theme.palette.text.primary,
              0.4
            )
            .css()
    }`};
`;

const dark = css<ContainerInterface>`
  border: ${({ theme, active }) =>
    `1px solid ${
      active === "true"
        ? theme.palette.primary.main
        : chroma(theme.palette.background.paper).darken(0.5).css()
    }`};
  &:hover {
    border-color: ${({ theme, active }) =>
      active === "true"
        ? theme.palette.primary.main
        : chroma(theme.palette.background.paper).brighten().css()};
  }
`;

const hoverStyle = css<ContainerInterface>`
  transition: all 0.2s ease;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  transform: scale(1.0125);
`;

const activeStyle = css<ContainerInterface>`
  z-index: 3;
  ${hoverStyle}
  &:hover {
    z-index: 3;
  }
`;

export default styled.div<ContainerInterface>`
  position: relative;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  background: ${({ theme }) => theme.palette.background.paper};
  width: 100%;
  place-self: start;
  transform-origin: 50% 0%;
  transition: all 0.1s ease;
  transform: scale(1);

  ${({ theme }) => (theme.palette.type === "light" ? light : dark)}
  ${({ active }) => active === "true" && activeStyle}

  &:hover {
    z-index: 2;
    ${hoverStyle}
  }
`;
