import user1 from "./user1.svg";
import user2 from "./user2.svg";
import user3 from "./user3.svg";
import user4 from "./user4.svg";
import user5 from "./user5.svg";
import user6 from "./user6.svg";
import user7 from "./user7.svg";
import user8 from "./user8.svg";
import user9 from "./user9.svg";
import user10 from "./user10.svg";
import user11 from "./user11.svg";

const userImages = [
  user1,
  user2,
  user3,
  user4,
  user5,
  user6,
  user7,
  user8,
  user9,
  user10,
  user11,
];

export default userImages;
