import { ITEM_CONSTANTS } from "services/constants";
import Checkmark from "./Checkmark";
import Container from "./Container";
import Flexbox from "./Flexbox";
import SubdirectoryArrow from "./SubdirectoryArrow";
import Text from "./Text";

export interface IItem {
  children: string;
  SPSiteBool: boolean;
  clickSelect: () => void;
  active: boolean;
}

const Item = ({ SPSiteBool, children, clickSelect, active }: IItem) => {
  return (
    <Container onClick={clickSelect}>
      <Flexbox>
        {SPSiteBool ? (
          <SubdirectoryArrow
            data-testid={ITEM_CONSTANTS.SUBDIRECTORY_ARROW_TEST_ID}
          />
        ) : null}
        <Text>{children}</Text>
      </Flexbox>
      {active ? (
        <Checkmark data-testid={ITEM_CONSTANTS.CHECKMARK_TEST_ID} />
      ) : null}
    </Container>
  );
};

export default Item;
