import { SelectionOption } from "components/PageControls/FileType";
import { atomFamily, SerializableParam } from "recoil";

/**
 * Stores the file type filter choice
 */
export default atomFamily<SelectionOption | undefined, SerializableParam>({
  key: "FileType",
  default: undefined,
});
