import { config, useSpring } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import ExpertisePersonDocuments from "state/search/expertise/ExpertisePersonDocuments";
import { Result } from "state/search/expertise/ExpertiseSearch";

import Container from "./Container";
import Document from "./Document";
import Message from "./Message";

export default function Documents({ person }: { person: Partial<Result> }) {
  const { t } = useTranslation();
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { ...config.gentle, clamp: true },
  });
  const personDocuments = useRecoilValue(
    ExpertisePersonDocuments(person.sipAddress as string)
  ).results;
  return (
    <Container style={spring}>
      {personDocuments.length > 0 ? (
        personDocuments.map((document, index) => (
          <Document document={document} key={index} />
        ))
      ) : (
        <Message>{t("No documents found")}</Message>
      )}
    </Container>
  );
}
