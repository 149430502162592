import { selectorFamily } from "recoil";
import { AxiosResponse } from "axios";
import { useAPI } from "services/network";
//import { Request, Response, endpoint } from "./ExpertisePersonProjects";
import { Request, Response, endpoint } from "./ExpertiseProjectDetails";

/**
 * Fetches a persons project list from the backend
 */
export default selectorFamily({
  key: "ExpertisePersonProjectDetail",
  get:
    ({ alias, projectName }: { alias: string; projectName: string }) =>
    async () => {
      const requestBody: Request = {
        alias,
        projectName,
      };
      return (
        await useAPI.post<Request, AxiosResponse<Response>>(
          endpoint,
          requestBody
        )
      ).data;
    },
});
