import { atom } from "recoil";
import { SourceNode } from "./SourceTree";

/**
 * Stores the applied selected sources
 */
export default atom<Set<SourceNode>>({
  key: "AppliedSources",
  default: new Set<SourceNode>(),
});
