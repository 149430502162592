import { selector } from "recoil";
import RedirectState from "state/Redirect";
import { Request, endpoint } from "./SiteSearch";
import { SPSiteUrltoRefiner } from "services/helpers";
import { useAPI } from "services/network";
import { AxiosResponse } from "axios";

export interface DepartmentRequest extends Request {
  additionalProperties: { departmentInfo: boolean };
}

export interface IDepartmentInfo {
  departmentId: string;
  departmentName: string;
}

export interface DepartmentResponse {
  results: Array<IDepartmentInfo>;
}

export interface Results extends Array<DepartmentResponse> {}

export default selector({
  key: "DepartmentInfo",
  get: async ({ get }) => {
    const siteId = get(RedirectState).SPSiteUrl;
    const requestBody: DepartmentRequest = {
      hiddenConstraints: SPSiteUrltoRefiner(siteId),
      additionalProperties: { departmentInfo: true },
      rowLimit: 500,
      page: 0,
      queryText: "contentclass:STS_Site",
      scope: "All",
      source: "SPOSites",
    };
    const response = (
      await useAPI.post<Request, AxiosResponse<Results>>(endpoint, requestBody)
    ).data[0]?.results[0];
    return response;
  },
});
