import { useRecoilValue, useSetRecoilState } from "recoil";
import { Suspense, useEffect, useCallback, useState } from "react";
import { useSpring, config } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";

import { useGetPath, useChangeQueryString } from "services/hooks";
import User from "state/auth/User";
import RedirectState from "state/Redirect";
import Query from "state/Query";
import HeaderBarPortal from "state/layout/HeaderBarPortal";
import { path as LandingPath } from "pages/LandingPage";

import HeaderArea from "./HeaderArea";
import Top from "./Top";
import Container from "./Container";
import SearchForm from "./SearchForm";
import TextField from "./TextField";
import LogoLink from "./LogoLink";
import UserDisplay from "./UserDisplay";
import Navigation from "./Navigation";
import SearchIcon from "./SearchIcon";
import HeaderPortal from "./HeaderPortal";
import { amplitudeTracker } from "services/helpers";
import { AMPLITUDE_EVENTS } from "services/constants";
import RefinerBanner from "components/RefinerBanner";
import { useLocation } from "react-router-dom";

function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  const user = useRecoilValue(User);
  const query = useRecoilValue(Query);
  const redirectStateBool = useRecoilValue(RedirectState).redirected;
  const getPath = useGetPath();
  const changeQueryString = useChangeQueryString();
  const [urlBool, setUrlBool] = useState(false);

  useEffect(() => {
    location.pathname === "/all" || location.pathname === "/images"
      ? setUrlBool(true)
      : setUrlBool(false);
  }, [location.pathname, setUrlBool]);

  const setPortal = useSetRecoilState(HeaderBarPortal);
  const portalCallback = useCallback(
    (element: HTMLDivElement) => {
      if (element) {
        setPortal(element);
      }
    },
    [setPortal]
  );

  const formik = useFormik({
    initialValues: {
      query,
    },
    onSubmit: ({ query }) => {
      amplitudeTracker(AMPLITUDE_EVENTS.INPUT_SEARCH, { searchTerm: query });
      changeQueryString({ query });
    },
  });

  // Update the form based on the recoil value changing
  useEffect(
    () => {
      formik.setFieldValue("query", query);
    },
    // eslint-disable-next-line
    [query]
  );

  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { ...config.gentle, clamp: true },
  });

  return (
    <HeaderArea style={spring}>
      <Top>
        <Container>
          {user && (
            <>
              <LogoLink to={getPath(LandingPath)}>{t("APP_NAME")}</LogoLink>
              <SearchForm onSubmit={formik.handleSubmit} autoComplete="off">
                <TextField
                  autoFocus
                  size="small"
                  name="query"
                  placeholder="Search"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.query}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon onClick={formik.submitForm} />
                      </InputAdornment>
                    ),
                  }}
                />
              </SearchForm>
            </>
          )}
          <Suspense fallback={null}>
            <UserDisplay />
          </Suspense>
        </Container>
      </Top>
      <Navigation />
      <HeaderPortal ref={portalCallback} />
      {redirectStateBool && urlBool ? <RefinerBanner /> : null}
    </HeaderArea>
  );
}

export default Header;
