import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import AllSearch, { useSearchSource } from "state/search/all/AllSearch";
import AllSearchPage from "state/search/all/AllSearchPage";
import LoadingDisplay from "components/LoadingDisplay";

import ResultsPage from "./ResultsPage";
import LoadMore from "./LoadMore";

function Results() {
  const firstPageLoadable = useRecoilValueLoadable(AllSearch(0));
  const allSearchPage = useRecoilValue(AllSearchPage);
  const pageList = allSearchPage ? [...Array(allSearchPage).keys()] : [0];
  const spoResult = useSearchSource(
    firstPageLoadable.state === "hasValue" ? firstPageLoadable.contents : [],
    "SPO"
  );
  const hasMorePages = useMemo(() => {
    if (spoResult) {
      const currentRow = allSearchPage * spoResult.rowCount;
      return currentRow < spoResult.totalRows;
    }
    return false;
  }, [allSearchPage, spoResult]);

  return firstPageLoadable.state !== "loading" ? (
    <>
      {pageList.map((page) => (
        <ResultsPage page={page} key={page} />
      ))}
      {hasMorePages && <LoadMore />}
    </>
  ) : (
    <LoadingDisplay style={{ minHeight: "200px" }} />
  );
}

export default Results;
