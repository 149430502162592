import { selector } from "recoil";
import { Node } from "state/treeTypes";
import SharedDriveGroups from "state/search/sharedDrives/SharedDriveGroups";

export interface SharedDrivesTree extends Array<Node> {}

/**
 * Fetches the shared drives access tree from the sift backend
 */
export default selector<SharedDrivesTree>({
  key: "SharedDrivesTree",
  get: async ({ get }) => [
    {
      level: "1",
      text: "Indices",
      children: get(SharedDriveGroups).map((source) => ({
        level: "2",
        text: source.source,
        children: null,
        indexName: source.index_name
      })),
    },
  ],
});
