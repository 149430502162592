import styled from "styled-components/macro";
import TextField from "@material-ui/core/TextField";

export default styled(TextField)`
  background: #f2f2f2;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  width: 100%;
  max-width: 500px;

  input {
    padding-top: ${({ theme }) => `${theme.spacing(1)}px`};
    padding-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
    color: #222;
  }
`;
