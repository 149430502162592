import Container from "./Container";
import env from "services/env";


const ReportData = () => {
    const sendMail = () => {
        const to = env.REACT_APP_REPORT_DATA_TO;
        const cc = env.REACT_APP_REPORT_DATA_CC;
        const subject = "USE: Data Issue Reported";
        const body = 
`Thank you for notifying us of a potential data issue found on the search query: ${window.location.href}
        
To help us address this issue adequately please:

    1. Indicate which items from your search results you would like to report, and why you feel this is a concern (e.g. personal, financial, confidential data or otherwise)

    2. Include a screenshot of your search results or export them to Excel and highlight the impacted items

Our support team will reach out to you.`;
        window.location.href = `mailto:${to}?cc=${cc}&subject=${subject}&body=${encodeURIComponent(body)}`;
    }
    return (
        <Container style={{ display: 'flex', alignItems: 'center' }}>
            <div onClick={sendMail}>Report Data Issue</div>
        </Container>
    );
}

export default ReportData;
