import { useRecoilPreviousValue } from "services/hooks";
import ExternalSearch, { Response } from "state/search/external/ExternalSearch";
import ResultsCountControl from "components/PageControls/ResultsCount";

function ResultsCount() {
  const externalSearch = useRecoilPreviousValue<Response, Response | null>(
    ExternalSearch(0),
    null
  );
  const numResults = externalSearch?.searchResult?.statistics?.totalHits || 0;
  return <ResultsCountControl count={numResults} />;
}

export default ResultsCount;
