import { REFINER_BANNER_CONSTANTS } from "services/constants";
import styled from "styled-components";

export default styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${REFINER_BANNER_CONSTANTS.SKY_DEFAULT};
  margin: 0;
`;
