import { useState } from "react";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import RedirectState from "state/Redirect";
import Query from "state/Query";
import SelectedRedirectState from "state/SelectedRedirect";
import DepartmentInfo, { IDepartmentInfo } from "state/sources/DepartmentInfo";
import { useHistory, useLocation } from "react-router-dom";
import Item from "./Item";
import Container from "./Container";
import InfoIcon from "./InfoIcon";
import Flexbox from "./Flexbox";
import SearchText from "./SearchText";
import StyledButton from "./StyledButton";
import StyledDropDown from "./StyledDropDown";
import SiteNameText from "./SiteNameText";
import { REFINER_BANNER_CONSTANTS } from "services/constants";

const RefinerBanner = () => {
  const location = useLocation();
  const SPSiteData = useRecoilValue(RedirectState);
  const setRedirectedState = useSetRecoilState(SelectedRedirectState);
  const selectedRedirectName = useRecoilValue(
    SelectedRedirectState
  ).redirectName;
  const clearRefinerState = useResetRecoilState(RedirectState);
  const clearRedirectState = useResetRecoilState(SelectedRedirectState);
  const departmentData = useRecoilValueLoadable(DepartmentInfo);
  const query = useRecoilValue(Query);
  const history = useHistory();

  const defaultSelectedRefiner = selectedRedirectName
    ? selectedRedirectName
    : SPSiteData.SPSiteName;

  const [selectedRefiner, setSelectedRefiner] = useState(
    defaultSelectedRefiner
  );

  const clearRefiner = () => {
    clearRefinerState();
    clearRedirectState();
    location.pathname === "/all" || location.pathname === "/images"
      ? history.push(`${location.pathname}?query=${query}`)
      : history.push(`/all?query=${query}`);
  };

  const SITE_NAME_TEXT = SPSiteData.SPSiteName + ".";
  const departmentInfo: IDepartmentInfo = departmentData.contents;
  const departmentError = departmentData.state === "hasError";

  return (
    <Container>
      <Flexbox>
        <InfoIcon />
        <SearchText>{REFINER_BANNER_CONSTANTS.SEARCH_TEXT}</SearchText>
        {departmentInfo.departmentId && !departmentError ? (
          <StyledDropDown
            lineHeight={REFINER_BANNER_CONSTANTS.STYLED_DROPDOWN_LINE_HEIGHT}
            label={selectedRefiner}
          >
            {({ close }) => {
              function select(name: string, url: string) {
                setSelectedRefiner(name);
                setRedirectedState({
                  redirectName: name,
                  redirectId: url,
                });
                close();
              }
              return (
                <>
                  <Item
                    clickSelect={() =>
                      select(
                        departmentInfo.departmentName,
                        departmentInfo.departmentId
                      )
                    }
                    SPSiteBool={false}
                    active={selectedRefiner === departmentInfo.departmentName}
                  >
                    {departmentInfo.departmentName}
                  </Item>
                  <Item
                    clickSelect={() =>
                      select(SPSiteData.SPSiteName, SPSiteData.SPSiteUrl)
                    }
                    SPSiteBool={true}
                    active={selectedRefiner === SPSiteData.SPSiteName}
                  >
                    {SPSiteData.SPSiteName}
                  </Item>
                </>
              );
            }}
          </StyledDropDown>
        ) : (
          <SiteNameText>{SITE_NAME_TEXT}</SiteNameText>
        )}
      </Flexbox>
      <StyledButton onClick={clearRefiner}>
        {REFINER_BANNER_CONSTANTS.CLEAR_FILTER_TEXT}
      </StyledButton>
    </Container>
  );
};

export default RefinerBanner;
