import { rest } from "msw";
import { randomInt } from "services/helpers";

import { useUrl } from "services/network";
import { apiDelay, apiStatus, apiResponse } from "services/testing";
import {
  Request,
  Response,
  endpoint,
} from "state/search/expertise/ExpertiseSearch";

import page1 from "../data/expertiseSearch/page1";
import page2 from "../data/expertiseSearch/page2";
import page3 from "../data/expertiseSearch/page3";
import userImages from "../data/expertiseSearch/userImages";

const pages = [page1, page2, page3];

export default rest.post<Request, Response>(
  `${useUrl}${endpoint}`,
  function (req, res, ctx) {
    const page = req.body.page;
    const responsePage = pages[page % pages.length] || [];

    // Must replace all pictureUrl with a mock image
    responsePage.forEach((result) => {
      if (result.source === "SPOExpertise") {
        result.results = result.results.map((result) => ({
          ...result,
          pictureUrl: userImages[Math.floor(Math.random() * userImages.length)],
          docId: randomInt(11111111111111, 99999999999999),
        }));
      }
    });

    return res(
      ctx.delay(apiDelay(endpoint, 1300)),
      ctx.status(apiStatus(endpoint, 200)),
      ctx.json(apiResponse(endpoint, responsePage))
    );
  }
);
