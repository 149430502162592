import { useEffect } from "react";
import { useRecoilValueLoadable, useRecoilValue } from "recoil";

import { useLogoutUser } from "services/auth";

import Token from "state/auth/Token";
import CurrentTime from "state/CurrentTime";

/**
 * If a users token expires, log them out
 */
function LogoutExpiredUser() {
  const currentTime = useRecoilValue(CurrentTime);
  const token = useRecoilValueLoadable(Token);
  const logoutUser = useLogoutUser();
  useEffect(() => {
    const expires_at =
      token.state === "hasValue" ? token.contents?.expires_at : null;
    const tokenIsExpired = expires_at && currentTime > Date.parse(expires_at);
    if (tokenIsExpired) {
      logoutUser();
    }
  }, [currentTime, token, logoutUser]);
  return null;
}

export default LogoutExpiredUser;
