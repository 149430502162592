import { useTranslation } from "react-i18next";

import { formatDateTime } from "services/helpers";
import { useGetPath } from "services/hooks";
import { Project } from "state/search/expertise/ExpertisePersonProjects";
import { path as ExpertisePath } from "pages/ExpertisePage";

import Container from "./Container";
import Label from "./Label";
import Text from "./Text";
import Link from "./Link";
import Dual from "./Dual";
import Count from "./Count";
import DateDiv from "./Date";

export default function ProjectDetails({ project }: { project: Project }) {
  const { t } = useTranslation();
  const getPath = useGetPath();
  return (
    <Container>
      <Label>{t("Name")}</Label>
      <Dual>
        <Link
          to={getPath(ExpertisePath, {
            query: `${project.ProjectName}`,
            project: String(true),
          })}
          title={project.ProjectName || ""}
          onClick={(event) => event.stopPropagation()}
        >
          {project.ProjectName || "-"}
        </Link>
        <DateDiv>
          <Label>{t("Created")}</Label>
          <div style={{ whiteSpace: "nowrap" }}>
            {formatDateTime(project.CreatedDate || "", "LLLL do yyyy")}
          </div>
        </DateDiv>
      </Dual>
      <Label>{t("Source")}</Label>
      <Dual>
        <Text>
          <span>{project.ProjectSource}</span>
          <Count>
            ({t("Count")}: {project.ProjectCount})
          </Count>
        </Text>
        <DateDiv>
          <Label>{t("Modified")}</Label>
          <Text>
            {formatDateTime(project.ModifiedDate || "", "LLLL do yyyy")}
          </Text>
        </DateDiv>
      </Dual>
    </Container>
  );
}
