import { rest } from "msw";
import { randomInt } from "services/helpers";

import { siftUrl } from "services/network";
import { apiDelay, apiStatus, apiResponse } from "services/testing";
import {
  Request,
  Response,
  endpoint,
} from "state/search/sharedDrives/SharedDrives";

import page1 from "../data/sharedDrives/page1";

const pages = [page1];

export default rest.post<Request, Response>(
  `${siftUrl}${endpoint}`,
  function (req, res, ctx) {
    const page = randomInt(1, pages.length);
    const responseBody = pages[page % pages.length] || [];
    return res(
      ctx.delay(apiDelay(endpoint, 1300)),
      ctx.status(apiStatus(endpoint, 200)),
      ctx.json(apiResponse(endpoint, responseBody))
    );
  }
);
