import { Response } from "state/search/all/AllSearch";

const documents: Response = [
  {
    source: "SPO",
    rowCount: 4,
    totalRows: 4,
    totalRowsIncludingDuplicates: 4,
    refinerResults: [
      {
        entries: [
          {
            RefinementCount: 1,
            RefinementName: "Older than a year",
            RefinementToken: "range(min, 2020-08-27T00:00:00.0000000Z)",
            RefinementValue: "Before 2020-08-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 3,
            RefinementName: "Past Year",
            RefinementToken:
              "range(2020-08-27T00:00:00.0000000Z, 2021-07-27T00:00:00.0000000Z)",
            RefinementValue:
              "From 2020-08-27T00:00:00.0000000Z up to 2021-07-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Month",
            RefinementToken:
              "range(2021-07-27T00:00:00.0000000Z, 2021-08-26T00:00:00.0000000Z)",
            RefinementValue:
              "From 2021-07-27T00:00:00.0000000Z up to 2021-08-26T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past 24 hours",
            RefinementToken:
              'range(2021-08-26T00:00:00.0000000Z, max, to="le")',
            RefinementValue: "2021-08-26T00:00:00.0000000Z or later",
          },
        ],
        name: "Write",
      },
      {
        entries: [
          {
            RefinementCount: 1,
            RefinementName: "docx",
            RefinementToken: '"ǂǂ646f6378"',
            RefinementValue: "docx",
          },
          {
            RefinementCount: 3,
            RefinementName: "pptx",
            RefinementToken: '"ǂǂ70707478"',
            RefinementValue: "pptx",
          },
        ],
        name: "FileType",
      },
    ],
    results: [
      {
        docId: 17597526254494,
        dlcDocId: "AAAAA1351-724471295-116",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaaa1351",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={742c1f11-43a1-41a5-8408-ce7781880afb}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Annemieke van den Beukel;Holgate, Nicholas E SI-UPX/E/Z",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaaa1351",
        siteTitle: "Exploration Conference",
        people:
          "Nicholas.Holgate@shell.com | Holgate, Nicholas E SI-UPX/E/Z | 693A30232E667C6D656D626572736869707C6E6963686F6C61732E686F6C67617465407368656C6C2E636F6D i:0#.f|membership|nicholas.holgate@shell.com;Annemieke van den Beukel;Holgate, Nicholas E SI-UPX/E/Z",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={742c1f11-43a1-41a5-8408-ce7781880afb}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.docx.svg",
        title: "2016 SIPMAP workshop Abstract Template",
        path: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission/UPX-E-Z_Holgate_IHS-to-Petrel.docx",
        summary:
          "to create detailed <strong>basin</strong>-scale well correlations <ddd/> into a detailed <strong>basin</strong>-scale 3D earth model which <ddd/> ...",
        resultType: "docx",
        size: 1026357,
        write: "2020-04-02T13:39:00.0000000Z",
        rank: 0.0,
      },
      {
        docId: 991203519522815437,
        dlcDocId: "AAAAA1351-724471295-537",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaaa1351",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={ebdad69b-cf13-4599-8ff4-3b390575ef82}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Holgate, Nicholas E SI-UPX/E/Z",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaaa1351",
        siteTitle: "Exploration Conference",
        people:
          "Nicholas.Holgate@shell.com | Holgate, Nicholas E SI-UPX/E/Z | 693A30232E667C6D656D626572736869707C6E6963686F6C61732E686F6C67617465407368656C6C2E636F6D i:0#.f|membership|nicholas.holgate@shell.com;Holgate, Nicholas E SI-UPX/E/Z",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={ebdad69b-cf13-4599-8ff4-3b390575ef82}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Technology Session Presentations",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pptx.svg",
        title: "Slide 1",
        path: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Technology Session Presentations/116_UPX-E-Z_Holgate_IHS-to-Petrel.pptx",
        summary:
          "New Tricks: Creating <strong>basin</strong>-scale well correlations <ddd/> <strong>nicholas.holgate@shell.com</strong> <ddd/> ...",
        resultType: "pptx",
        size: 159490546,
        write: "2020-09-10T15:47:47.0000000Z",
        rank: 0.0,
      },
      {
        docId: 991203520103925552,
        dlcDocId: "AAFAA0175-728909044-393",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa0175",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA0175/HIW",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAFAA0175/HIW/_layouts/15/Doc.aspx?sourcedoc={ffb96d83-8d10-4187-83e1-e8222755ef1a}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Nicholas.Holgate@shell.com;Holgate, Nicholas E SI-UPX/E/Z",
        Description: "Azerbaijan, South Caspian: BP Shafag Asiman-1 ",
        SiteName: "https://eu001-sp.shell.com/sites/aafaa0175",
        siteTitle: "High Impact Wells",
        people:
          "Nicholas.Holgate@shell.com | Holgate, Nicholas E SI-UPX/E/Z | 693A30232E667C6D656D626572736869707C6E6963686F6C61732E686F6C67617465407368656C6C2E636F6D i:0#.f|membership|nicholas.holgate@shell.com;Nicholas.Holgate@shell.com;Holgate, Nicholas E SI-UPX/E/Z",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAFAA0175/HIW/_layouts/15/Doc.aspx?sourcedoc={ffb96d83-8d10-4187-83e1-e8222755ef1a}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA0175/HIW/Published HIW documents/Forms/Folders.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pptx.svg",
        title: "HIW_1page_template",
        path: "https://eu001-sp.shell.com/sites/AAFAA0175/HIW/Published HIW documents/2021_Azerbaijan_D230-01.pptx",
        summary:
          "in the Caspian Sea (Terek-Caspian <strong>Basin</strong>) in 2020 <ddd/> of the Terek-Caspian <strong>Basin</strong> (north-eastern Azeri <ddd/> ...",
        resultType: "pptx",
        size: 1113827,
        write: "2020-09-07T13:31:19.0000000Z",
        rank: 0.0,
      },
      {
        docId: 991203520230835452,
        dlcDocId: "AAFAA0175-728909044-387",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa0175",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA0175/HIW",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAFAA0175/HIW/_layouts/15/Doc.aspx?sourcedoc={1176257c-b022-491e-bf19-17a34353e7ac}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Nicholas.Holgate@shell.com;Holgate, Nicholas E SI-UPX/E/Z",
        Description: "Azerbaijan, South Caspian: BP Shafag Asiman-1 ",
        SiteName: "https://eu001-sp.shell.com/sites/aafaa0175",
        siteTitle: "High Impact Wells",
        people:
          "Nicholas.Holgate@shell.com | Holgate, Nicholas E SI-UPX/E/Z | 693A30232E667C6D656D626572736869707C6E6963686F6C61732E686F6C67617465407368656C6C2E636F6D i:0#.f|membership|nicholas.holgate@shell.com;Nicholas.Holgate@shell.com;Holgate, Nicholas E SI-UPX/E/Z",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAFAA0175/HIW/_layouts/15/Doc.aspx?sourcedoc={1176257c-b022-491e-bf19-17a34353e7ac}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA0175/HIW/Published HIW documents/Forms/Folders.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pptx.svg",
        title: "HIW_1page_template",
        path: "https://eu001-sp.shell.com/sites/AAFAA0175/HIW/Published HIW documents/2021_Azerbaijan_NKX-01.pptx",
        summary:
          "a mud volcano, which are common within the <strong>basin</strong> <ddd/> This portion of the <strong>basin</strong> is extremely active in <ddd/> ...",
        resultType: "pptx",
        size: 1390509,
        write: "2021-01-07T15:18:52.0000000Z",
        rank: 0.0,
      },
    ],
    spellingSuggestion: "",
  },
];

export default documents;
