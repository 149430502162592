import { Response } from "state/search/expertise/ExpertisePersonProjects";

const projectDetails: Response = [
  {
    UserAlias: "USDHFI",
    ProjectName: "Qroo",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_exp/pg_wr_315/ndi/quintana_roo/events/EOCT_P6_ANSB3_RTM_ADD2012_VERA18_multi_jdc_qroo.int",
    DataType: "3Dhorizon",
    CreatedDate: "2018-08-02T09:15:19.215141",
    ModifiedDate: "2018-08-02T15:18:29.927687",
  },
];

export default projectDetails;
