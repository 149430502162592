import { createTheme } from "@material-ui/core/styles";
import baseTheme, { palette } from "./baseTheme";

export default createTheme({
  ...baseTheme,
  palette: {
    ...palette,
    type: "dark",
    path: "#15B2CF",
    header: "#121E2E",
    headerText: "#B0B6BA",
    text: {
      primary: "#7B828E",
    },
    primary: {
      main: "#15B2CF",
    },
    secondary: {
      main: "#565656",
    },
    background: {
      default: "#1B283B",
      paper: "#273449",
    },
  },
});
