import { FormEvent, useMemo, useEffect, useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { isEqualSet } from "services/helpers";
import SourceTree from "state/sources/SourceTree";
import SelectedSources from "state/sources/SelectedSources";
import AppliedSources from "state/sources/AppliedSources";

import TreeFilterControl from "components/PageControls/TreeFilter";
import SubmitRow from "./SubmitRow";
import Button from "./Button";
import SourceLabel from "./SourceLabel";
import CheckAccess from "./CheckAccess";

export interface Dirty {
  isDirty: boolean;
  setDirty: () => void;
}

function TreeFilter({ close }: { close: () => void }) {
  const { t } = useTranslation();
  const sourceTree = useRecoilValue(SourceTree);
  const selectedState = useRecoilState(SelectedSources);
  const [selectedSources, setSelectedSources] = selectedState;
  const [appliedSources, setAppliedSources] = useRecoilState(AppliedSources);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setAppliedSources(new Set(selectedSources));
      close();
    },
    [setAppliedSources, close, selectedSources]
  );

  const isDirty = useMemo(
    () => !isEqualSet(selectedSources, appliedSources),
    [selectedSources, appliedSources]
  );

  useEffect(() => {
    setSelectedSources(new Set(appliedSources));
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <TreeFilterControl
        tree={sourceTree}
        selectedState={selectedState}
        Label={SourceLabel}
      >
        <SubmitRow>
          <CheckAccess />
          <Button type="submit" area="submit" disabled={!isDirty}>
            {t("Apply changes")}
          </Button>
        </SubmitRow>
      </TreeFilterControl>
    </form>
  );
}

export default TreeFilter;
