import styled from "styled-components";
import logo from "./exploration.svg";

const Logo = (props: any) => {
  return <img src={logo} {...props} alt="Shell" />;
};

export default styled(Logo)`
  height: 48px;
  border-radius: 32px;
`;
