import { ThemeOptions } from "@material-ui/core";

declare module "@material-ui/core/styles/createPalette" {
  interface PaletteOptions {
    shell: {
      yellow: string;
      red: string;
    };
    path: string;
    header: string;
    headerText: string;
  }
}

export const palette = {
  type: "light" as "light" | "dark",
  path: "#15B2CF",
  header: "#D5D5D5",
  headerText: "#515C6C",
  shell: {
    yellow: "#FBCE07",
    red: "#DD1D21",
  },
  primary: {
    main: "#0281B1",
  },
  secondary: {
    main: "#c3c3c3",
  },
};

const baseTheme: ThemeOptions = {
  palette,
  props: {
    MuiTextField: {
      variant: "outlined",
    },
    MuiLink: {
      underline: "hover",
      color: "secondary",
    },
    MuiButton: {
      disableElevation: true,
      variant: "contained",
      color: "primary",
    },
    MuiSwitch: {
      color: "primary",
    },
    MuiCheckbox: {
      color: "primary",
    },
    MuiContainer: {
      maxWidth: "xl",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 3,
  },
};

export default baseTheme;
