/**
 * Convert a json array of objects into a CSV string
 * Original inspiration (ES6 version): https://stackoverflow.com/a/31536517
 * @param data the array of objects to convert
 * @returns the CSV string
 */
export async function jsonToCSV(data: Array<{ [key: string]: any }>) {
  const replacer = (key: any, value: any) => (value === null ? "" : value);
  const header = Object.keys(data[0]);
  return [
    header.join(","),
    ...data.map((row) =>
      header
        .map((fieldName) => {
          const fieldData = JSON.stringify(row[fieldName], replacer);
          return fieldData ? fieldData.replace(/\\"/g, '""') : fieldData;
        })
        .join(",")
    ),
  ].join("\r\n");
}
