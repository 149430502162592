import { useRecoilValue } from "recoil";
import { useSpring } from "@react-spring/web";

import AllSearch, {
  AllSourceResult,
  useSearchSource,
} from "state/search/all/AllSearch";

import ImageDialog from "pages/ImagesPage/Images/ImageDialog";
import Container from "./Container";
import ImageCard from "./ImageCard";
import NoResults from "./NoResults";

const noImages = {
  results: [],
};

function Images() {
  const allSearch = useRecoilValue(AllSearch(0));
  const { results: images } = (useSearchSource(allSearch, "SPOImage") ||
    noImages) as AllSourceResult;
  const spring = useSpring({ from: { opacity: 0 }, to: { opacity: 1 } });
  return (
    <Container style={spring}>
      {images.length ? (
        images.map((image) => <ImageCard image={image} key={image.docId} />)
      ) : (
        <NoResults />
      )}
      <ImageDialog images={images} />
    </Container>
  );
}

export default Images;
