import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

import SiteSearch from "state/sources/SiteSearch";
import LoadingDisplay from "components/LoadingDisplay";

import Container from "./Container";
import Overlay from "./Overlay";

function CheckAccess() {
  const { t } = useTranslation();
  const siteSearchLoadable = useRecoilValueLoadable(SiteSearch);
  const isLoading = siteSearchLoadable.state === "loading";
  const hasLoaded =
    siteSearchLoadable.state === "hasValue" &&
    siteSearchLoadable.contents !== null;
  const hasError = siteSearchLoadable.state === "hasError";

  if (hasLoaded) {
    return null;
  }

  return (
    <Container>
      {isLoading && (
        <Overlay>
          <LoadingDisplay />
        </Overlay>
      )}
      {hasError && (
        <Overlay hidden>
          <Typography color="error">
            {t("Error loading access info")}
          </Typography>
        </Overlay>
      )}
    </Container>
  );
}

export default CheckAccess;
