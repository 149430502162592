import { HTMLProps } from "react";
import { Dispatch, SetStateAction, ReactNode, useState } from "react";
import { Node, Tree } from "state/treeTypes";

import NodeList from "./NodeList";
import Grid from "./Container";
import NodeEl from "./Node";

export interface Dirty {
  isDirty: boolean;
  setDirty: () => void;
}

function TreeFilter({
  tree,
  selectedState,
  children,
  Label,
}: {
  tree: Tree;
  selectedState: [Set<Node>, Dispatch<SetStateAction<Set<Node>>>];
  children: ReactNode;
  Label?: (props: HTMLProps<HTMLLabelElement>) => JSX.Element;
}) {
  const allState = useState(false);
  return (
    <Grid>
      {children}
      <NodeList>
        {tree.map((node, index) => (
          <NodeEl
            node={node}
            key={index}
            className="root"
            tree={tree}
            allState={allState}
            selectedState={selectedState}
            Label={Label}
          />
        ))}
      </NodeList>
    </Grid>
  );
}

export default TreeFilter;
