import styled from "styled-components/macro";
import { Typography } from "@material-ui/core";

export default styled(Typography)`
  font-size: 18px;
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.background.default)};

  @media all and (max-width: 775px) {
    font-size: 16px;
  }
`;
