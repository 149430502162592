import { useEffect, useRef } from "react";
import { atom, useRecoilValue } from "recoil";

export type Layout = "row" | "column";

/**
 * The layout selection for the expertise page
 */
const ExpertiseLayout = atom<Layout>({
  key: "ExpertiseLayout",
  default: "row",
});

/**
 * Hook which returns a boolean indicating whether or not the layout was toggled
 * @returns boolean indicating whether or not the layout was toggled
 */
function useSwitchedLayout() {
  const layout = useRecoilValue(ExpertiseLayout);
  const oldLayout = useRef(layout);
  useEffect(() => {
    oldLayout.current = layout;
  }, [layout]);
  return oldLayout.current !== layout;
}

export { useSwitchedLayout };
export default ExpertiseLayout;
