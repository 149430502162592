import { useSpring, config } from "@react-spring/web";
import { useTranslation } from "react-i18next";

import { useHasLoginError } from "services/auth";

import Container from "./Container";
import LinearProgress from "./LinearProgress";
import Text from "./Text";

function Progress() {
  const { t } = useTranslation();
  const hasLoginError = useHasLoginError();

  const progressSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.gentle,
    delay: 300,
  });

  return (
    <Container style={progressSpring}>
      <LinearProgress />
      {hasLoginError && (
        <Text hasLoginError={hasLoginError}>{t("Error logging in")}</Text>
      )}
    </Container>
  );
}

export default Progress;
