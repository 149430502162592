import styled from "styled-components/macro";

export default styled.div`
  grid-area: show-more;
  display: grid;
  place-items: center;
  padding: ${({ theme }) =>
    `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px 0`};
  width: 100%;
  height: 100%;
`;
