import styled, { css } from "styled-components/macro";
import Paper from "@material-ui/core/Paper";
import { Placement } from ".";

interface PaperInterface {
  placement: Placement;
  width: number;
  topOffset: number;
}

const positions = {
  default: css<PaperInterface>`
    position: absolute;
  `,
  top: css<PaperInterface>`
    position: fixed;
    top: ${({ topOffset }) => `${topOffset}px`};
  `,
  bottom: css<PaperInterface>`
    position: absolute;
    bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  `,
};

export default styled(Paper).attrs({ elevation: 1 })<PaperInterface>`
  ${({ placement }) => positions[placement]}
  width: ${({ width }) => `${width}px`};
`;
