import { ReactNode, useEffect } from "react";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import * as amplitude from "@amplitude/analytics-browser";

import GlobalStyle from "./GlobalStyle";
import InjectTheme from "./InjectTheme";
import SyncCurrentTime from "./effects/SyncCurrentTime";
import SyncViewportDimensions from "./effects/SyncViewportDimensions";
import ExportThemeInDevelopment from "./effects/ExportThemeInDevelopment";
import LoadSavedToken from "./effects/LoadSavedToken";
import LoadQueryString from "./effects/LoadQueryString";
import LogoutExpiredUser from "./effects/LogoutExpiredUser";
import AppInsightsTracking from "./effects/AppInsightsTracking";
import env from "services/env";

function AppSetup({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const amplitudeKey = env.REACT_APP_AMPLITUDE_KEY;

  useEffect(() => {
    amplitude.init(amplitudeKey, undefined, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        fileDownloads: true,
        formInteractions: true,
      },
    });
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      <Helmet>
        <title>{t("APP_NAME")}</title>
      </Helmet>
      <RecoilRoot>
        <InjectTheme>
          <CssBaseline />
          <GlobalStyle />
          <Router>
            <AppInsightsTracking />
            <SyncCurrentTime />
            <SyncViewportDimensions />
            <ExportThemeInDevelopment />
            <LoadSavedToken />
            <LoadQueryString />
            <LogoutExpiredUser />
            {children}
          </Router>
        </InjectTheme>
      </RecoilRoot>
    </SnackbarProvider>
  );
}

export default AppSetup;
