import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";

import { useHasQuery } from "state/Query";

import PageContainer from "components/PageContainer";
import PleaseEnterQuery from "components/PleaseEnterQuery";
import HeaderBar from "components/HeaderBar";
import PageControls from "components/PageControls";
import LoadingDisplay from "components/LoadingDisplay";
import ErrorDisplay from "components/ErrorDisplay";

import ResultsCount from "./controls/ResultsCount";

import Results from "./Results";

// The page path relative to the application root
const path = "/external";

function ExternalPage() {
  const { t } = useTranslation();
  const hasQuery = useHasQuery();

  if (!hasQuery) {
    return <PleaseEnterQuery />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("External")} - {t("APP_NAME")}
        </title>
      </Helmet>
      <HeaderBar>
        <PageControls left={[<ResultsCount />]} />
      </HeaderBar>
      <PageContainer>
        <ErrorBoundary FallbackComponent={ErrorDisplay}>
          <Suspense
            fallback={<LoadingDisplay style={{ minHeight: "200px" }} />}
          >
            <Results />
          </Suspense>
        </ErrorBoundary>
      </PageContainer>
    </>
  );
}

export { path };
export default ExternalPage;
