import { Response } from "state/search/expertise/ExpertisePersonProjects";

const documents: Response = [
  {
    UserAlias: null,
    ProjectName: "Nakika PBE",
    ProjectSource: "nDI",
    ProjectCount: 7,
    DataPath: null,
    DataType: null,
    CreatedDate: "2019-02-08T14:27:03.436568",
    ModifiedDate: "2019-02-08T16:35:36.42365",
  },
  {
    UserAlias: null,
    ProjectName: "test",
    ProjectSource: "nDI",
    ProjectCount: 9,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-12-04T14:28:29.618839",
    ModifiedDate: "2015-12-04T14:28:42.095",
  },
  {
    UserAlias: null,
    ProjectName: "HokesRun",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-06-24T17:36:30.099732",
    ModifiedDate: "2015-06-24T17:42:33.910193",
  },
  {
    UserAlias: null,
    ProjectName: "Egom_broadband",
    ProjectSource: "nDI",
    ProjectCount: 28,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-04-06T11:25:39.67945",
    ModifiedDate: "2015-08-03T15:02:35.492138",
  },
  {
    UserAlias: null,
    ProjectName: "REGIONAL Merged Dataset CG",
    ProjectSource: "nDI",
    ProjectCount: 2,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-02-27T15:22:40.271488",
    ModifiedDate: "2015-04-06T14:10:24.144191",
  },
  {
    UserAlias: null,
    ProjectName: "Mitten-Trend-regional - USHLU0",
    ProjectSource: "nDI",
    ProjectCount: 34,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-02-26T17:34:08.974586",
    ModifiedDate: "2015-03-09T14:39:49.702808",
  },
  {
    UserAlias: null,
    ProjectName: "Dilution-EGom-HB",
    ProjectSource: "nDI",
    ProjectCount: 8,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-02-10T09:38:27.80647",
    ModifiedDate: "2015-02-13T14:47:35.562992",
  },
  {
    UserAlias: null,
    ProjectName: "REGIONAL Merged Dataset - NLCGI0",
    ProjectSource: "nDI",
    ProjectCount: 2,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-02-10T09:38:04.973442",
    ModifiedDate: "2015-02-10T09:38:05.017742",
  },
  {
    UserAlias: null,
    ProjectName: "Dover Shallow",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-08-06T14:56:29.529335",
    ModifiedDate: "2014-08-07T14:02:57.462661",
  },
  {
    UserAlias: null,
    ProjectName: "DoverFaults",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-07-15T17:09:34.341988",
    ModifiedDate: "2014-07-15T17:10:44.953175",
  },
  {
    UserAlias: null,
    ProjectName: "Dover",
    ProjectSource: "nDI",
    ProjectCount: 32,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-07-14T14:07:12.704133",
    ModifiedDate: "2014-12-11T14:57:07.245057",
  },
  {
    UserAlias: null,
    ProjectName: "Justice InhouseforDiegel (1)",
    ProjectSource: "nDI",
    ProjectCount: 3,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-05-20T14:23:02.882757",
    ModifiedDate: "2014-05-20T14:28:09.127491",
  },
  {
    UserAlias: null,
    ProjectName: "Justice InhouseforDiegel",
    ProjectSource: "nDI",
    ProjectCount: 12,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-05-20T14:22:25.400256",
    ModifiedDate: "2014-05-20T14:22:46.541518",
  },
  {
    UserAlias: null,
    ProjectName: "Justice Inhouse - USJWOP (1)",
    ProjectSource: "nDI",
    ProjectCount: 20,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-04-24T16:20:34.960441",
    ModifiedDate: "2014-10-01T13:49:05.81534",
  },
  {
    UserAlias: null,
    ProjectName: "Simple Box20 - USJWOP",
    ProjectSource: "nDI",
    ProjectCount: 5,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-04-24T16:13:10.536252",
    ModifiedDate: "2014-04-24T16:13:10.658789",
  },
  {
    UserAlias: null,
    ProjectName: "Regional Merge - NLCGI0",
    ProjectSource: "nDI",
    ProjectCount: 2,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-03-14T16:59:51.855735",
    ModifiedDate: "2014-03-14T17:00:28.663442",
  },
  {
    UserAlias: null,
    ProjectName: "emanatee",
    ProjectSource: "nDI",
    ProjectCount: 9,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-09-23T16:50:34.516721",
    ModifiedDate: "2013-10-25T08:43:15.509087",
  },
  {
    UserAlias: null,
    ProjectName: "PowerNap",
    ProjectSource: "nDI",
    ProjectCount: 8,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-05-01T15:33:59.444756",
    ModifiedDate: "2013-10-09T15:49:09.192041",
  },
];

export default documents;
