import { Link } from "react-router-dom";
import styled from "styled-components";

export default styled(Link)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.text.primary};
  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: none;
  }
`;
