import { Container } from "@material-ui/core";
import { animated } from "@react-spring/web";
import styled from "styled-components";

export default animated(styled(Container)`
  display: grid;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing(10)}px 0 ${theme.spacing(2)}px`};
  place-items: start center;
`);
