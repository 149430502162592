import Link from "./Link";
import Text from "./Text";
import Logo from "./Logo";

interface LogoLinkInterface {
  to: string;
  children: string;
}

function LogoLink({ to, children }: LogoLinkInterface) {
  return (
    <Link to={to}>
      <Logo /> <Text>{children}</Text>
    </Link>
  );
}

export default LogoLink;
