import React from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import env from "services/env";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: env.REACT_APP_INSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
if (env.REACT_APP_INSIGHTS_KEY) {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

class AppInsights extends React.Component {
  render() {
    return null;
  }
}

export { reactPlugin, appInsights };
export default withAITracking(reactPlugin, AppInsights);
