import { Skeleton } from "@material-ui/lab";
import styled from "styled-components/macro";
import { columnSize } from "./Projects";

export default styled(Skeleton).attrs({ component: "div" })`
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  width: ${`${columnSize}px`};
  cursor: default;
  transform: none;
`;
