import styled from "styled-components/macro";
import { Link } from "react-router-dom";

export default styled(Link)`
  grid-area: logo;
  place-self: center left;
  font-size: 22px;
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  grid-column-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
  &:visited {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: none;
  }
`;
