import styled from "styled-components/macro";
import { config, animated, useSpring } from "@react-spring/web";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";

import { randomInt } from "services/helpers";
import { SourceNode } from "state/sources/SourceTree";

const Icon = animated(styled(NotInterestedIcon)`
  height: 20px;
  color: ${({ theme }) => theme.palette.error.light};
  cursor: default;
`);

function NoneIcon({ node }: { node: SourceNode }) {
  const { t } = useTranslation();
  const spring = useSpring({
    from: { opacity: 0, transform: "translateX(-20px)" },
    to: { opacity: 1, transform: "translateX(0px)" },
    config: config.gentle,
    delay: randomInt(0, 300),
  });
  return (
    <Tooltip
      title={
        t(
          "You do not have access to any of these sites and libraries"
        ) as string
      }
    >
      <a
        href={node.siteUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ cursor: "pointer" }}
      >
        <Icon style={spring} />
      </a>
    </Tooltip>
  );
}

export default NoneIcon;
