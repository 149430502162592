import { animated } from "@react-spring/web";
import styled from "styled-components/macro";
import { themeBorder } from "services/helpers";

export default animated(styled.div`
  position: relative;
  display: grid;
  place-items: end center;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  transform-origin: 50% 50%;
  grid-row-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  transition: all 0.1s ease;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  background: ${({ theme }) => theme.palette.background.paper};

  ${themeBorder}

  &:hover {
    z-index: 1;
    transition: all 0.2s ease;
    box-shadow: ${({ theme }) => theme.shadows[2]};
    transform: scale(1.05);
  }
`);
