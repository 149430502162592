const en = {
  translation: {
    APP_NAME: "Unified Search",
    prototype_button: "Beta Software",
    "Unified Search": "Unified Search",
    "Search USE": "Search USE",
    PLEASE_LOGIN:
      "Please log in with your Shell credentials to access the Shell Unified Search Engine and gain access to millions of documents.",
    "Log in with Shell Credentials": "Log in with Shell Credentials",
    "Error logging in": "Error logging in",
    Search: "Search",
    "Please enter your search query": "Please enter your search query",
    "Search query is too long": "Search query is too long",
    Loading: "Loading",
    "Loading, please wait": "Loading, please wait",
    "Search Results": "Search Results",
    Dashboard: "Dashboard",
    "Shared Drives": "Shared Drives",
    External: "External",
    Images: "Images",
    Map: "Map",
    Expertise: "Expertise",
    All: "All",
    About: "About",
    Results: "Results",
    "Please try changing your selections and clicking retry":
      "Please try changing your selections and clicking retry",
    "Please enter a search query to begin":
      "Please enter a search query to begin",
    at: "at",
    "See details": "See details",
    Folder: "Folder",
    "Site Collection": "Site Collection",
    Size: "Size",
    "Last Modified Time": "Last Modified Time",
    "Author(s)": "Author(s)",
    "Load more": "Load more",
    "Additional content tabs": "Additional content tabs",
    "External Literature": "External Literature",
    Page: "Page",
    Open: "Open",
    "View image details": "View image details",
    "Error loading": "Error loading",
    "Error loading access info": "Error loading access info",
    "Loading image": "Loading image",
    "Site collection": "Site collection",
    Document: "Document",
    Image: "Image",
    "Caption text": "Caption text",
    Dimensions: "Dimensions",
    "Slide/Page number": "Slide/Page number",
    "Slide text": "Slide text",
    "Apply changes": "Apply changes",
    "Check my access": "Check my access",
    "Export to Excel": "Export to Excel",
    "File Type": "File Type",
    "Sharepoint Sites & Libraries": "Sharepoint Sites & Libraries",
    "Clear File Type": "Clear File Type",
    "You have access to ALL of these sites and libraries":
      "You have access to ALL of these sites and libraries",
    "You have access to SOME of these sites and libraries":
      "You have access to SOME of these sites and libraries",
    "You do not have access to any of these sites and libraries":
      "You do not have access to any of these sites and libraries",
    "Light theme": "Light theme",
    "Dark theme": "Dark theme",
    "Projects Search": "Projects Search",
    Business: "Business",
    "Line of Business": "Line of Business",
    Sort: "Sort",
    "Sort By": "Sort By",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Project Start Date - Recent": "Project Start Date - Recent",
    "Project Start Date - Oldest": "Project Start Date - Oldest",
    "Toggle layout": "Toggle layout",
    "Top Project Results": "Top Project Results",
    Details: "Details",
    Documents: "Documents",
    Projects: "Projects",
    "Start chat": "Start chat",
    "No details found": "No details found",
    "Current Project": "Current Project",
    "Project Title": "Project Title",
    "Project Start": "Project Start",
    Location: "Location",
    Role: "Role",
    Skills: "Skills",
    Assignments: "Assignments",
    "No documents found": "No documents found",
    "No projects found": "No projects found",
    Name: "Name",
    Source: "Source",
    Created: "Created",
    Modified: "Modified",
    "Creation Date": "Creation Date",
    "Last Modified": "Last Modified",
    "Data Type": "Data Type",
    Path: "Path",
    "Copy Path": "Copy Path",
    "Copy Error": "Copy Error",
    "Copied!": "Copied!",
    Count: "Count",
    Responsibility: "Responsibility",
    "No results found": "No results found",
    "No images found": "No images found",
    "If you find any documents that you feel should not be there, please contact your data manager to get them removed":
      "If you find any documents that you feel should not be there, please contact your data manager to get them removed",
    "Ask Emma": "Ask Emma",
    "SSW Virtual Assistant": "SSW Virtual Assistant"
  },
};

export default en;
