import { Typography } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(Typography).attrs({ component: "div" })`
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  font-size: 12px;
  max-width: 100%;
  .highlight {
    font-weight: bold;
  }
`;
