import { Link } from "react-router-dom";
import styled from "styled-components";

export default styled(Link)`
  place-self: start;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
