import { animated } from "@react-spring/web";
import styled, { css } from "styled-components/macro";
import { Layout } from "state/search/expertise/ExpertiseLayout";

export const columnSize = 250;

interface ContainerInterface {
  layout: Layout;
  navButtonWidth: number;
}

const columnLayout = css<ContainerInterface>`
  grid-template-columns: ${`repeat(auto-fill, minmax(${columnSize}px, 1fr))`};
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
`;
const rowLayout = css<ContainerInterface>`
  grid-auto-flow: column;
  padding-left: ${({ theme, navButtonWidth }) =>
    `${theme.spacing(2) + navButtonWidth}px`};
  padding-right: ${({ theme, navButtonWidth }) =>
    `${theme.spacing(2) + navButtonWidth}px`};
  padding-top: ${({ theme }) => `${theme.spacing(2)}px`};
  padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  min-height: 233px;
`;

export default animated(styled.div<ContainerInterface>`
  position: relative;
  display: grid;
  grid-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  ${({ layout }) => (layout === "column" ? columnLayout : rowLayout)}
`);
