import { ErrorBoundary } from "react-error-boundary";
import ErrorDisplay from "components/ErrorDisplay";
import Results from "./Results";

export default function SearchResults() {
  return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorDisplay}>
        <Results />
      </ErrorBoundary>
    </div>
  );
}
