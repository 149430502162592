import { REFINER_BANNER_CONSTANTS } from "services/constants";
import { InfoOutlined } from "@material-ui/icons";
import styled from "styled-components";

const InfoIcon = styled(InfoOutlined)`
  color: ${REFINER_BANNER_CONSTANTS.SKY_DEFAULT};
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export default InfoIcon;
