import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { formatDateTime } from "services/helpers";
import { useGetPath } from "services/hooks";
import { Result } from "state/search/expertise/ExpertiseSearch";
import { path as ExpertisePath } from "pages/ExpertisePage";
import ExpertiseProjectPosition from "state/search/expertise/ExpertiseProjectPosition";

import Container from "./Container";
import TitleLink from "./TitleLink";
import Property from "./Property";
import Link from "./Link";

const dateFormat = "LLL do yyyy";

export default function ProjectCard({
  project,
  active,
}: {
  project: Partial<Result>;
  active: boolean;
}) {
  const { t } = useTranslation();
  const getPath = useGetPath();

  const setContainerPosition = useSetRecoilState(
    ExpertiseProjectPosition(project.createdDate as string)
  );
  const containerCallback = useCallback(
    (container: HTMLDivElement | null) => {
      container && setContainerPosition(container.offsetLeft);
    },
    [setContainerPosition]
  );

  return (
    <Link
      to={getPath(ExpertisePath, {
        query: project.title || "",
        project: String(true),
      })}
    >
      <Container
        ref={containerCallback}
        active={String(active) as "true" | "false"}
      >
        <TitleLink title={project.title}>{project.title}</TitleLink>
        <Property
          label={t("Created Date")}
          value={formatDateTime(project.createdDate as string, dateFormat)}
        />
        <Property
          label={t("Modified Date")}
          value={formatDateTime(project.modifiedDate as string, dateFormat)}
        />
        <Property label={t("Activities Count")} value={project.projectCount} />
      </Container>
    </Link>
  );
}
