import { Typography } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(Typography)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  font-size: 13px;
`;
