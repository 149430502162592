import { ButtonBase } from "@material-ui/core";
import chroma from "chroma-js";
import styled from "styled-components";

export default styled(ButtonBase)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  border: ${({ theme }) =>
    `1px solid ${chroma(theme.palette.primary.main).alpha(0.3).css()}`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  padding: 5px;
  background: ${({ theme }) =>
    chroma(theme.palette.primary.main).alpha(0.1).css()};
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;
