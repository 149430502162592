import { selectorFamily } from "recoil";
import { AxiosResponse } from "axios";
import { useAPI } from "services/network";

export interface Project {
  CreatedDate: null | string;
  DataPath: null | string;
  DataType: null | string;
  ModifiedDate: null | string;
  ProjectCount: number;
  ProjectName: null | string;
  ProjectSource: null | string;
  UserAlias: null | string;
}

export interface Request {
  alias: string;
  projectName?: string;
}

export interface Response extends Array<Project> {}

export const endpoint = "/api/v1/ExpertiseApi/projectsSearchByAlias/";

/**
 * Fetches a persons project list from the backend
 */
export default selectorFamily({
  key: "ExpertisePersonProjects",
  get: (alias: string) => async () => {
    const requestBody: Request = {
      alias,
    };
    return (
      await useAPI.post<Request, AxiosResponse<Response>>(endpoint, requestBody)
    ).data;
  },
});
