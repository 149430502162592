import { useGetPath } from "services/hooks";

import Background from "./Background";
import Container from "./Container";
import NavItem from "./NavItem";

import { path as AllPath } from "pages/AllPage";
import { path as ImagesPath } from "pages/ImagesPage";
import { path as ExternalPath } from "pages/ExternalPage";
import { path as ShareDrivesPath } from "pages/SharedDrivesPage";
// import { path as MapPath } from "pages/MapPage";
import { path as ExpertisePath } from "pages/ExpertisePage";
import { amplitudeTracker } from "services/helpers";
import { AMPLITUDE_EVENTS } from "services/constants";

function Navigation() {
  const getPath = useGetPath();

  return (
    <Background>
      <Container>
        <NavItem
          onClick={() => amplitudeTracker(AMPLITUDE_EVENTS.TAB_ALL)}
          to={getPath(AllPath)}
          exact
        >
          All
        </NavItem>
        <NavItem
          onClick={() => amplitudeTracker(AMPLITUDE_EVENTS.TAB_IMAGES)}
          to={getPath(ImagesPath)}
        >
          Images
        </NavItem>
        <NavItem
          onClick={() => amplitudeTracker(AMPLITUDE_EVENTS.TAB_EXTERNAL)}
          to={getPath(ExternalPath)}
        >
          External
        </NavItem>
        <NavItem
          onClick={() => amplitudeTracker(AMPLITUDE_EVENTS.TAB_SHARED_DRIVES)}
          to={getPath(ShareDrivesPath)}
        >
          Shared Drives
        </NavItem>
        {/* <NavItem to={getPath(MapPath)}>Map</NavItem> */}
        <NavItem
          onClick={() => amplitudeTracker(AMPLITUDE_EVENTS.TAB_EXPERTISE)}
          to={getPath(ExpertisePath)}
        >
          Expertise
        </NavItem>
      </Container>
    </Background>
  );
}

export default Navigation;
