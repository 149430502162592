import styled from "styled-components/macro";

export default styled.div`
  width: 100%;
  grid-area: meta;
  place-items: start;
  margin-top: ${({ theme }) => `${theme.spacing(2)}px`};

  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  font-size: 12px;
  place-items: center start;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
