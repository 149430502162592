import { FormEvent, useMemo, useEffect, useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

import { isEqualSet } from "services/helpers";
import SharedDrivesTree from "state/search/sharedDrives/SharedDrivesTree";
import SelectedSharedDrives from "state/search/sharedDrives/SelectedSharedDrives";
import AppliedSharedDrives from "state/search/sharedDrives/AppliedSharedDrives";

import TreeFilterControl from "components/PageControls/TreeFilter";
import SubmitRow from "./SubmitRow";

export interface Dirty {
  isDirty: boolean;
  setDirty: () => void;
}

export default function TreeFilter({ close }: { close: () => void }) {
  const { t } = useTranslation();
  const sharedDrivesTree = useRecoilValue(SharedDrivesTree);
  const selectedState = useRecoilState(SelectedSharedDrives);
  const [selected, setSelected] = selectedState;
  const [applied, setApplied] = useRecoilState(AppliedSharedDrives);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setApplied(new Set(selected));
      close();
    },
    [setApplied, close, selected]
  );

  const isDirty = useMemo(
    () => !isEqualSet(selected, applied),
    [selected, applied]
  );

  useEffect(() => {
    setSelected(new Set(applied));
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <TreeFilterControl tree={sharedDrivesTree} selectedState={selectedState}>
        <SubmitRow>
          <Button type="submit" disabled={!isDirty}>
            {t("Apply changes")}
          </Button>
        </SubmitRow>
      </TreeFilterControl>
    </form>
  );
}
