import { Typography } from "@material-ui/core";
import styled, { css } from "styled-components";

interface LinkProps {
  href: string;
  target?: string;
  rel?: string;
  title?: string;
  textColor?: string;
}

const titleColor = css<LinkProps>`
  color: ${({ textColor }) => textColor};
  &:visited,
  &:hover {
    color: ${({ textColor }) => textColor};
  }
`;

export default styled(({ textColor, ...props }) => (
  <Typography {...props} />
)).attrs({ component: "a" })<LinkProps>`
  max-width: 100%;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ title }) => title && titleColor}
`;
