import { Response } from "state/sources/SourceTree";

const data: Response = [
  {
    text: "All",
    level: "1",
    children: [
      {
        text: "Argentina",
        level: "2",
        children: [
          {
            text: "Exploration - Argentina Venture",
            level: "3",
            children: [
              {
                text: "Argentina Bid Round 2018",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa3520",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa3520/argentina bid round 2018",
              },
              {
                text: "Argentina Bid Round 2020",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa3520",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa3520/f03",
              },
              {
                text: "Audits",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa3520",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa3520/audits",
              },
              {
                text: "CAN100 - Business Assurance",
                level: "4",
                children: null,
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa3520/can100a",
              },
              {
                text: "CAN100 - Commercial",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa3520",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa3520/can100f",
              },
              {
                text: "CAN100 - Contracts and Procurement",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa3520",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa3520/can100k",
              },
              {
                text: "CAN100 - DRB",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa3520",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa3520/can100b",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aafaa3520",
          },
        ],
        sites: ["https://eu001-sp.shell.com/sites/AAFAA3520"],
      },
      {
        text: "Bangladesh",
        level: "2",
        children: [
          {
            text: "Exploration - Bangladesh",
            level: "3",
            children: [
              {
                text: "Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaaa9772",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaaa9772/documents",
              },
              {
                text: "Opportunities",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaaa9772",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaaa9772/lib01",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aaaaa9772",
          },
        ],
        sites: ["https://eu001-sp.shell.com/sites/aaaaa9772"],
      },
      {
        text: "Bolivia",
        level: "2",
        children: [
          {
            text: "Bolivia Exploration",
            level: "3",
            children: [
              {
                text: "Admin",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa4075/admi",
              },
              {
                text: "Archive",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa4075/arch",
              },
              {
                text: "Audit 2018",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4075/audit 2018",
              },
              {
                text: "Concessions",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa4075/conc",
              },
              {
                text: "Contracts",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa4075/cont3",
              },
              {
                text: "HCT",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4075/jaguar assurance tracking",
              },
              {
                text: "HSSE",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa4075/hsse1",
              },
              {
                text: "HSSE",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa4075/hss2",
              },
              {
                text: "HSSE",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa4075/hsse11",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aafaa4075",
          },
        ],
        sites: ["https://eu001-sp.shell.com/sites/AAFAA4075"],
      },

      {
        text: "Brazil",
        level: "2",
        children: [
          {
            text: "Alto Cabo Frio Oeste",
            level: "3",
            children: [
              {
                text: "7- Governance",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4455",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4455/7%20governance",
              },
              {
                text: "9- Risk Register",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4455",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4455/9%20risk%20register",
              },
              {
                text: "1- Venture Management",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4455",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4455/1%20venture%20management",
              },
              {
                text: "5- Government",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4455",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4455/government",
              },
              {
                text: "3- Venture Meetings",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4455",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4455/3%20venture%20meetings",
              },
              {
                text: "8-HSSE",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4455",
                libraryUrl: "https://eu001-sp.shell.com/sites/aafaa4455/8hsse",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aafaa4455",
          },
          {
            text: "BRAZIL EXPLORATION",
            level: "3",
            children: [
              {
                text: "Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab4525/documents",
              },
              {
                text: "1999-11-25 GECO AS - Shell Brasil S.A. ",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab4525/199",
              },
              {
                text: "TECHNICAL DATA MANAGEMENT.",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab4525/tec",
              },
              {
                text: "BM-SEAL-5",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab4525/bms6",
              },
              {
                text: "BM-C-25",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab4525/2101",
              },
              {
                text: "Pre Salt Brazil GRV/DEPTH Uncertainty  August 2018",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab4525/pre%20salt%20brazil%20grvdepth%20uncertainty%20%20august%202018",
              },
              {
                text: "BC-10 GNOME",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab4525/gno",
              },
              {
                text: "BM-S-37 (S-M-610)",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab4525/bms3",
              },
              {
                text: "Santos Regional",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525/cbg",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab4525/cbg/santos%20regional",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aaaab4525",
          },
          {
            text: "Tres Marias",
            level: "3",
            children: [
              {
                text: "3 - Venture Meetings",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4973",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4973/3%20%20venture%20meetings",
              },
              {
                text: "1- Venture Management",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4973",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4973/1%20venture%20management",
              },
              {
                text: "7- HSSE",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4973",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4973/7-%20hsse",
              },
              {
                text: "4- Internal Meetings",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4973",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4973/5%20xorp",
              },
              {
                text: "8- Seismic",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4973",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4973/8%20seismic",
              },
              {
                text: "5- Government",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4973",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4973/5%20government",
              },
              {
                text: "6- Governance",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4973",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4973/6%20governance",
              },
              {
                text: "2- Technical",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa4973",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa4973/2%20technical",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aafaa4973",
          },
          {
            text: "CM-659 (Su�uarana & Ariranha)",
            level: "3",
            children: [
              {
                text: "7- Risk Register",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa6135",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa6135/6-%20risk%20register",
              },
              {
                text: "3- Venture Meetings",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa6135",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa6135/3-%20venture%20meetings",
              },
              {
                text: "6- Internal Meetings",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa6135",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa6135/5-%20internal%20meetings",
              },
              {
                text: "4- Government",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa6135",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa6135/4-%20government",
              },
              {
                text: "5- Governance",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa6135",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa6135/5-%20governance",
              },
              {
                text: "1- Venture Management",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa6135",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa6135/venture%20management",
              },
              {
                text: "2- Technical",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aafaa6135",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aafaa6135/2-%20technical",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aafaa6135",
          },
        ],
      },
      {
        text: "Cambodia",
        level: "2",
        children: [
          {
            text: "Exploration - Cambodia",
            level: "3",
            children: [
              {
                text: "Public Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0090",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0090/public%20documents",
              },
              {
                text: "Opportunities",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0090",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab0090/lib01",
              },
              {
                text: "Cambodia",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0090",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab0090/cam",
              },
              {
                text: "Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0090",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0090/documents",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aaaab0090",
          },
        ],
        sites: ["https://eu001-sp.shell.com/sites/aaaab0090"],
      },
      {
        text: "China",
        level: "2",
        children: [
          {
            text: "Exploration - China (NBD)",
            level: "3",
            children: [
              {
                text: "Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0091",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0091/documents",
              },
              {
                text: "Opportunities",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0091",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab0091/lib01",
              },
              {
                text: "Bohai Bay RON",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0091",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab0091/boh",
              },
              {
                text: "Public Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0091",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0091/public%20documents",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aaaab0091",
          },
        ],
        sites: ["https://eu001-sp.shell.com/sites/aaaab0091"],
      },
      {
        text: "EP Global Library",
        level: "2",
        children: [
          {
            text: "Exploration - EP Global Library",
            level: "3",
            children: [
              {
                text: "ReportLibrary01",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/AAFAA4377",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/AAFAA4377/ReportLibrary01",
              },
              {
                text: "ReportLibrary02",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/AAFAA4377",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/AAFAA4377/ReportLibrary02",
              },
              {
                text: "ReportLibrary03",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/AAFAA4377",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/AAFAA4377/ReportLibrary03",
              },
              {
                text: "ReportLibrary04",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/AAFAA4377",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/AAFAA4377/ReportLibrary04",
              },
              {
                text: "ReportLibrary05",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/AAFAA4377",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/AAFAA4377/ReportLibrary05",
              },
              {
                text: "ReportLibrary06",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/AAFAA4377",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/AAFAA4377/ReportLibrary06",
              },
              {
                text: "ReportLibrary07",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/AAFAA4377",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/AAFAA4377/ReportLibrary07",
              },
              {
                text: "ReportLibrary08",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/AAFAA4377",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/AAFAA4377/ReportLibrary08",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/AAFAA4377",
          },
        ],
        sites: ["https://eu001-sp.shell.com/sites/AAFAA4377"],
      },
      {
        text: "Singapore",
        level: "2",
        children: [
          {
            text: "Exploration - Singapore",
            level: "3",
            children: [
              {
                text: "Opportunities",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0083",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab0083/lib01",
              },
              {
                text: "Public Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0083",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0083/public%20documents",
              },
              {
                text: "Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0083",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0083/documents",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aaaab0083",
          },
        ],
        sites: ["https://eu001-sp.shell.com/sites/aaaab0083"],
      },
      {
        text: "South Korea",
        level: "2",
        children: [
          {
            text: "Exploration - South Korea",
            level: "3",
            children: [
              {
                text: "Opportunities",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0096",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab0096/lib01",
              },
              {
                text: "Public Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0096",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0096/public%20documents",
              },
              {
                text: "Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0096",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0096/documents",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aaaab0096",
          },
        ],
        sites: ["https://eu001-sp.shell.com/sites/aaaab0096"],
      },
      {
        text: "Vietnam",
        level: "2",
        children: [
          {
            text: "Exploration - Vietnam",
            level: "3",
            children: [
              {
                text: "Public Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0086",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0086/public%20documents",
              },
              {
                text: "Opportunities",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0086",
                libraryUrl: "https://eu001-sp.shell.com/sites/aaaab0086/lib01",
              },
              {
                text: "Documents",
                level: "4",
                children: null,
                siteUrl: "https://eu001-sp.shell.com/sites/aaaab0086",
                libraryUrl:
                  "https://eu001-sp.shell.com/sites/aaaab0086/documents",
              },
            ],
            siteUrl: "https://eu001-sp.shell.com/sites/aaaab0086",
          },
        ],
        sites: ["https://eu001-sp.shell.com/sites/aaaab0086"],
      },
    ],
  },
];

export default data;
