import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";
import { Waypoint } from "react-waypoint";
import Button from "@material-ui/core/Button";

import ExternalSearchPage from "state/search/external/ExternalSearchPage";
import ExternalSearch from "state/search/external/ExternalSearch";
import LoadingDisplay from "components/LoadingDisplay";

import Container from "./Container";

function LoadMore() {
  const { t } = useTranslation();
  const [page, setPage] = useRecoilState(ExternalSearchPage);
  const currentPageLoadable = useRecoilValueLoadable(ExternalSearch(page));

  function bumpPageCount() {
    setPage(page + 1);
  }

  return currentPageLoadable.state === "hasValue" ? (
    <Container>
      <Waypoint onEnter={bumpPageCount}>
        <Button variant="text" onClick={bumpPageCount}>
          {t("Load more")}
        </Button>
      </Waypoint>
    </Container>
  ) : (
    <Container>
      <LoadingDisplay />
    </Container>
  );
}

export default LoadMore;
