import { useRecoilValue } from "recoil";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

import { useAPI } from "services/network";
import { endpoint, Request, Response } from "state/search/all/AllSearch";
import Query from "state/Query";

import ExportCSV from "components/PageControls/ExportCSV";
import ImageSearchRefiner from "state/search/image/ImageSearchRefiner";

function Export() {
  const { t } = useTranslation();
  const queryText = useRecoilValue(Query);
  const refiner = useRecoilValue(ImageSearchRefiner);

  async function fetchData(page: number) {
    const { data } = await useAPI.post<Request, AxiosResponse<Response>>(
      endpoint,
      {
        page,
        queryText,
        refiner,
        scope: "All",
        source: "SPOImage",
        rowLimit: 500,
      }
    );
    return data[0];
  }

  return <ExportCSV fetchData={fetchData} label={t("Export to Excel")} />;
}

export default Export;
