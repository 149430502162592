import { atom } from "recoil";
import { getPKCEState } from "services/auth";

function getCode() {
  const queryString = new URLSearchParams(window.location.search);
  if (queryString.has("code") && queryString.has("state")) {
    const pkceState = getPKCEState();
    const queryState = queryString.get("state");
    if (pkceState === queryState) {
      return queryString.get("code") as string;
    }
  }
  return null;
}

export default atom<string | null>({
  key: "RedirectCode",
  default: getCode(),
});
