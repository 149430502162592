import { useRef, useEffect } from "react";
import { useTrail, animated, config } from "@react-spring/web";

import { useRecoilValueOr } from "services/hooks";
import ExpertiseSearch, {
  useSearchSource,
} from "state/search/expertise/ExpertiseSearch";
import { useSwitchedLayout } from "state/search/expertise/ExpertiseLayout";

import Result from "./Result";
import NoResults from "./NoResults";

function ResultsPage({ page }: { page: number }) {
  const expertiseSearch = useRecoilValueOr(ExpertiseSearch(page), null);
  const switchedLayout = useSwitchedLayout();
  const spoResult = useSearchSource(expertiseSearch || [], "SPOExpertise");
  const previousResult = useRef(spoResult);

  useEffect(() => {
    previousResult.current = spoResult;
  }, [spoResult]);
  const newResult = previousResult.current !== spoResult;

  const trail = useTrail(spoResult?.results.length || 0, {
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: (prop) =>
      prop === "opacity"
        ? { ...config.gentle, tension: 200, clamp: true }
        : { ...config.gentle, tension: 200 },
    reset: newResult || switchedLayout,
  });

  return spoResult && spoResult.rowCount ? (
    <>
      {trail.map((style, index) => (
        <animated.div key={index} style={style}>
          <Result result={spoResult.results[index]} />
        </animated.div>
      ))}
    </>
  ) : (
    <NoResults />
  );
}

export default ResultsPage;
