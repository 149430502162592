import { ButtonBase } from "@material-ui/core";
import { animated } from "@react-spring/web";
import styled from "styled-components/macro";

export default animated(styled(ButtonBase).attrs({ component: "div" })`
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "details show-more"
    "more-info more-info";
  cursor: pointer;
`);
