import { atom } from "recoil";

export interface ISelectedRedirect {
  redirectName: string | undefined;
  redirectId: string | undefined;
}

const SelectedRedirectState = atom<ISelectedRedirect>({
  key: "SelectedRedirectState",
  default: {
    redirectName: undefined,
    redirectId: undefined,
  },
});

export default SelectedRedirectState;
