import { atom } from "recoil";
import { SelectionOption } from "components/PageControls/SelectionDropdown";

export interface ExpertiseLineOfBusiness extends SelectionOption {}

/**
 * The line of business option selection for the expertise page
 */
export default atom<ExpertiseLineOfBusiness | undefined>({
  key: "ExpertiseLineOfBusiness",
  default: undefined,
});
