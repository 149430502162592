import { Response } from "state/search/expertise/ExpertisePersonProjects";

const documents: Response = [
  {
    UserAlias: null,
    ProjectName: "Qroo",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2018-08-02T09:15:19.215141",
    ModifiedDate: "2018-08-02T15:18:29.927687",
  },
  {
    UserAlias: null,
    ProjectName: "Moffat_Line_Length_Experiment - USCCIN",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2018-05-10T11:17:07.440126",
    ModifiedDate: "2018-05-16T16:23:34.513603",
  },
  {
    UserAlias: null,
    ProjectName: "Caesar_Tonga_20180226 - USCCIN",
    ProjectSource: "nDI",
    ProjectCount: 3,
    DataPath: null,
    DataType: null,
    CreatedDate: "2018-05-07T14:09:24.215319",
    ModifiedDate: "2018-05-07T14:09:24.539215",
  },
  {
    UserAlias: null,
    ProjectName: "Ipanema_Ops_Respud_mvg - CAMVAN - USCCIN",
    ProjectSource: "nDI",
    ProjectCount: 8,
    DataPath: null,
    DataType: null,
    CreatedDate: "2018-04-13T13:04:41.570246",
    ModifiedDate: "2018-04-13T13:04:42.225209",
  },
  {
    UserAlias: null,
    ProjectName: "GreatWhite",
    ProjectSource: "nDI",
    ProjectCount: 4,
    DataPath: null,
    DataType: null,
    CreatedDate: "2018-02-27T13:30:31.909836",
    ModifiedDate: "2018-02-27T13:50:59.698876",
  },
];

export default documents;
