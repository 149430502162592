import { UIEvent, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { useIsLoggingIn } from "services/auth";
import BodyScrollPosition from "state/layout/BodyScrollPosition";
import BodyBottomPosition from "state/layout/BodyBottomPosition";
import RedirectState from "state/Redirect";

import NotFoundPage from "pages/NotFoundPage";
import LandingPage, { path as LandingPath } from "pages/LandingPage";
import AuthCallbackPage, { path as AuthPath } from "pages/AuthCallbackPage";
import AllPage, { path as AllPath } from "pages/AllPage";
import ImagesPage, { path as ImagesPath } from "pages/ImagesPage";
import ExternalPage, { path as ExternalPath } from "pages/ExternalPage";
import SharedDrivesPage, {
  path as ShareDrivesPath,
} from "pages/SharedDrivesPage";
import MapPage, { path as MapPath } from "pages/MapPage";
import ExpertisePage, { path as ExpertisePath } from "pages/ExpertisePage";

import LandingSearch from "pages/LandingPage/LandingSearch";
import AppGrid from "./AppGrid";
import Body from "./Body";
import Header from "./Header";
import PreviewImagesIframe from "./PreviewImagesIframe";
import Redirecting from "components/Redirecting";

function App() {
  const isLoggingIn = useIsLoggingIn();
  const setBodyScrollPosition = useSetRecoilState(BodyScrollPosition);
  const setBodyBottomPosition = useSetRecoilState(BodyBottomPosition);
  const redirectedState = useRecoilValue(RedirectState).redirected;
  const [renderRedirected, setRenderRedirect] = useState(true);

  function bodyScroll(event: UIEvent<HTMLDivElement>) {
    setBodyScrollPosition(event.currentTarget.scrollTop);
  }
  function bodyCallback(element: HTMLDivElement) {
    if (element) {
      setBodyBottomPosition(element.getBoundingClientRect().height);
    }
  }

  if (redirectedState) {
    setTimeout(() => {
      setRenderRedirect(false);
    }, 3000);
  }

  if (isLoggingIn) {
    return <LandingSearch />;
  }

  return (
    <>
      {redirectedState && renderRedirected ? (
        <Redirecting />
      ) : (
        <>
          <AppGrid>
            <Header />
            <Body id="body" onScroll={bodyScroll} ref={bodyCallback}>
              <Switch>
                <Route path={AuthPath} component={AuthCallbackPage} />
                <Route exact path={LandingPath} component={LandingPage} />
                <Route exact path={AllPath} component={AllPage} />
                <Route exact path={ImagesPath} component={ImagesPage} />
                <Route exact path={ExternalPath} component={ExternalPage} />
                <Route
                  exact
                  path={ShareDrivesPath}
                  component={SharedDrivesPage}
                />
                <Route exact path={MapPath} component={MapPage} />
                <Route exact path={ExpertisePath} component={ExpertisePage} />
                <Route component={NotFoundPage} />
              </Switch>
            </Body>
          </AppGrid>
          <PreviewImagesIframe />
        </>
      )}
    </>
  );
}

export default App;
