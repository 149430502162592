import { useState, useEffect, useRef, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation } from "react-router-dom";
import { config, useSpring } from "@react-spring/web";
import { useFormik } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import lightTheme from "theme/light";
import { ThemeProvider as StyledThemeProvider } from "styled-components/macro";
import { ThemeProvider as MaterialThemeProvider } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { testFlag } from "services/testing";
import { useIsLoggedIn, useIsLoggingIn, generateAuthUrl } from "services/auth";
import { useChangeQueryString, useGetPath } from "services/hooks";
import RedirectCode from "state/auth/RedirectCode";
import TestLoginUser from "state/auth/TestLoginUser";
import Query from "state/Query";
import WasLoggedIn from "state/WasLoggedIn";
import { path as AllPath } from "pages/AllPage";
import { path as LandingPath } from "pages/LandingPage";

import Background from "./Background";
import Container from "./Container";
import Progress from "./Progress";
import SearchIcon from "./SearchIcon";
import TextField from "./TextField";
import Form from "./Form";
import ErrorDisplay from "./ErrorDisplay";
import Notice from "./Notice";
import { amplitudeTracker } from "services/helpers";
import { AMPLITUDE_EVENTS } from "services/constants";

export default function LandingSearch() {
  const { t } = useTranslation();
  const testLoginUser = useSetRecoilState(TestLoginUser);
  const changeQueryString = useChangeQueryString();
  const getPath = useGetPath();
  const location = useLocation();
  const [error, setError] = useState<string | false>(false);
  const userIsLoggedIn = useIsLoggedIn();
  const userIsLoggingIn = useIsLoggingIn();
  const redirectCode = useRecoilValue(RedirectCode);
  const query = useRecoilValue(Query);
  const [loginClicked, setLoginClicked] = useState(false);
  const isLoggingIn = Boolean(
    loginClicked || (redirectCode && userIsLoggingIn)
  );
  const wasLoggedIn = useRecoilValue(WasLoggedIn);

  const login = useCallback(
    async ({ query }: { query: string }) => {
      let testTimeout: NodeJS.Timeout;
      if (userIsLoggedIn) {
        changeQueryString({ query }, AllPath);
      } else {
        formRef.current?.querySelector("input")?.blur();
        setLoginClicked(true);

        /**
         * Record what page the user was on when logging in so they
         * can be redirected back to it afterwards.
         */
        const destinationPath =
          location.pathname === LandingPath ? AllPath : location.pathname;
        const destinationPage = getPath(destinationPath, {
          query,
        });
        localStorage.setItem(
          "login-page",
          `${destinationPage}${location.hash}`
        );

        if (testFlag("fakeLogin")) {
          // Login as test user after a short delay to mimic normal login UX
          testTimeout = setTimeout(() => testLoginUser(true), 150);
        } else {
          // Redirect the user to the authorization page
          const authUrl = await generateAuthUrl();
          if (typeof authUrl === "string") {
            window.location.replace(authUrl);
          }
        }

        return () => {
          if (testTimeout) {
            clearTimeout(testTimeout);
          }
        };
      }
    },
    [testLoginUser, changeQueryString, getPath, location, userIsLoggedIn]
  );

  const formik = useFormik({
    initialValues: {
      query,
    },
    onSubmit: (values) => {
      amplitudeTracker(AMPLITUDE_EVENTS.INPUT_SEARCH, { searchTerm: values.query });
      if (!isLoggingIn) {
        login(values);
      }
    },
  });

  // Update the form based on the recoil value changing
  useEffect(
    () => {
      formik.setFieldValue("query", query);
    },
    // eslint-disable-next-line
    [query]
  );

  /**
   * Check for error state in the query string
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("error")) {
      setError(params.get("error_description") || false);
    }
  }, [location]);

  // Wait to see if we can load the token in locally
  const [waitingForInit, setWaitingForInit] = useState(true);
  useEffect(() => {
    const initTimeout = setTimeout(() => {
      setWaitingForInit(false);
    }, 100);
    return () => clearTimeout(initTimeout);
  }, []);

  // Automatically login the user if they load the page with a query
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("query");
    if (!waitingForInit && !userIsLoggedIn && !wasLoggedIn && query !== null) {
      login({ query });
    }
  }, [userIsLoggedIn, location, wasLoggedIn, waitingForInit, login]);

  const formRef = useRef<HTMLFormElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  function onFocus() {
    setIsFocused(true);
  }
  function onBlur() {
    setIsFocused(false);
  }

  const formSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { ...config.gentle, clamp: true },
    delay: 300,
  });

  return (
    <MaterialThemeProvider theme={lightTheme}>
      <StyledThemeProvider theme={lightTheme}>
        <Background isFocused={isFocused}>
          <Container>
            <Form
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              ref={formRef}
            >
              <TextField
                autoFocus
                disabled={isLoggingIn}
                size="small"
                name="query"
                placeholder="Search"
                onChange={formik.handleChange}
                onBlur={onBlur}
                onFocus={onFocus}
                value={formik.values.query}
                style={formSpring}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        data-testid={"searchIcon"}
                        onClick={formik.submitForm}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {error && <ErrorDisplay>{error}</ErrorDisplay>}
            </Form>
            {isLoggingIn && <Progress />}
            <Notice>
              {t(
                "If you find any documents that you feel should not be there, please contact your site owner to get them removed"
              )}
            </Notice>
          </Container>
        </Background>
      </StyledThemeProvider>
    </MaterialThemeProvider>
  );
}
