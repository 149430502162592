import { useRecoilPreviousValue } from "services/hooks";
import ImageSearch from "state/search/image/ImageSearch";
import ResultsCountControl from "components/PageControls/ResultsCount";

function ResultsCount() {
  const spoImageResult = useRecoilPreviousValue(ImageSearch(0), null);
  const numResults = spoImageResult?.totalRows || 0;
  return <ResultsCountControl count={numResults} />;
}

export default ResultsCount;
