import styled, { css } from "styled-components/macro";
import { animated } from "@react-spring/web";
import chroma from "chroma-js";
import { Layout } from "state/search/expertise/ExpertiseLayout";

interface CarouselInterface {
  layout: Layout;
  navButtonWidth: number;
}

const columnLayout = css<CarouselInterface>`
  overflow: visible;
`;
const rowLayout = css<CarouselInterface>`
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`;

export default animated(styled.div<CarouselInterface>`
  position: relative;
  grid-area: top-projects;
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  background: ${({ theme }) =>
    chroma(theme.palette.background.default).darken(0.1).css()};
  ${({ layout }) => (layout === "column" ? columnLayout : rowLayout)}
`);
