import { Response } from "state/search/expertise/ExpertisePersonProjects";

const projectDetails: Response = [
  {
    UserAlias: "USDHFI",
    ProjectName: "GreatWhite",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_dev_perd/gw_wm50/ndi/phase2_2017/faults/WM20.flt",
    DataType: "Fault",
    CreatedDate: "2018-02-27T13:30:31.909836",
    ModifiedDate: "2018-02-27T13:50:59.698876",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "GreatWhite",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_dev_perd/gw_wm50/ndi/phase2_2017/faults/WM50_FLTS_OBS_MFM.flt",
    DataType: "Fault",
    CreatedDate: "2018-02-27T13:30:31.909836",
    ModifiedDate: "2018-02-27T13:50:59.698876",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "GreatWhite",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_dev_perd/gw_wm50/ndi/phase2_2017/events/WM50_Q_Zombie_2017.int",
    DataType: "3Dhorizon",
    CreatedDate: "2018-02-27T13:30:31.909836",
    ModifiedDate: "2018-02-27T13:50:59.698876",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "GreatWhite",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_dev_perd/gw_wm50/ndi/wdp_2017/events/WM50_QZombie_2017.int",
    DataType: "3Dhorizon",
    CreatedDate: "2018-02-27T13:30:31.909836",
    ModifiedDate: "2018-02-27T13:50:59.698876",
  },
];

export default projectDetails;
