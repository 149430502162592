import styled from "styled-components/macro";
import logo from "./xdigi.svg";

function Logo(props: any) {
  return <img src={logo} {...props} alt="Shell" />;
}

export default styled(Logo)`
  height: 30px;
`;
