import { Response } from "state/sources/SiteSearch";

const data: Response = [
  {
    source: "SPO",
    results: [
      {
        path: "https://eu001-sp.shell.com/sites/AAFAA4377",
      },
      {
        path: "https://eu001-sp.shell.com/sites/AAFAA4259",
      },
      {
        path: "https://eu001-sp.shell.com/sites/AAAAA0703",
      },
      {
        path: "https://eu001-sp.shell.com/sites/AAFAA3273",
      },
      {
        path: "https://eu001-sp.shell.com/sites/AAAAA9424",
      },
      {
        path: "https://eu001-sp.shell.com/sites/aafaa4973",
      },
      {
        path: "https://eu001-sp.shell.com/sites/aaaab0083",
      },
    ],
  },
];

export default data;
