import { animated, useSpring } from "@react-spring/web";
import styled from "styled-components/macro";

const Container = animated(styled.div`
  font-weight: bold;
`);

export default function Message({ style, ...props }: any) {
  const spring = useSpring({ from: { opacity: 0 }, to: { opacity: 1 } });
  return <Container style={{ ...spring, ...style }} {...props} />;
}
