import styled from "styled-components/macro";

export default styled.div`
  position: relative;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  line-height: 38px;
  padding: ${({ theme }) => `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`};
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.palette.text.primary};
  }
`;
