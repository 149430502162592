import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { saveAs } from "file-saver";

import LoadingDisplay from "components/LoadingDisplay";

import { jsonToCSV } from "./export.worker";
import Container from "./Container";
import Overlay from "./Overlay";
import { amplitudeTracker } from "services/helpers";
import { AMPLITUDE_EVENTS } from "services/constants";

function ExportCSV({
  fetchData,
  label,
  disabled,
  limit = 1000,
}: {
  fetchData: (page: number) => Promise<any>;
  label: string;
  disabled?: boolean;
  limit?: number;
}) {
  const { t } = useTranslation();
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState(false);

  async function exportNow() {
    amplitudeTracker(AMPLITUDE_EVENTS.EXPORT_TO_EXCEL);
    setIsExporting(true);
    try {
      let data: Array<Object> = [];
      let page = 0;
      while (data.length < limit) {
        const response = await fetchData(page);
        if (response == null) {
          throw "No response received from API.";
        }
        data = [...data, ...response.results];
        page += 1;
        const value = response.totalRows;
        if (value < limit) {
          limit = value;
        }
      }
      saveAs(
        new Blob([await jsonToCSV(data.slice(0, limit))], {
          type: "text/plain;charset=utf-8",
        }),
        "export.csv"
      );
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setIsExporting(false);
  }

  return (
    <Container>
      {isExporting && (
        <Overlay>
          <LoadingDisplay />
        </Overlay>
      )}
      {error && (
        <Overlay hidden>
          <Typography color="error">{t("Error loading")}</Typography>
        </Overlay>
      )}
      <div onClick={exportNow}>{label}</div>
    </Container>
  );
}

export default ExportCSV;
