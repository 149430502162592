import { useState, useCallback, useEffect, ReactNode } from "react";
import { createPortal } from "react-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import ViewportDimensions from "state/layout/ViewportDimensions";
import HeaderBarHeight from "state/layout/HeaderBarHeight";
import HeaderBarPortal from "state/layout/HeaderBarPortal";

import Content from "./Content";

function HeaderBar({ children }: { children: ReactNode }) {
  const setHeaderBarHeight = useSetRecoilState(HeaderBarHeight);
  const headerBarPortal = useRecoilValue(HeaderBarPortal);
  const viewportDimensions = useRecoilValue(ViewportDimensions);

  const [content, setContent] = useState<HTMLDivElement>();
  const contentCallback = useCallback((element) => setContent(element), []);
  useEffect(() => {
    if (content) {
      setHeaderBarHeight(content.getBoundingClientRect().height);
    }
    return () => setHeaderBarHeight(0);
  }, [setHeaderBarHeight, content, viewportDimensions]);

  return headerBarPortal
    ? createPortal(
        <Content ref={contentCallback}>{children}</Content>,
        headerBarPortal
      )
    : null;
}

export default HeaderBar;
