import { selector } from "recoil";
import { AxiosResponse } from "axios";

import { useAPI } from "services/network";

import SourceTree, { getSite, getSiteList } from "./SourceTree";

export const endpoint = "/api/v1/SearchApi/siteSearch";

interface SiteSearchResult {
  source: "SPO";
  results: Array<{ path: string }>;
}

export interface Request {
  hiddenConstraints: string;
  rowLimit: number;
  page: 0;
  queryText: "contentclass:STS_Site";
  scope: "All";
  source: "SPOSites";
}
export interface Response extends Array<SiteSearchResult> {}

/**
 * Fetches the site-search result from the backend
 * (used for access check in drop down)
 */
export default selector({
  key: "SiteSearch",
  get: async ({ get }) => {
    const siteList = getSiteList(get(SourceTree));
    const requestBody: Request = {
      hiddenConstraints: `SPSiteUrl:(${siteList
        .map((s) => s.toLowerCase())
        .join(" OR ")})`,
      rowLimit: 500,
      page: 0,
      queryText: "contentclass:STS_Site",
      scope: "All",
      source: "SPOSites",
    };
    const response = (
      await useAPI.post<Request, AxiosResponse<Response>>(endpoint, requestBody)
    ).data[0]?.results?.map(({ path }) => getSite(path));
    return response;
  },
});
