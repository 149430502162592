import { FormEvent, ReactNode } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { useTheme } from "@material-ui/core";

import DateRangeState, { DateRange } from "state/DateRange";
import DropDown from "components/DropDown";

import Container from "./Container";
import ModifiedRange from "./ModifiedRange";
import DateDisplay from "./DateDisplay";
import Button from "./Button";

import "react-date-range/dist/styles.css";
import "./lightTheme.scss";
import "./darkTheme.scss";
import { amplitudeTracker } from "services/helpers";
import { AMPLITUDE_EVENTS } from "services/constants";

const dateFormat = "MMMM d yyyy";

function RangeSelect({ section }: { section: string }) {
  const { t } = useTranslation();
  const theme = useTheme() as any;
  const [selected, setSelected] = useRecoilState(
    DateRangeState(`${section}-selected`)
  );
  const [state, setState] = useRecoilState(DateRangeState(section));
  const themeType = theme.palette.type;

  let label: ReactNode = t("Last Modified");
  if (state !== null) {
    label = (
      <ModifiedRange>
        <span>Modified:</span>
        <DateDisplay>{format(state[0].startDate, dateFormat)}</DateDisplay>-
        <DateDisplay>{format(state[0].endDate, dateFormat)}</DateDisplay>
      </ModifiedRange>
    );
  }

  // Amplidue Event: Click_Last_Modified
  return (
    <DropDown
      amplitudeFunc={() => amplitudeTracker(AMPLITUDE_EVENTS.CLICK_LAST_MODIFIED)}
      label={label}
      align="left"
    >
      {({ close }) => {
        function handleSubmit(event: FormEvent) {
          amplitudeTracker(AMPLITUDE_EVENTS.APPLY_DATE_RANGE);
          event.preventDefault();
          setState(selected);
          close();
        }
        function clearRange() {
          amplitudeTracker(AMPLITUDE_EVENTS.CLEAR_DATE_RANGE);
          setState(null);
          close();
        }
        function handleChange(item: any) {
          setSelected([item.selection]);
        }
        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <Button
                area="clear"
                variant="text"
                onClick={clearRange}
                disabled={state === null}
              >
                {t("Clear date range")}
              </Button>
              <Button type="submit" disabled={selected === state} area="submit">
                {t("Apply date range")}
              </Button>
            </Container>
            <DateRangePicker
              rangeColors={[theme.palette.primary.main]}
              className={themeType}
              onChange={handleChange}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={selected as [DateRange]}
              direction="horizontal"
            />
          </form>
        );
      }}
    </DropDown>
  );
}

export default RangeSelect;
