import { Response } from "state/search/expertise/ExpertisePersonProjects";

const documents: Response = [
  {
    UserAlias: null,
    ProjectName: "GC_BM_GVB",
    ProjectSource: "nDI",
    ProjectCount: 3,
    DataPath: null,
    DataType: null,
    CreatedDate: "2016-07-18T14:27:48.10707",
    ModifiedDate: "2016-07-18T16:05:16.474063",
  },
  {
    UserAlias: null,
    ProjectName: "From_Session",
    ProjectSource: "nDI",
    ProjectCount: 34,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-09-09T09:02:36.77129",
    ModifiedDate: "2019-05-03T12:21:08.509443",
  },
  {
    UserAlias: null,
    ProjectName: "Wing",
    ProjectSource: "nDI",
    ProjectCount: 3,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-02-16T16:58:05.972185",
    ModifiedDate: "2015-03-06T18:03:30.468316",
  },
  {
    UserAlias: null,
    ProjectName: "Vinyl_Kildrummy",
    ProjectSource: "nDI",
    ProjectCount: 12,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-12-05T12:57:49.065721",
    ModifiedDate: "2017-04-14T12:28:52.947082",
  },
  {
    UserAlias: null,
    ProjectName: "Shadow_well_Plan",
    ProjectSource: "nDI",
    ProjectCount: 8,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-12-05T10:10:58.953475",
    ModifiedDate: "2017-04-07T13:26:58.664647",
  },
  {
    UserAlias: null,
    ProjectName: "Taggart_Deep",
    ProjectSource: "nDI",
    ProjectCount: 6,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-10-23T13:36:44.948524",
    ModifiedDate: "2014-10-27T10:13:07.177734",
  },
  {
    UserAlias: null,
    ProjectName: "Subset_Strat3D",
    ProjectSource: "nDI",
    ProjectCount: 4,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-06-18T09:33:29.095643",
    ModifiedDate: "2014-07-08T13:53:35.226619",
  },
  {
    UserAlias: null,
    ProjectName: "ShadowBasin_Mini",
    ProjectSource: "nDI",
    ProjectCount: 29,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-02-20T16:40:53.761854",
    ModifiedDate: "2015-10-28T08:56:26.135347",
  },
  {
    UserAlias: null,
    ProjectName: "ShadowBasin - USJMHE",
    ProjectSource: "nDI",
    ProjectCount: 23,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-02-20T15:48:07.451525",
    ModifiedDate: "2014-02-20T18:09:55.03642",
  },
];

export default documents;
