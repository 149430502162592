import { Response } from "state/search/expertise/ExpertisePersonProjects";

const documents: Response = [
  {
    UserAlias: null,
    ProjectName: "JFH_EGOM_PRESALT",
    ProjectSource: "nDI",
    ProjectCount: 10,
    DataPath: null,
    DataType: null,
    CreatedDate: "2020-11-12T16:44:58.486815",
    ModifiedDate: "2020-12-02T11:06:52.499192",
  },
  {
    UserAlias: null,
    ProjectName: "JFH_EGOM_DECLJUST",
    ProjectSource: "nDI",
    ProjectCount: 10,
    DataPath: null,
    DataType: null,
    CreatedDate: "2020-07-15T11:50:15.888712",
    ModifiedDate: "2021-01-13T13:23:29.086514",
  },
  {
    UserAlias: null,
    ProjectName: "JFH_EGOM_2D",
    ProjectSource: "nDI",
    ProjectCount: 6,
    DataPath: null,
    DataType: null,
    CreatedDate: "2020-04-28T10:55:17.865124",
    ModifiedDate: "2020-08-19T10:48:46.986032",
  },
  {
    UserAlias: null,
    ProjectName: "JFH_EGOM_2020",
    ProjectSource: "nDI",
    ProjectCount: 6,
    DataPath: null,
    DataType: null,
    CreatedDate: "2020-04-22T10:33:02.445763",
    ModifiedDate: "2020-05-05T08:05:47.29176",
  },
  {
    UserAlias: null,
    ProjectName: "New Project",
    ProjectSource: "nDI",
    ProjectCount: 3,
    DataPath: null,
    DataType: null,
    CreatedDate: "2020-03-18T13:13:07.175459",
    ModifiedDate: "2020-03-18T13:34:05.709648",
  },
  {
    UserAlias: null,
    ProjectName: "JFH_MegeMerge_GOM315",
    ProjectSource: "nDI",
    ProjectCount: 3,
    DataPath: null,
    DataType: null,
    CreatedDate: "2020-02-18T09:08:41.895923",
    ModifiedDate: "2020-03-06T13:01:44.112958",
  },
  {
    UserAlias: null,
    ProjectName: "JFH_EGOM",
    ProjectSource: "nDI",
    ProjectCount: 15,
    DataPath: null,
    DataType: null,
    CreatedDate: "2019-11-20T09:29:20.702986",
    ModifiedDate: "2020-07-13T12:27:45.316044",
  },
  {
    UserAlias: null,
    ProjectName: "JFH_GOM",
    ProjectSource: "nDI",
    ProjectCount: 4,
    DataPath: null,
    DataType: null,
    CreatedDate: "2019-08-08T11:06:10.134387",
    ModifiedDate: "2020-02-18T09:00:40.500598",
  },
];

export default documents;
