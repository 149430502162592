import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FileIcon, defaultStyles, DefaultExtensionType } from "react-file-icon";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { useGetPath } from "services/hooks";
import { formatBytes, formatDateTime, timeZone } from "services/helpers";
import { Hit } from "state/search/sharedDrives/SharedDrives";
import { path as ExpertisePath } from "pages/ExpertisePage";

import Paper from "./Paper";
import Icon from "./Icon";
import Meta from "./Meta";
import Title from "./Title";
import Path from "./Path";
import Summary from "./Summary";
import Content from "./Content";
import ShowMore from "./ShowMore";
import Property from "./Property";
import Information from "./Information";
import Authors from "./Authors";
import DisabledAuthor from "./DisabledAuthor";
import CopyPath from "./CopyPath";

const disabledAuthors = ["System Account", "Unknown"];

function Result({ result }: { result: Hit }) {
  const { t } = useTranslation();
  const fileExtension = result._source[
    "file-extension"
  ].toLowerCase() as DefaultExtensionType;
  const [shouldShowAll, setShouldShowAll] = useState(false);
  const getPath = useGetPath();
  const authors = [result._source["file-author"]];
  const summary = result.highlight?.["file-content"] ? result.highlight?.["file-content"][0] : "";

  function toggleShowAll() {
    setShouldShowAll(!shouldShowAll);
  }

  return (
    <Paper>
      <Content>
        <Icon>
          <FileIcon
            radius={0}
            extension={fileExtension}
            {...defaultStyles[fileExtension]}
          />
        </Icon>
        <Information>
          <Title
            target="_blank"
            rel="noopener noreferrer"
          >
            {result._source["file-name"]}
          </Title>
          <Path
            target="_blank"
            rel="noopener noreferrer"
          >
            <CopyPath copyUrl={result._source["file-path"] || ""}>{result._source["file-display_path"] || ""}</CopyPath> 
          </Path>
          {summary && (
            <Summary
              dangerouslySetInnerHTML={{
                __html: summary,
              }}
            />
          )}
          <Collapse in={shouldShowAll}>
            <Meta>
              <Property label={t("Author(s)")}>
                <Authors>
                  {authors.map((author, index) => {
                    const authorDisplay = disabledAuthors.includes(author) ? (
                      <DisabledAuthor>{author}</DisabledAuthor>
                    ) : (
                      <Link to={getPath(ExpertisePath, { query: author })}>
                        {author}
                      </Link>
                    );
                    const hasMoreAuthors = index < authors.length - 1;
                    return (
                      <Fragment key={index}>
                        {authorDisplay}
                        {hasMoreAuthors ? <span>,&nbsp;</span> : null}
                      </Fragment>
                    );
                  })}
                </Authors>
              </Property>
              <Property label={t("Last Modified Time")}>
                {formatDateTime(result._source["file-modified_date"])} (
                {timeZone})
              </Property>
              <Property label={t("Created At")}>
                {formatDateTime(result._source["file-created_date"])} (
                {timeZone})
              </Property>
              <Property label={t("Size")}>
                {formatBytes(result._source["file-size"])}
              </Property>
            </Meta>
          </Collapse>
        </Information>
      </Content>
      <ShowMore onClick={toggleShowAll}>
        <IconButton size="small" aria-label={t("See details")} component="div">
          {shouldShowAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </ShowMore>
    </Paper>
  );
}

export default Result;
