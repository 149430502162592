import chroma from "chroma-js";
import { createGlobalStyle } from "styled-components/macro";

const scrollBarSize = 11;

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100vh;
    background: ${({ theme }) => theme.palette.background.main};
  }
  
  ::-webkit-scrollbar {
    width: ${`${scrollBarSize}px`};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: ${({ theme }: any) => `${theme.shape.borderRadius}px`};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => chroma(theme.palette.primary.main).darken().css()};
    &:visited {
      color: ${({ theme }) =>
        chroma(theme.palette.primary.main).darken().css()};
    }
    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
      cursor: pointer;
    }
    &:active {
      text-decoration: none;
    }
  }
`;

export { scrollBarSize };
export default GlobalStyle;
