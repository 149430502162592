import { Typography } from "@material-ui/core";
import { Layout } from "state/search/expertise/ExpertiseLayout";
import styled, { css } from "styled-components";

interface TitleInterface {
  offset: number;
  layout: Layout;
}

const rowLayout = css<TitleInterface>`
  place-items: center;
  margin-left: ${({ theme, offset }) => `${offset + theme.spacing(2)}px`};
  margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
`;
const columnLayout = css<TitleInterface>`
  place-items: start;
  margin: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px 0`};
`;

export default styled(Typography)<TitleInterface>`
  place-self: start;
  font-weight: bold;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  ${({ layout }) => (layout === "row" ? rowLayout : columnLayout)}
  color: ${({ theme }) => theme.palette.primary.main};
`;
