import { useTranslation } from "react-i18next";

import { Project } from "state/search/expertise/ExpertisePersonProjects";
import { formatDateTime } from "services/helpers";

import Container from "./Container";
import PathValue from "./PathValue";
import Value from "./Value";
import Label from "./Label";
import Text from "./Text";
import CopyPath from "./CopyPath";
import DataType from "./DataType";
import DataTypeLabel from "./DataTypeLabel";
import Dates from "./Dates";
import DateDiv from "./Date";

export default function ProjectDisplay({
  project,
  style,
}: {
  project: Project;
  style: any;
}) {
  const { t } = useTranslation();
  return (
    <Container style={style}>
      <PathValue>
        <Value>
          <Label>{t("Data Type")}</Label>
          <DataType>
            <DataTypeLabel>{project.DataType}</DataTypeLabel>
          </DataType>
        </Value>
        <CopyPath>{project.DataPath || ""}</CopyPath>
      </PathValue>
      <Dates>
        <DateDiv area="created">
          <Label>{t("Created")}</Label>
          <Text>
            {formatDateTime(project.CreatedDate || "", "LLLL do yyyy")}
          </Text>
        </DateDiv>
        <DateDiv area="modified">
          <Label>{t("Modified")}</Label>
          <Text>
            {formatDateTime(project.ModifiedDate || "", "LLLL do yyyy")}
          </Text>
        </DateDiv>
      </Dates>
    </Container>
  );
}
