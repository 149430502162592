import { REFINER_BANNER_CONSTANTS } from "services/constants";
import styled from "styled-components/macro";

export default styled.div<{ lineHeight?: number }>`
  ${({ theme, lineHeight }) => {
    if (lineHeight) {
      return `
        line-height: ${lineHeight}px;
        color: ${REFINER_BANNER_CONSTANTS.SKY_DEFAULT};
        font-weight: 600;
        grid-column-gap: 0;
      `;
    }
    return `
      grid-column-gap: ${theme.spacing(1)}px;
      line-height: 38px;
    `;
  }}
  display: grid;
  grid-template-columns: 1fr auto;
  place-items: center;
  user-select: none;
`;
