import styled from "styled-components";

const Container = styled.div`
  max-width: 100%;
  display: grid;
  margin: ${({ theme }) => `${theme.spacing(0.5)}px 0`};
`;

const Label = styled.div`
  max-width: 100%;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default function BasicInfo({ children }: { children: string }) {
  return (
    <Container>
      <Label title={children}>{children}</Label>
    </Container>
  );
}
