import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { useHasQuery } from "state/Query";

import PageContainer from "components/PageContainer";
import PleaseEnterQuery from "components/PleaseEnterQuery";

// The page path relative to the application root
const path = "/map";

function MapPage() {
  const { t } = useTranslation();
  const hasQuery = useHasQuery();

  if (!hasQuery) {
    return <PleaseEnterQuery />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("Map")} - {t("APP_NAME")}
        </title>
      </Helmet>
      <PageContainer>MapPage TODO</PageContainer>
    </>
  );
}

export { path };
export default MapPage;
