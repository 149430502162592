import { Response } from "state/search/all/AllSearch";

const documents: Response = [
  {
    source: "SPO",
    rowCount: 0,
    totalRows: 0,
    totalRowsIncludingDuplicates: 0,
    refinerResults: [],
    results: [],
    spellingSuggestion: "",
  },
];

export default documents;
