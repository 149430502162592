import {
  ChangeEvent,
  useState,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
  HTMLProps,
  useEffect,
} from "react";
import { v4 as uuid } from "uuid";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { Node, Tree } from "state/treeTypes";
import { amplitudeTracker, randomInt } from "services/helpers";

import Container from "./Container";
import Content from "./Content";
import LabelComponent from "./Label";
import Children from "./Children";
import { AMPLITUDE_EVENTS } from "services/constants";

function NodeEl({
  node,
  tree,
  allState,
  selectedState,
  Label: UserLabel,
  ...props
}: {
  node: Node;
  tree: Tree;
  className?: string;
  allState: [boolean, Dispatch<SetStateAction<boolean>>];
  selectedState: [Set<Node>, Dispatch<SetStateAction<Set<Node>>>];
  Label?: (props: HTMLProps<HTMLLabelElement>) => JSX.Element;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(node.level === "1");
  const [uncheckAll, setUncheckAll] = allState;
  const [selected, setSelected] = selectedState;
  const Label = UserLabel ? UserLabel : LabelComponent;
  const id = useMemo(() => uuid(), []);

  // rendering speed optimization, delay child rendering
  const [canRenderChildren, setCanRenderChildren] = useState(false);
  useEffect(() => {
    const renderTimeout = setTimeout(
      () => setCanRenderChildren(true),
      randomInt(400, 650)
    );
    return () => clearTimeout(renderTimeout);
  }, [node]);

  function toggle() {
    setIsOpen(!isOpen);
  }

  const parentIsChecked = useCallback(
    (node: Node): boolean => {
      if (node.parent) {
        if (selected.has(node.parent)) {
          return true;
        }
        return parentIsChecked(node.parent);
      }
      return false;
    },
    [selected]
  );
  function seekRoot(node: Node): Node {
    if (!node.parent?.parent) {
      return node;
    }
    const parent = node.parent;
    if (parent) {
      addSiblings(parent);
      if (parent.parent?.parent !== null) {
        return seekRoot(parent);
      }
      return parent;
    }
    return parent ? parent : node;
  }
  function addOtherRoots(node: Node) {
    const myRoot = seekRoot(node);
    const all = tree[0];
    all.children?.forEach((root) => {
      if (root !== myRoot) {
        selected.add(root);
      }
    });
  }
  function addSiblings(node?: Node | null) {
    if (node) {
      const parent = node.parent;
      if (parent) {
        parent.children?.forEach((child) => {
          if (child !== node) {
            selected.add(child);
          }
        });
      }
    }
  }
  function removeChildren(node: Node) {
    node.children?.forEach((child) => {
      selected.delete(child);
      if (child.children?.length) {
        removeChildren(child);
      }
    });
  }
  function addNode(node: Node) {
    selected.add(node);
  }
  function removeNode(node: Node) {
    selected.delete(node);
  }
  function removeParents(node: Node) {
    const parent = node.parent;
    if (parent) {
      selected.delete(parent);
      if (parent.parent) {
        removeParents(parent);
      }
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    amplitudeTracker(AMPLITUDE_EVENTS.SELECTING_NEW_SOURCE_FILTER, {
      source: node.text,
    });
    const isNotRoot = node.level !== "1";
    if (selected.size > 0) {
      if (isNotRoot) {
        removeChildren(node);
        if (event.target.checked) {
          addNode(node);
        } else {
          removeNode(node);
          const siblingAreChecked = node.parent?.children?.reduce(
            (acc, child) => {
              return acc || selected.has(child);
            },
            false
          );
          if (!siblingAreChecked && parentIsChecked(node)) {
            addSiblings(node);
            addSiblings(node.parent);
          }
        }
        removeParents(node);
        if (selected.size === 0) {
          setUncheckAll(true);
          setSelected(new Set(selected));
          return;
        }
      } else {
        selected.clear();
      }
    } else if (isNotRoot) {
      if (event.target.checked) {
        addNode(node);
      } else {
        addOtherRoots(node);
        addSiblings(node);
      }
    }
    if (isNotRoot || event.target.checked) {
      setUncheckAll(false);
    } else {
      setUncheckAll(true);
    }
    setSelected(new Set(selected));
  }

  const isAnyChildrenChecked = useCallback(
    (node: Node): boolean => {
      return (
        node.children?.reduce(
          (isChecked, child) =>
            isChecked || selected.has(child) || isAnyChildrenChecked(child),
          false as boolean
        ) || false
      );
    },
    [selected]
  );
  const isAllChildrenChecked = useCallback(
    (node: Node): boolean =>
      node.children?.reduce((acc, child) => {
        return acc && (selected.has(child) || isAllChildrenChecked(child));
      }, true as boolean) || false,
    [selected]
  );
  const anyChildrenAreChecked = useMemo(
    () => isAnyChildrenChecked(node),
    [isAnyChildrenChecked, node]
  );
  const allChildrenAreChecked = useMemo(
    () => isAllChildrenChecked(node),
    [isAllChildrenChecked, node]
  );
  const isChecked = useMemo(
    () =>
      (selected.size === 0 && !uncheckAll) ||
      selected.has(node) ||
      parentIsChecked(node) ||
      anyChildrenAreChecked,
    [selected, node, parentIsChecked, anyChildrenAreChecked, uncheckAll]
  );

  let toggleButton = null;
  if (node.children?.length && node.level === "2") {
    toggleButton = isOpen ? (
      <IconButton aria-label="open" onClick={toggle} size="small">
        <KeyboardArrowDownIcon />
      </IconButton>
    ) : (
      <IconButton aria-label="open" onClick={toggle} size="small">
        <KeyboardArrowRightIcon />
      </IconButton>
    );
  }

  return (
    <Container {...props} isRoot={node.level === "1"}>
      <Content haschildren={Boolean(toggleButton) ? "yes" : "no"}>
        {toggleButton}
        <Checkbox
          id={id}
          size="small"
          checked={isChecked}
          indeterminate={anyChildrenAreChecked && !allChildrenAreChecked}
          onChange={handleChange}
        />
        <Label htmlFor={id} node={node}>
          {node.text}
        </Label>
      </Content>
      {node.children?.length &&
        canRenderChildren &&
        isOpen &&
        // hiding library level (3) child items
        node.level !== "3" && (
          <Children padding={node.level !== "1"}>
            {node.children.map((child, index) => (
              <NodeEl
                key={index}
                node={child}
                tree={tree}
                allState={allState}
                selectedState={selectedState}
                Label={Label}
              />
            ))}
          </Children>
        )}
    </Container>
  );
}

export default NodeEl;
