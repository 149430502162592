import { Typography } from "@material-ui/core";
import { Node } from "state/treeTypes";
import styled from "styled-components/macro";

export default styled(Typography).attrs({ component: "label" })<{
  htmlFor: string;
  node: Node;
}>`
  padding: ${({ theme }) =>
    `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px 0`};
  font-size: 13px;
  cursor: pointer;
  width: 100%;
`;
