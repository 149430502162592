import { useRecoilPreviousValue } from "services/hooks";
import SharedDrives, { Response } from "state/search/sharedDrives/SharedDrives";
import ResultsCountControl from "components/PageControls/ResultsCount";

function ResultsCount() {
  const sharedDrives = useRecoilPreviousValue<Response, null>(
    SharedDrives(0),
    null
  );
  const numResults = sharedDrives?.hits.total.value || 0;
  return <ResultsCountControl count={numResults} />;
}

export default ResultsCount;
