import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ColumnIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20,4H4C2.9,4,2,4.9,2,6l0,12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V9.2h16V18z M20,8H4V6h16
	V8z"
      />
    </SvgIcon>
  );
}
