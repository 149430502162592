import { Typography } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled.div`
  display: grid;
  place-items: center;
  grid-area: image;
  background: ${({ theme }) => theme.palette.background.paper};
  position: relative;
  z-index: 1;
`;

const Message = styled(Typography)`
  font-size: 13px;
  font-weight: bold;
  cursor: default;
`;

function ErrorDisplay({ children }: { children: string }) {
  return (
    <Container>
      <Message color="textSecondary">{children}</Message>
    </Container>
  );
}

export default ErrorDisplay;
