import { Button } from "@material-ui/core";
import { MAIL_BUTTON_CONSTANTS } from "services/constants";
import styled from "styled-components";

const MailButton = styled(Button)`
  display: flex;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 0.125rem;
  background: ${MAIL_BUTTON_CONSTANTS.SKY_DEFAULT};

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
`;

export default MailButton;
