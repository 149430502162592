import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import ExternalSearch, { Response } from "state/search/external/ExternalSearch";
import ExternalSearchPage from "state/search/external/ExternalSearchPage";
import LoadingDisplay from "components/LoadingDisplay";

import ResultsPage from "./ResultsPage";
import LoadMore from "./LoadMore";

function Results() {
  const firstPageLoadable = useRecoilValueLoadable(ExternalSearch(0));
  const externalSearchPage = useRecoilValue(ExternalSearchPage);
  const pageList = externalSearchPage
    ? [...Array(externalSearchPage).keys()]
    : [0];
  const results: Response | null =
    firstPageLoadable.state === "hasValue" ? firstPageLoadable.contents : null;
  const hasMorePages = useMemo(() => {
    if (results && results.searchResult.statistics.totalHits) {
      const rowCount = results.searchResult.data.records.length;
      const currentRow = externalSearchPage * rowCount;
      return currentRow < results.searchResult.statistics.totalHits;
    }
    return false;
  }, [externalSearchPage, results]);

  return firstPageLoadable.state !== "loading" ? (
    <>
      {pageList.map((page) => (
        <ResultsPage page={page} key={page} />
      ))}
      {hasMorePages && <LoadMore />}
    </>
  ) : (
    <LoadingDisplay style={{ minHeight: "200px" }} />
  );
}

export default Results;
