import styled from "styled-components/macro";

export default styled.div`
  width: 100%;
  grid-area: information;
  display: grid;
  grid-auto-flow: row;
  place-items: center start;

  .highlight {
    font-weight: bold;
  }
`;
