import styled from "styled-components/macro";
import chroma from "chroma-js";

export default styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "left center right";
  min-height: 58px;
  background: ${({ theme }) =>
    chroma(theme.palette.background.default).darken(0.2).css()};
  border: ${({ theme }) =>
    `1px solid ${chroma(theme.palette.background.default).darken(0.5).css()}`};
`;
