import { Typography } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(Typography).attrs({ component: "div" })`
  grid-area: value;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  place-items: center start;
`;
