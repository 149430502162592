import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import SPAuthenticated from "state/SPAuthenticated";

const iframeAuthenticationUrl =
  "https://eu001-sp.shell.com/sites/AAFAA4504/Unily3LoginHandler.aspx";

export default function PreviewImagesIframe() {
  const setSPAuthenticated = useSetRecoilState(SPAuthenticated);
  useEffect(() => {
    if (window) {
      window.addEventListener("message", function (event) {
        if (
          iframeAuthenticationUrl.indexOf(event.origin) === 0 &&
          event.data === "UnilySPAuthenticated"
        ) {
          setSPAuthenticated(true);
        }
      });
    }
  }, [setSPAuthenticated]);

  return (
    <iframe
      title="authenticator"
      src={iframeAuthenticationUrl}
      style={{ display: "none" }}
    ></iframe>
  );
}
