import Container from "./Container";
import Logo from "./Logo";
import Flexbox from "./Flexbox";
import Header from "./Header";
import Phrase from "./Phrase";
import { CircularProgress } from "@material-ui/core";
import { REDIRECTING_CONSTANTS } from "services/constants";

const Redirecting = () => {
  return (
    <Container>
      <Flexbox>
        <CircularProgress size={REDIRECTING_CONSTANTS.CIRCULAR_PROGRESS_SIZE} />
        <Flexbox gap={REDIRECTING_CONSTANTS.FLEXBOX_SIZE}>
          <Header>{REDIRECTING_CONSTANTS.HEADER_TEXT}</Header>
          <Phrase>{REDIRECTING_CONSTANTS.PHRASE_TEXT}</Phrase>
          <Logo />
        </Flexbox>
      </Flexbox>
    </Container>
  );
};

export default Redirecting;
