import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { config, useSpring } from "@react-spring/web";
import { Waypoint } from "react-waypoint";
import Button from "@material-ui/core/Button";

import ExpertiseSearchPage from "state/search/expertise/ExpertiseSearchPage";

import Container from "./Container";

export default function LoadMoreButton() {
  const { t } = useTranslation();
  const [page, setPage] = useRecoilState(ExpertiseSearchPage);

  const spring = useSpring({
    from: { opacity: 0, transform: "translateY(30px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: (prop) =>
      prop === "opacity" ? { ...config.gentle, clamp: true } : config.gentle,
    delay: 1500,
  });

  function bumpPageCount() {
    setPage(page + 1);
  }

  return (
    <Container style={spring}>
      <Waypoint onEnter={bumpPageCount}>
        <Button variant="text" onClick={bumpPageCount}>
          {t("Load more")}
        </Button>
      </Waypoint>
    </Container>
  );
}
