import { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValueLoadable, RecoilValue, useRecoilValue, useRecoilState } from "recoil";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Result } from "state/search/all/AllSearch";
import SharedDrivesPage from "state/search/sharedDrives/SharedDrivesPage";

/**
 * Hook to retrieve a callback function which will change the user query.
 * @returns callback
 */
function useChangeQueryString() {
  const history = useHistory();
  const location = useLocation();
  const getPath = useGetPath();
  const [sharedDrivePage, setSharedDrivePage] = useRecoilState(SharedDrivesPage);

  /**
   * Callback function which will change the query string (used for navigation)
   * @param queryParams The query params to change to
   * @param path (optional) the page path to render into the url
   */
  function changeQueryString(
    queryParams: { [key: string]: string },
    path: string = location.pathname
  ) {
    if(sharedDrivePage !== 0){
      setSharedDrivePage(0);
    }
    history.push(getPath(path, queryParams));
  }

  return changeQueryString;
}

/**
 * Hook that returns the current or previous recoil value.
 *
 * 1. If the recoil value is loading, and it is the initial load, it returns the initial value.
 * 2. If the recoil value is loading, and it is NOT the initial load, it returns the
 *    previous value.
 * 3. If the recoil value has loaded, it returns that value.
 *
 * @param recoilValue The recoil value that you wish to read
 * @param initialValue The initial value returned if/when the loadable is in a loading state
 * @returns The value, the previous value, or the initialValue
 */
function useRecoilPreviousValue<Loadable, InitialValue extends any>(
  recoilValue: RecoilValue<Loadable>,
  initialValue: InitialValue
): Loadable | InitialValue {
  const loadable = useRecoilValueLoadable(recoilValue);
  const [previousValue, setPreviousValue] = useState<Loadable | InitialValue>(
    initialValue
  );
  return useMemo(() => {
    let currentValue = previousValue;
    if (loadable.state === "hasValue") {
      currentValue = loadable.valueOrThrow();
      setPreviousValue(currentValue);
    }
    return currentValue;
    // eslint-disable-next-line
  }, [loadable]);
}

/**
 * Hook that returns the current recoil value or null if it doesn't have a value
 * @param recoilValue The recoil value that you wish to read
 * @returns The value or null
 */
function useRecoilValueOrNull<Loadable>(
  recoilValue: RecoilValue<Loadable>
): Loadable | null {
  const loadable = useRecoilValueLoadable(recoilValue);
  return loadable.state === "hasValue" ? loadable.contents : null;
}

/**
 * Hook used to retrieve a recoil state value, or return something else
 * if it is loading/busy.
 * @param recoilValue The recoil state variable
 * @param orValue The value to return if its loading
 * @returns
 */
function useRecoilValueOr<Loadable, OrValue>(
  recoilValue: RecoilValue<Loadable>,
  orValue: OrValue
): Loadable | OrValue {
  const loadable = useRecoilValueLoadable(recoilValue);
  return loadable.state === "hasValue" ? loadable.contents : orValue;
}

/**
 * Returns flag indicating if the recoil value has changed
 * @param recoilValue The recoil value to watch
 * @returns boolean indicating if the value changed
 */
function useRecoilValueChanged<RecoilVal>(
  recoilValue: RecoilValue<RecoilVal>
): boolean {
  const value = useRecoilValue(recoilValue);
  const oldValue = useRef(value);
  useEffect(() => {
    oldValue.current = value;
  }, [value]);
  return oldValue.current !== value;
}

/**
 * Hook which returns a flag indicating if the user changed pages
 * @returns boolean flag indicating if the user changed pages
 */
function useChangedPage() {
  const location = useLocation();
  const prevLocation = useRef(location.pathname);
  useEffect(() => {
    prevLocation.current = location.pathname;
  }, [location]);
  return prevLocation.current !== location.pathname;
}

/**
 * Hook to retrieve a factory function which will render the path to a
 * page while preserving the current query string parameters
 * @returns factory
 */
function useGetPath() {
  const { search } = useLocation();

  /**
   * Factory function that renders links to a route while preserving and
   * adding any parameters desired
   * @param path The path to route to
   * @param newParams Any params you want to add/replace on the querystring
   * @returns
   */
  function getPath(path: string, newParams?: { [key: string]: string }) {
    let queryParams: URLSearchParams = new URLSearchParams(search);
    if (newParams) {
      queryParams = new URLSearchParams({
        ...Object.fromEntries(queryParams),
        ...Object.fromEntries(new URLSearchParams(newParams)),
      });
    }
    const hasParams =
      Array.from(queryParams.entries())
        .map(([, value]) => Boolean(value))
        .filter((x) => x).length > 0;
    return `${path}${hasParams ? `?${queryParams}` : ""}`;
  }

  return getPath;
}

function useGetImageLocation(image: Result | null) {
  return useMemo(() => {
    let label = null;
    let addr = null;
    if (image) {
      const linkMatches = (image.documentFileName || "").match(
        /href {0,1}=\\{0,1}"(https:\/\/.*)" .*>(.*)<\/a>/
      );
      if (linkMatches && linkMatches.length === 3) {
        addr = linkMatches[1];
        label = linkMatches[2];
      }
    }
    const parentAddr = (() => {
      let parent = null;
      if (addr) {
        // const path = new URL(addr).pathname.split("/");
        const path = addr.split("/");
        path.pop();
        parent = path.join("/");
      }
      return parent;
    })();
    return { label, addr, parentAddr };
  }, [image]);
}

export {
  useChangeQueryString,
  useRecoilPreviousValue,
  useRecoilValueOrNull,
  useRecoilValueOr,
  useRecoilValueChanged,
  useChangedPage,
  useGetPath,
  useGetImageLocation,
};
