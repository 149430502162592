import { selectorFamily } from "recoil";
import { AxiosResponse } from "axios";

import Query from "state/Query";
import { useAPI } from "services/network";
import { Request, Response } from "../all/AllSearch";

export const endpoint = "/api/v1/SearchApi/allSearch";

/**
 * Fetches a persons document result from the backend
 */
export default selectorFamily({
  key: "ExpertisePersonDocuments",
  get:
    (sipAddress: string) =>
    async ({ get }) => {
      const query = get(Query);
      const requestBody: Request = {
        page: 0,
        queryText: `people:"${sipAddress}" ${query}`,
        scope: "Expertise",
        source: "SPO",
      };
      return (
        await useAPI.post<Request, AxiosResponse<Response>>(
          endpoint,
          requestBody
        )
      ).data[0];
    },
});
