import { atomFamily, SerializableParam } from "recoil";

/**
 * Stores the selected sharepoint refiners
 */
export default atomFamily<SelectedSPRefiner[] | undefined, SerializableParam>({
  key: "SharePointSelectedRefiners",
  default: undefined,
});

export type SelectedSPRefiner = {
  name: string, 
  value: string,
  token: string
}