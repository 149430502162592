import { useRef, useEffect, useState } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import ExpertiseSearchPage from "state/search/expertise/ExpertiseSearchPage";
import ExpertiseSearch from "state/search/expertise/ExpertiseSearch";

import LoadingDisplay from "./LoadingDisplay";
import LoadMoreButton from "./LoadMoreButton";
import ExpertiseLayout from "state/search/expertise/ExpertiseLayout";

function LoadMore() {
  const currentPageLoadable = useRecoilValueLoadable(
    ExpertiseSearch(useRecoilValue(ExpertiseSearchPage))
  );

  const [layoutToggling, setLayoutToggling] = useState(false);
  const layout = useRecoilValue(ExpertiseLayout);
  const oldLayout = useRef(layout);
  useEffect(() => {
    if (oldLayout.current !== layout) {
      oldLayout.current = layout;
      setLayoutToggling(true);
    }
  }, [layout]);

  const togglingTimeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (layoutToggling) {
      togglingTimeout.current && clearTimeout(togglingTimeout.current);
      togglingTimeout.current = setTimeout(() => {
        setLayoutToggling(false);
      }, 100);
    }
  }, [layoutToggling]);

  if (layoutToggling) {
    return null;
  }

  return currentPageLoadable.state === "hasValue" ? (
    <LoadMoreButton />
  ) : (
    <LoadingDisplay />
  );
}

export default LoadMore;
