import { useTranslation } from "react-i18next";
import { useRecoilValueOrNull } from "services/hooks";
import {
  serverSeachRequest,
  SourceEntry,
  endpoint,
  ISearch
} from "state/search/sharedDrives/SharedDrives";

import ExportCSV from "components/PageControls/ExportCSV";
import SharedDrivesServerQuery from "state/search/sharedDrives/SharedDrivesServerQuery";

const allowedExportDataTypes = ["string", "number"];
const fileContentColumn = "file-content";

export default function Export() {
  const { t } = useTranslation();
  const serverQuery: ISearch = {...useRecoilValueOrNull(SharedDrivesServerQuery)} as ISearch;
  async function fetchData(page: number) {
    let exportData: Array<SourceEntry> = [];
    let totalRows: number = 0;

    if (endpoint && serverQuery) {
      serverQuery.pageNumber = page;
      serverQuery.pageSize = 100;
      const { data } = await serverSeachRequest(
        endpoint,
        serverQuery
      );
      totalRows = data.hits.total.value;
      exportData = data.hits.hits.map((hit) => {
        const data: any = hit._source;
        delete data[fileContentColumn];
        return Object.keys(data).reduce((acc, key) => {
          if (allowedExportDataTypes.includes(typeof data[key])) {
            acc[key] = data[key];
          }
          return acc;
        }, {} as any);
      });
    }
    return {
      results: exportData,
      totalRows: totalRows
    };
  }

  return (
    <ExportCSV
      fetchData={fetchData}
      label={t("Export to Excel")}
      disabled={serverQuery === null || endpoint === null}
    />
  );
}
