import styled from "styled-components/macro";
import { scrollBarSize } from "App/GlobalStyle";

export default styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: ${`calc(100% - ${scrollBarSize}px)`};
  z-index: 1;
`;
