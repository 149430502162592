import { Typography } from "@material-ui/core";
import { ReactNode } from "react";
import styled from "styled-components/macro";

const fontSize = "12px";

const Label = styled(Typography)`
  font-weight: bold;
  font-size: ${fontSize};
`;

function Property({ label, children }: { label: string; children: ReactNode }) {
  return (
    <>
      <Label>{label}</Label>
      <div>{children}</div>
    </>
  );
}

export default Property;
