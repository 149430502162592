import { Response } from "state/search/expertise/ExpertisePersonProjects";

const projectDetails: Response = [
  {
    UserAlias: "USDHFI",
    ProjectName: "Ipanema_Ops_Respud_mvg - CAMVAN - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_exp/pg_wr_315/ndi/ipanema/faults/Ipanema_Miocene_Faults_2016.flt",
    DataType: "Fault",
    CreatedDate: "2018-04-13T13:04:41.570246",
    ModifiedDate: "2018-04-13T13:04:42.225209",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "Ipanema_Ops_Respud_mvg - CAMVAN - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_exp/pg_wr_315/ndi/ipanema/faults/Ipanema_Wilcox_Faults_2016.flt",
    DataType: "Fault",
    CreatedDate: "2018-04-13T13:04:41.570246",
    ModifiedDate: "2018-04-13T13:04:42.225209",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "Ipanema_Ops_Respud_mvg - CAMVAN - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_exp/pg_wr_315/ndi/ipanema/events/camvan/eoct_ipanema_ops_respud_final_mvg_sept2017.int",
    DataType: "3Dhorizon",
    CreatedDate: "2018-04-13T13:04:41.570246",
    ModifiedDate: "2018-04-13T13:04:42.225209",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "Ipanema_Ops_Respud_mvg - CAMVAN - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_exp/pg_wr_315/ndi/ipanema/events/camvan/eoct_ipanema_postdrill_working_jan2018.int",
    DataType: "3Dhorizon",
    CreatedDate: "2018-04-13T13:04:41.570246",
    ModifiedDate: "2018-04-13T13:04:42.225209",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "Ipanema_Ops_Respud_mvg - CAMVAN - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_exp/pg_wr_315/ndi/ipanema/polygons/Ipanema_PLY_XORP4.ply",
    DataType: "Polygon",
    CreatedDate: "2018-04-13T13:04:41.570246",
    ModifiedDate: "2018-04-13T13:04:42.225209",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "Ipanema_Ops_Respud_mvg - CAMVAN - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_exp/pg_wr_315/ndi/ipanema/polygons/camvan/event_edit_mvg_dec2017.ply",
    DataType: "Polygon",
    CreatedDate: "2018-04-13T13:04:41.570246",
    ModifiedDate: "2018-04-13T13:04:42.225209",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "Ipanema_Ops_Respud_mvg - CAMVAN - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_exp/pg_wr_315/ndi/ipanema/faults/camvan/ipanema_ops_mvg_sep2017.flt",
    DataType: "Fault",
    CreatedDate: "2018-04-13T13:04:41.570246",
    ModifiedDate: "2018-04-13T13:04:42.225209",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "Ipanema_Ops_Respud_mvg - CAMVAN - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_exp/pg_wr_315/ndi/ipanema/events/AAR/Ipanema_AAR_PREP_migdecon_01092018.int",
    DataType: "3Dhorizon",
    CreatedDate: "2018-04-13T13:04:41.570246",
    ModifiedDate: "2018-04-13T13:04:42.225209",
  },
];

export default projectDetails;
