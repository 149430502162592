import styled from "styled-components/macro";

export default styled.div<{ gap?: number }>`
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  ${({ gap }) => {
    if (gap) {
      return `gap: ${gap}px;`;
    }
    return "gap: 0";
  }}
  align-self: stretch;
`;
