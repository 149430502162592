import styled from "styled-components/macro";

const padding = 1;

export default styled.div`
  grid-area: path-value;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas:
    "value"
    "path";
  grid-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  padding: ${({ theme }) =>
    `${theme.spacing(padding)}px 0 ${theme.spacing(padding)}px ${theme.spacing(
      padding
    )}px`};
  place-items: center start;
`;
