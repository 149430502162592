import { Response } from "state/search/sharedDrives/SharedDriveGroups";

const data: Response = {
  found: true,
  _source: {
    groups: ["ta.data.prd.bsee", "ta.data.tst.test_crawl"],
  },
};

export default data;
