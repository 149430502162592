import { selector } from "recoil";
import ExpertiseCurrentProject from "./ExpertiseCurrentProject";
import ExpertiseProjectPosition from "./ExpertiseProjectPosition";

/**
 * The scroll position for the projects carousel on the expertise page
 */
export default selector<number>({
  key: "ExpertiseProjectScrollPosition",
  get: ({ get }) => get(ExpertiseProjectPosition(get(ExpertiseCurrentProject))),
});
