import { atom } from "recoil";
import { Node } from "state/treeTypes";

/**
 * Keeps track of the current selected shared drives
 */
export default atom<Set<Node>>({
  key: "SelectedSharedDrives",
  default: new Set<Node>(),
});
