import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ColumnIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20,4H4C2.9,4,2,4.9,2,6l0,12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M15,18H4v-5h11V18z M15,13H4V5.8
	h11V13z M20,18h-4V5.8h4V18z"
      />
    </SvgIcon>
  );
}
