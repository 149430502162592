import { useState, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useKey } from "react-use";

import { Project } from "state/search/expertise/ExpertisePersonProjects";
import ErrorDisplay from "components/ErrorDisplay";

import Collapse from "./Collapse";
import LoadingDisplay from "./LoadingDisplay";
import Container from "./Container";
import Content from "./Content";
import ProjectDetails from "./ProjectDetails";
import ShowMore from "./ShowMore";
import MoreInfo from "./MoreInfo";

export default function ProjectDisplay({
  project,
  userAlias,
}: {
  project: Project;
  userAlias: string;
}) {
  const [wasOpened, setWasOpened] = useState(false);
  const [open, setIsOpen] = useState(false);
  function toggleMore() {
    setIsOpen(!open);
    setWasOpened(true);
  }
  useKey("Escape", () => setIsOpen(false));
  return (
    <Container open={open}>
      <Content onClick={toggleMore}>
        <ProjectDetails project={project} />
        <ShowMore open={open} />
      </Content>
      <Collapse in={open}>
        {wasOpened ? (
          <Suspense fallback={<LoadingDisplay />}>
            <ErrorBoundary FallbackComponent={ErrorDisplay}>
              <MoreInfo userAlias={userAlias} project={project} />
            </ErrorBoundary>
          </Suspense>
        ) : (
          <LoadingDisplay />
        )}
      </Collapse>
    </Container>
  );
}
