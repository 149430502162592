import { ButtonBase } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(ButtonBase)`
  display: grid;
  place-items: center;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(0.5)}px 0`};
  cursor: pointer;
  border-radius: ${({ theme }) =>
    `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`};
  border-left: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  padding: ${({ theme }) => `0 ${theme.spacing(1)}px`};
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  &:active {
    background: rgba(0, 0, 0, 0.075);
  }
`;
