import DropDown from "components/DropDown";
import styled from "styled-components";

const StyledDropDown = styled(DropDown)`
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 4px;
  padding: 0 4px 0px 8px;
  z-index: 2;
`;

export default StyledDropDown;
