import styled from "styled-components/macro";

export default styled.div`
  grid-area: details;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  place-items: center start;
`;
