import { Typography } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(Typography).attrs({ component: "div" })`
  margin-top: ${({ theme }) => `${theme.spacing(0.5)}px`};
  font-size: 12px;

  em {
    font-weight: bold;
  }
`;
