import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { Redirect } from "react-router";

import WasLoggedIn from "state/WasLoggedIn";
import { useIsLoggedIn } from "services/auth";

import PageContainer from "components/PageContainer";
import LoadingDisplay from "components/LoadingDisplay";

// The page path relative to the application root
const path = "/auth/callback";

function AuthCallbackPage() {
  const isLoggedIn = useIsLoggedIn();
  const loginPage = localStorage.getItem("login-page") || "";
  const setWasLoggedIn = useSetRecoilState(WasLoggedIn);

  useEffect(() => {
    setWasLoggedIn(true);
  }, [isLoggedIn, setWasLoggedIn]);

  return isLoggedIn ? (
    <Redirect to={loginPage} />
  ) : (
    <PageContainer>
      <LoadingDisplay style={{ minHeight: "200px" }} />
    </PageContainer>
  );
}

export { path };
export default AuthCallbackPage;
