import { useRecoilPreviousValue } from "services/hooks";
import ExpertiseSearch, {
  useSearchSource,
  Response,
} from "state/search/expertise/ExpertiseSearch";
import ResultsCountControl from "components/PageControls/ResultsCount";

function ResultsCount() {
  const expertiseSearch = useRecoilPreviousValue<Response, Response>(
    ExpertiseSearch(0),
    []
  );
  const spoResult = useSearchSource(expertiseSearch, "SPOExpertise");
  const numResults = spoResult?.totalRows || 0;
  return <ResultsCountControl count={numResults} />;
}

export default ResultsCount;
