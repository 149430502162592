import styled from "styled-components";

export default styled.label`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  place-items: center;
  cursor: pointer;
  user-select: none;
`;
