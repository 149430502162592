import styled from "styled-components/macro";

export default styled.div`
  position: relative;
  grid-area: body;
  height: 100%;
  display: grid;
  overflow-y: scroll;
  overflow-x: hidden;
`;
