import user1 from "./user1";
import user2 from "./user2";
import user3 from "./user3";
import user4 from "./user4";
import user5 from "./user5";
import user6 from "./user6";
import user7 from "./user7";
import user8 from "./user8";
import user9 from "./user9";

const userDocuments = [
  user1,
  user2,
  user3,
  user4,
  user5,
  user6,
  user7,
  user8,
  user9,
];

export default userDocuments;
