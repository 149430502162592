import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { getSitePaths, SourceNode } from "state/sources/SourceTree";
import SiteSearch from "state/sources/SiteSearch";

import AllIcon from "./AllIcon";
import SomeIcon from "./SomeIcon";
import NoneIcon from "./NoneIcon";

type Access = "all" | "some" | "none";

function SiteAccess({ node }: { node: SourceNode }) {
  const siteSearch = useRecoilValue(SiteSearch);
  const isSite = !node.libraryUrl;

  const access: Access = useMemo(() => {
    if (siteSearch) {
      const childSites = getSitePaths([node]);

      // check for all children
      const all = childSites.reduce(
        (previous, site) => previous && siteSearch.includes(site.toUpperCase()),
        true
      );
      if (all) return "all";

      // check for some children
      const some = childSites.reduce(
        (previous, site) => previous || siteSearch.includes(site.toUpperCase()),
        false
      );
      if (some) return "some";
    }
    return "none";
  }, [siteSearch, node]);

  const showIcon = useMemo(
    () => Boolean(isSite && siteSearch && node.level !== "1"),
    [isSite, siteSearch, node]
  );

  if (showIcon) {
    return {
      all: <AllIcon />,
      some: <SomeIcon />,
      none: <NoneIcon node={node} />,
    }[access];
  }
  return null;
}

export default SiteAccess;
