import { HTMLProps } from "react";
import { useTranslation } from "react-i18next";

import Container from "./Container";
import NumberAnimation from "./NumberAnimation";
import Icon from "./Icon";
import Label from "./Label";

interface ResultsCounteInterface extends HTMLProps<HTMLDivElement> {
  count: number;
  style?: any;
}

function ResultsCount({ count, style }: ResultsCounteInterface) {
  const { t } = useTranslation();
  return (
    <Container style={style}>
      <Icon /> <Label>{t("Results")}:</Label> <NumberAnimation to={count} />
    </Container>
  );
}

export default ResultsCount;
