import { useEffect, useState, useRef, ChangeEvent, useMemo } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { animated, config, useSpring } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";

import { mailToAddressChecker, wrapWords } from "services/helpers";
import { useGetImageLocation } from "services/hooks";
import SelectedImage from "state/SelectedImage";
import { Result } from "state/search/all/AllSearch";
import Query from "state/Query";

import LoadingDisplay from "components/LoadingDisplay";
import Container from "./Container";
import ImageEl from "./Image";
import MetaContainer from "./MetaContainer";
import Meta from "./Meta";
import DialogTitle from "./DialogTitle";
import Title from "./Title";
import ErrorDisplay from "./ErrorDisplay";
import LoadingPlaceholder from "./LoadingPlaceholder";
import ImageBlock from "./ImageBlock";
import Label from "./Label";
import SlideText from "./SlideText";
import MailButton from "./MailButton";
import { MAIL_BUTTON_CONSTANTS } from "services/constants";

const Dialog = animated(MuiDialog);

function ImageDialog({ images }: { images: Array<Result> }) {
  const { t } = useTranslation();
  const image = useRecoilValue(SelectedImage);
  const query = useRecoilValue(Query);
  const { addr, label } = useGetImageLocation(image);
  const setSelectedImage = useSetRecoilState(SelectedImage);
  const resetSelectedImage = useResetRecoilState(SelectedImage);
  const spring = useSpring({
    from: { opacity: 0, transform: "scale(0.6)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: config.gentle,
  });
  const [mailToAddrDialog, setMailToAddrDialog] = useState("");
  const [mailToOCRText, setMailToOCRText] = useState<string | null>(null);

  const isOpen = Boolean(image);
  function handleClose() {
    resetSelectedImage();
  }

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const oldImage = useRef(image);
  useEffect(() => {
    // Clear the error when a new image is loaded
    if (oldImage.current !== image) {
      oldImage.current = image;
      setError(false);
    }
  }, [image]);

  useEffect(() => {
    if (image && image.pictureURL) {
      const newImg = new Image();
      newImg.onload = () => {
        setLoaded(true);
      };
      newImg.onerror = () => setError(true);
      newImg.src = image.pictureURL;
    }
  }, [image]);

  const currentImage = useMemo(() => {
    if (image) {
      return images.indexOf(image);
    }
    return null;
  }, [images, image]);

  if (image?.ocrText) {
    console.info("wrapWords", wrapWords(image.ocrText, "Basin"));
  }
  //@ts-ignore
  window.image = image;

  function handleChange(event: ChangeEvent<unknown>, image: number) {
    setLoaded(false);
    setError(false);
    setSelectedImage(images[image - 1]);
  }

  const mailToSubject = `Image submitted for Georeferencing from USE: ${image?.imageName}`;
  useEffect(() => {
    if (addr && image) {
      setMailToAddrDialog(mailToAddressChecker(addr, image.SiteName));
    }
  }, [addr, image]);

  const mailToBody = `Thanks for submitting this image for georeferencing, to support you efficiently please provide the following information.

Project Name: [enter project name]
Product Type: [play map, charge map, trap map, prospect map]
Coordinate Reference System: [enter coordinate reference system details]
Sensitivity: [Internal or Confidential]

"Note: if image is not a map or there are no coordinates on the image, TAO Analyst will decline the request."


---- DO NOT EDIT BELOW ---- Autogenerated Details ---- DO NOT EDIT BELOW ----
${mailToAddrDialog}

Link to the extracted image: ${image?.pictureURL}
`;

  const mailToOCREmailText = `
Extracted Text (including OCR): ${mailToOCRText}
`;

  const trimmedOCRText = mailToOCREmailText.substring(0, 500);

  useEffect(() => {
    if (image?.ocrText) {
      setMailToOCRText(image.ocrText);
    }
  }, [image?.ocrText]);

  const mailToString = `mailto:${
    MAIL_BUTTON_CONSTANTS.mailToReceiver
  }?subject=${mailToSubject}&body=${encodeURIComponent(
    mailToBody +
      (image?.ocrText ? trimmedOCRText : "") +
      (trimmedOCRText !== mailToOCREmailText ? "..." : "")
  )}`;

  const handleMailClick = () => {
    window.location.href = mailToString;
  };

  return image ? (
    <Dialog
      onClose={handleClose}
      aria-labelledby="image-dialog-title"
      open={isOpen}
      maxWidth={false}
      style={spring}
    >
      <DialogTitle id="image-dialog-title">
        <Title>{image.captionText || image.imageName}</Title>
        <Pagination
          count={images.length}
          page={currentImage !== null ? currentImage + 1 : 1}
          onChange={handleChange}
        />
        {loaded && (
          <MailButton onClick={handleMailClick}>
            {MAIL_BUTTON_CONSTANTS.text}
          </MailButton>
        )}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Container>
        <ImageBlock>
          <LoadingDisplay style={{ gridArea: "image" }} />
          <LoadingPlaceholder
            size={{ width: image.pictureWidth, height: image.pictureHeight }}
          />
          {loaded ? (
            <ImageEl
              src={image.pictureURL}
              title={image.captionText || image.imageName}
            />
          ) : error ? (
            <ErrorDisplay>{t("Error loading")}</ErrorDisplay>
          ) : null}
        </ImageBlock>
        <MetaContainer>
          <Meta>
            <Label>{t("Site collection")}</Label>
            <Typography
              component="a"
              href={image.siteUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {image.siteTitle}
            </Typography>

            {/* <Label>{t("Folder")}</Label>
            <Typography
              component="a"
              href={parentAddr || ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {image.parentFileName}
            </Typography> */}

            <Label>{t("Document")}</Label>
            <Typography
              component="a"
              href={addr || ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {label}
            </Typography>

            <Label>{t("Image")}</Label>
            <Typography
              component="a"
              href={image.pictureURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {image.imageName}
            </Typography>

            {image.captionText && (
              <>
                <Label>{t("Caption text")}</Label>
                <Typography>{image.captionText}</Typography>
              </>
            )}

            <Label>{t("Dimensions")}</Label>
            <Typography>
              {image.pictureWidth}px x {image.pictureHeight}px
            </Typography>

            {typeof image.slideNumber !== "undefined" && (
              <>
                <Label>{t("Slide/Page number")}</Label>
                <Typography>{image.slideNumber}</Typography>
              </>
            )}

            {image.slideText && (
              <>
                <Label>{t("Slide text")}</Label>
                <SlideText>{image.slideText}</SlideText>
              </>
            )}

            {image.ocrText && (
              <>
                <Label>{t("OCR Text")}</Label>
                <SlideText
                  dangerouslySetInnerHTML={{
                    __html: wrapWords(image.ocrText, query),
                  }}
                />
              </>
            )}
          </Meta>
        </MetaContainer>
      </Container>
    </Dialog>
  ) : null;
}

export default ImageDialog;
