import { useRecoilState } from "recoil";

import FileType from "state/FileType";
import { spoGroup, useFileTypeEntries } from "state/search/image/ImageSearch";

import FileTypeControl from "components/PageControls/FileType";

function FileTypeEl() {
  const fileTypeState = useRecoilState(FileType(spoGroup));
  const entries = useFileTypeEntries();
  return <FileTypeControl entries={entries} fileTypeState={fileTypeState} />;
}

export default FileTypeEl;
