import { animated } from "@react-spring/web";
import { themeBorder } from "services/helpers";
import styled from "styled-components/macro";

export default animated(styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "path-value dates";
  grid-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  place-items: center start;
  background: rgba(0, 0, 0, 0.025);
  ${themeBorder}
`);
