import { Response } from "state/search/expertise/ExpertisePersonProjects";

const projectDetails: Response = [
  {
    UserAlias: "USDHFI",
    ProjectName: "Caesar_Tonga_20180226 - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_dev_nov/caesar_tonga/ndi/irm_m17a_2016/faults/Caesar_Tonga_FINAL_2016.flt",
    DataType: "Fault",
    CreatedDate: "2018-05-07T14:09:24.215319",
    ModifiedDate: "2018-05-07T14:09:24.539215",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "Caesar_Tonga_20180226 - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_dev_nov/caesar_tonga/ndi/irm_m17a_2016/events/Final_2016.int",
    DataType: "3Dhorizon",
    CreatedDate: "2018-05-07T14:09:24.215319",
    ModifiedDate: "2018-05-07T14:09:24.539215",
  },
  {
    UserAlias: "USDHFI",
    ProjectName: "Caesar_Tonga_20180226 - USCCIN",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/gom_dev_nov/caesar_tonga/ndi/irm_m17a_2016/polygons/Caesar_Tonga_FINAL_2016.ply",
    DataType: "Polygon",
    CreatedDate: "2018-05-07T14:09:24.215319",
    ModifiedDate: "2018-05-07T14:09:24.539215",
  },
];

export default projectDetails;
