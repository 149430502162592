import { atom } from "recoil";
import { SelectionOption } from "components/PageControls/SelectionDropdown";

export interface ExpertiseBusiness extends SelectionOption {}

/**
 * The business option selection for the expertise page
 */
export default atom<ExpertiseBusiness | undefined>({
  key: "ExpertiseBusiness",
  default: undefined,
});
