import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";

import env from "services/env";
import { stripAddress } from "services/helpers";
import User from "state/auth/User";
import RedirectCode from "state/auth/RedirectCode";
import TestLoginUser from "state/auth/TestLoginUser";
import { path as AuthCallback } from "pages/AuthCallbackPage";

/**
 * The scope requested during authentication as well as when the resulting
 * token is retrieved.
 */
const scope = "api://68fab6ab-a186-4c29-b11a-a71930792d02/USE.API.Read";

/**
 * PKCE authorization logic originally based on/adapted from:
 * https://github.com/aaronpk/pkce-vanilla-js/blob/master/index.html
 * https://auth0.com/docs/flows/add-login-using-the-authorization-code-flow-with-pkce
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow
 */

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain: string) {
  const encoder = new TextEncoder();
  return window.crypto.subtle.digest("SHA-256", encoder.encode(plain));
}

// Base64-urlencodes the input string
function base64urlencode(str: ArrayBuffer) {
  // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(str))))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// Generate a secure random string using the browser crypto functions
function generateRandomString() {
  var array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
    ""
  );
}

// Generate, save and return a base64-urlencoded sha256 hash for the PKCE challenge
const pkceCodeVerifierKey = "sso_pkce_code_verifier";
async function pkceChallenge() {
  const code = generateRandomString();
  localStorage.setItem(pkceCodeVerifierKey, code);
  return base64urlencode(await sha256(code));
}

// Generate, save and return PKCE state for verification
function pkceState() {
  const state = generateRandomString();
  localStorage.setItem("sso_pkce_state", state);
  return state;
}

// Construct the redirect URI based on the path
const { protocol, host } = window.location;
const appURI = `${protocol}//${host}`;
const redirectURI = `${appURI}${AuthCallback}`;

const ssoHost =
  env.REACT_APP_SSO_HOST || stripAddress("login.microsoftonline.com");
const tenant = env.REACT_APP_TENANT || "db1e96a8-a3da-442a-930b-235cac24cd5c";
const tokenUrl = `${ssoHost}/${tenant}/oauth2/v2.0/token`;
const authUrl = `${ssoHost}/${tenant}/oauth2/v2.0/authorize`;
const logoutUrl = `${ssoHost}/${tenant}/oauth2/v2.0/logout`;

/**
 * Hook which returns boolean indicating whether or not the user is logging in
 */
function useIsLoggingIn() {
  const user = useRecoilValueLoadable(User);
  const redirectCode = useRecoilValue(RedirectCode);
  return user.state === "loading" || !redirectCode;
}

/**
 * Hook which returns boolean indicating whether or not the user is logged in
 */
function useIsLoggedIn() {
  const user = useRecoilValueLoadable(User);
  const redirectCode = useRecoilValue(RedirectCode);
  return user.state === "hasValue" && redirectCode !== null;
}

/**
 * Hook which returns boolean indicating whether or not the user had a login error
 */
function useHasLoginError() {
  const user = useRecoilValueLoadable(User);
  return user.state === "hasError";
}

/**
 * Hook that returns a function the caller can use to logout the user
 */
function useLogoutUser() {
  const setRedirectCode = useSetRecoilState(RedirectCode);
  const setTestUser = useSetRecoilState(TestLoginUser);
  return () => {
    localStorage.removeItem("token");
    setRedirectCode(null);
    setTestUser(false);
    window.location.replace(`${logoutUrl}?post_logout_redirect_uri=${appURI}`);
  };
}

/**
 * Generate the authentication url used to login the user
 * @returns The auth url
 */
async function generateAuthUrl() {
  const params = new URLSearchParams({
    client_id: env.REACT_APP_AAD_APP_CLIENTID,
    response_type: "code",
    redirect_uri: redirectURI,
    response_mode: "query",
    scope,
    state: pkceState(),
    code_challenge: await pkceChallenge(),
    code_challenge_method: "S256",
  }).toString();
  return `${authUrl}?${params}`;
}

// Get the current PKCE state to verify from localstorage
function getPKCEState() {
  return localStorage.getItem("sso_pkce_state");
}

// Get the current PKCE code verifier value from localstorage
function getPKCECodeVerifier() {
  return localStorage.getItem(pkceCodeVerifierKey);
}

export {
  useIsLoggingIn,
  useIsLoggedIn,
  useHasLoginError,
  useLogoutUser,
  generateAuthUrl,
  getPKCEState,
  getPKCECodeVerifier,
  tokenUrl,
  redirectURI,
  scope,
};
