import { useRecoilState } from "recoil";

import FileType from "state/FileType";
import {
  spoGroup,
  useFileTypeEntries,
} from "state/search/sharedDrives/SharedDrives";

import FileTypeControl from "components/PageControls/FileType";

function FileTypeEl() {
  const fileTypeState = useRecoilState(FileType(spoGroup));
  const entries = useFileTypeEntries();
  entries?.sort((a,b)=> a.RefinementName.localeCompare(b.RefinementName));
  return <FileTypeControl entries={entries} fileTypeState={fileTypeState} />;
}

export default FileTypeEl;
