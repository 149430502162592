import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";
import { config, useSpring } from "@react-spring/web";
import { useTheme } from "@material-ui/core";
import LabelIcon from "@material-ui/icons/Label";

import { useRecoilValueOr } from "services/hooks";
import {
  Layout,
  useSwitchedLayout,
} from "state/search/expertise/ExpertiseLayout";
import ExpertiseSearch, {
  useSearchSource,
} from "state/search/expertise/ExpertiseSearch";
import ExpertiseProjectScrollPosition from "state/search/expertise/ExpertiseProjectScrollPosition";
import ExpertiseProjectSearch from "state/search/expertise/ExpertiseProjectSearch";
import Query from "state/Query";

import Carousel from "./Carousel";
import Container from "./Container";
import Projects from "./Projects";
import ProjectCard from "./ProjectCard";
import NavButton from "./NavButton";
import Title from "./Title";
import LoadingCard from "./LoadingCard";
import NoResults from "./NoResults";

const buttonWidth = 60;

export default function TopProjects({ layout }: { layout: Layout }) {
  const { t } = useTranslation();
  const switchedLayout = useSwitchedLayout();
  const query = useRecoilValue(Query);
  const projectSearch = useRecoilValue(ExpertiseProjectSearch);
  const showNav = layout === "row";
  const theme = useTheme();
  const expertiseLoader = useRecoilValueLoadable(ExpertiseSearch(0));
  const expertiseSearch = useRecoilValueOr(ExpertiseSearch(0), []);
  const projectScrollPosition = useRecoilValue(ExpertiseProjectScrollPosition);
  const projectResults = useSearchSource(expertiseSearch, "ExpertiseProjects");
  const projects = useMemo(
    () => projectResults?.results || [],
    [projectResults]
  );

  const [container, setContainer] = useState<HTMLDivElement>();
  const containerCallback = useCallback((container: HTMLDivElement) => {
    setContainer(container);
  }, []);

  useEffect(() => {
    if (container) {
      container.scroll({
        left: projectScrollPosition - buttonWidth - theme.spacing(2),
        behavior: "smooth",
      });
    }
  }, [container, projectScrollPosition, theme]);

  const oldProjects = useRef(projects.length);
  useEffect(() => {
    oldProjects.current = projects.length;
  }, [projects]);

  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: switchedLayout,
    config: { ...config.gentle, tension: 35, clamp: true },
  });

  const projectsSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: oldProjects.current !== projects.length,
    config: { ...config.gentle, tension: 25, clamp: true },
  });

  return (
    <Carousel layout={layout} navButtonWidth={buttonWidth} style={spring}>
      <Title offset={buttonWidth} layout={layout}>
        <LabelIcon />
        {t("Top Project Results")}
      </Title>
      {showNav && <NavButton placement="left" width={buttonWidth} />}
      <Container ref={containerCallback}>
        <Projects
          layout={layout}
          navButtonWidth={buttonWidth}
          style={projectsSpring}
        >
          {expertiseLoader.state === "loading" ? (
            Array.from(Array(10)).map((x, index) => <LoadingCard key={index} />)
          ) : projects.length ? (
            projects.map((project, index) => {
              const active = project.title === query && projectSearch;
              return (
                <ProjectCard project={project} active={active} key={index} />
              );
            })
          ) : (
            <NoResults />
          )}
        </Projects>
      </Container>
      {showNav && <NavButton placement="right" width={buttonWidth} />}
    </Carousel>
  );
}
