import { useState } from "react";
import { useKey } from "react-use";
import { Collapse } from "@material-ui/core";

import { Result as IResult } from "state/search/expertise/ExpertiseSearch";

import Avatar from "./Avatar";
import Content from "./Content";
import Container from "./Container";
import Info from "./Info";
import DetailsDisplay from "./DetailsDisplay";

export default function Result({ result }: { result: Partial<IResult> }) {
  const [selectedPerson, setSelectedPerson] = useState(false);
  const active = String(selectedPerson) as "true" | "false";

  useKey("Escape", () => setSelectedPerson(false));

  function togglePerson() {
    setSelectedPerson(!selectedPerson);
  }

  return (
    <Container active={active} onClick={togglePerson}>
      <Content>
        <Avatar
          alt={result.preferredName || ""}
          src={result.pictureUrl as string}
        />
        <Info person={result} active={active} />
      </Content>
      <Collapse in={active === "true"} style={{ gridArea: "collapse" }}>
        <DetailsDisplay person={result} />
      </Collapse>
    </Container>
  );
}
