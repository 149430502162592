import { Response } from "state/search/all/AllSearch";

const documents: Response = [
  {
    source: "SPO",
    rowCount: 2,
    totalRows: 2,
    totalRowsIncludingDuplicates: 2,
    refinerResults: [
      {
        entries: [
          {
            RefinementCount: 2,
            RefinementName: "Older than a year",
            RefinementToken: "range(min, 2020-08-27T00:00:00.0000000Z)",
            RefinementValue: "Before 2020-08-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Year",
            RefinementToken:
              "range(2020-08-27T00:00:00.0000000Z, 2021-07-27T00:00:00.0000000Z)",
            RefinementValue:
              "From 2020-08-27T00:00:00.0000000Z up to 2021-07-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Month",
            RefinementToken:
              "range(2021-07-27T00:00:00.0000000Z, 2021-08-26T00:00:00.0000000Z)",
            RefinementValue:
              "From 2021-07-27T00:00:00.0000000Z up to 2021-08-26T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past 24 hours",
            RefinementToken:
              'range(2021-08-26T00:00:00.0000000Z, max, to="le")',
            RefinementValue: "2021-08-26T00:00:00.0000000Z or later",
          },
        ],
        name: "Write",
      },
      {
        entries: [
          {
            RefinementCount: 2,
            RefinementName: "docx",
            RefinementToken: '"ǂǂ646f6378"',
            RefinementValue: "docx",
          },
        ],
        name: "FileType",
      },
    ],
    results: [
      {
        docId: 17596947954492,
        dlcDocId: "AAAAA1351-724471295-71",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaaa1351",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={1cf2d351-4210-4fed-a3d9-d89c589e6f39}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author:
          "Sally.Gregerson@shell.com;Justin.Ugbo@shell.com;Jason.Robe@shell.com;Andrew.Nolan@shell.com;Nabanita.Das@shell.com;Cherie.Blyth@shell.com;I.Donaldson@shell.com;Michael.Harvey@shell.com;Gregerson, Sally T SDA-STX/L/AU",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaaa1351",
        siteTitle: "Exploration Conference",
        people:
          "Sally.Gregerson@shell.com | Gregerson, Sally T SDA-STX/L/AU | 693A30232E667C6D656D626572736869707C73616C6C792E677265676572736F6E407368656C6C2E636F6D i:0#.f|membership|sally.gregerson@shell.com;Sally.Gregerson@shell.com;Justin.Ugbo@shell.com;Jason.Robe@shell.com;Andrew.Nolan@shell.com;Nabanita.Das@shell.com;Cherie.Blyth@shell.com;I.Donaldson@shell.com;Michael.Harvey@shell.com;Gregerson, Sally T SDA-STX/L/AU",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={1cf2d351-4210-4fed-a3d9-d89c589e6f39}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.docx.svg",
        title: "Bratwurst, the largest Gas Discovery in 2019",
        path: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission/UPX_A_Z_Sally_Gregerson et al. Bratwurst Gas Discovery_Abstract.docx",
        summary:
          "of the Browse <strong>Basin</strong> and focused its efforts to <ddd/> knowledge of the Jurassic Play in the Browse <strong>Basin</strong> <ddd/> ...",
        resultType: "docx",
        size: 1213164,
        write: "2020-03-20T06:13:00.0000000Z",
        rank: 0.0,
      },
      {
        docId: 991203530756955412,
        dlcDocId: "AAFAA3273-1248411372-150177",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa3273",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA3273",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAFAA3273/_layouts/15/Doc.aspx?sourcedoc={fef44387-546e-4c86-944a-e940a34f2b04}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "Rosie Johnstone;Andrew Iwaniw;Donaldson, Ian F SDA-UPX/A/Z",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aafaa3273",
        siteTitle: "Wells",
        people:
          "I.Donaldson@shell.com | Donaldson, Ian F SDA-UPX/A/Z | 693A30232E667C6D656D626572736869707C692E646F6E616C64736F6E407368656C6C2E636F6D i:0#.f|membership|i.donaldson@shell.com;Rosie Johnstone;Andrew Iwaniw;Donaldson, Ian F SDA-UPX/A/Z",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAFAA3273/_layouts/15/Doc.aspx?sourcedoc={fef44387-546e-4c86-944a-e940a34f2b04}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA3273/WellFile Australia/Exploration/Bratwurst-1/01 Well Planning/01 Subsurface/Mudlogging Program/versions",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.docx.svg",
        title: "CONTENTS",
        path: "https://eu001-sp.shell.com/sites/AAFAA3273/WellFile Australia/Exploration/Bratwurst-1/01 Well Planning/01 Subsurface/Mudlogging Program/versions/Bratwurst-1_Mudlogging_Program_IDeditsoffline.docx",
        summary:
          "XXXXXXX  Well Completion Report – Basic Data <ddd/> in the remote Browse <strong>Basin</strong> area (permit AC/P64 <ddd/> ...",
        resultType: "docx",
        size: 32057867,
        write: "2019-08-07T01:48:00.0000000Z",
        rank: 0.0,
      },
    ],
    spellingSuggestion: "",
  },
];

export default documents;
