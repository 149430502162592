import { ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components/macro";
import chroma from "chroma-js";
import { columnSize } from "../Projects";

interface ContainerInterface {
  active: "true" | "false";
  ref: any;
}

const light = css<ContainerInterface>`
  border: ${({ theme, active }) =>
    `1px solid ${
      active === "true"
        ? theme.palette.primary.main
        : chroma
            .mix(
              theme.palette.background.paper,
              theme.palette.text.primary,
              0.4
            )
            .css()
    }`};
`;

const dark = css<ContainerInterface>`
  border: ${({ theme, active }) =>
    `1px solid ${
      active === "true"
        ? theme.palette.primary.main
        : chroma(theme.palette.background.paper).darken(0.5).css()
    }`};
  &:hover {
    border-color: ${({ theme, active }) =>
      active === "true"
        ? theme.palette.primary.main
        : chroma(theme.palette.background.paper).brighten().css()};
  }
`;

const hoverStyles = css`
  z-index: 1;
  transition: all 0.2s ease;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  transform: scale(1.05);
`;

export default styled(ButtonBase)<ContainerInterface>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  transform-origin: 50% 50%;
  transition: all 0.1s ease;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  background: ${({ theme }) => theme.palette.background.paper};
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  grid-row-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  min-width: ${`${columnSize}px`};
  cursor: pointer;
  text-align: left;
  justify-content: left;

  ${({ theme }) => (theme.palette.type === "light" ? light : dark)}

  ${({ active }) => active === "true" && hoverStyles}

  &:hover {
    ${hoverStyles}
  }
`;
