import { animated } from "@react-spring/web";
import styled, { css } from "styled-components/macro";

const hidden = css`
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
`;
const visible = css`
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
`;

export default animated(styled.div<{
  align: "left" | "right";
  open: boolean;
  onClick: (event: MouseEvent) => void;
}>`
  position: absolute;
  top: 100%;
  left: ${({ align }) => (align === "left" ? 0 : "auto")};
  right: ${({ align }) => (align === "right" ? 0 : "auto")};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  place-items: center start;
  margin-top: 10px;
  background: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  min-width: 100%;
  cursor: auto;
  transition: all 0.2s ease;
  ${({ open }) => (open ? visible : hidden)}
`);
