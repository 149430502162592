import { atom } from "recoil";

// Stores the SharePoint Site URL, Name, and boolean if it is passed
// in as a redirected URL from SharePoint and stores it as a refiner
export interface IRedirectState {
  redirected: boolean;
  SPSiteUrl: string;
  SPSiteName: string;
}

const RedirectState = atom<IRedirectState>({
  key: "SPSiteUrl",
  default: {
    redirected: false,
    SPSiteUrl: "",
    SPSiteName: "",
  },
});

export default RedirectState;
