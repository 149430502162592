import { rest } from "msw";

import { useUrl } from "services/network";
import { apiDelay, apiStatus, apiResponse } from "services/testing";
import { Request, Response, endpoint } from "state/sources/SiteSearch";

import siteSearch from "../data/siteSearch";

export default rest.post<Request, Response>(
  `${useUrl}${endpoint}`,
  function (req, res, ctx) {
    return res(
      ctx.delay(apiDelay(endpoint, 8000)),
      ctx.status(apiStatus(endpoint, 200)),
      ctx.json(apiResponse(endpoint, siteSearch))
    );
  }
);
