import chroma from "chroma-js";
import Pill from "pages/ExpertisePage/SearchResults/Results/ResultsPage/Result/Pill";
import styled, { css } from "styled-components/macro";

const styles = {
  ready: css``,
  error: css`
    background: ${({ theme }) =>
      chroma(theme.palette.error.main).alpha(0.1).css()};
    &:hover {
      border-color: ${({ theme }) => theme.palette.error.main};
    }
  `,
  copied: css`
    background: ${({ theme }) =>
      chroma(theme.palette.success.main).alpha(0.1).css()};
    &:hover {
      border-color: ${({ theme }) => theme.palette.success.main};
    }
  `,
};

export default styled(Pill)<{ state: "ready" | "error" | "copied" }>`
  grid-area: path;
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  font-size: 12px;
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  place-items: center start;
  ${({ state }) => styles[state]}
`;
