import { Container } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(Container)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${({ theme }) => `${theme.spacing(3)}px`};
  position: relative;
  z-index: 20;
  grid-template-areas: "logo search user";
`;
