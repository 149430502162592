import { Avatar } from "@material-ui/core";
import styled from "styled-components";

const size = 100;

export default styled(Avatar)`
  width: ${size}px;
  height: ${size}px;
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  grid-area: user;
`;
