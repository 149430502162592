import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import PageContainer from "components/PageContainer";

/**
 * Renders the not found page (404)
 */
function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {t("Not Found")} - {t("APP_NAME")}
        </title>
      </Helmet>
      <PageContainer>Page not found content</PageContainer>
    </>
  );
}

export default NotFoundPage;
