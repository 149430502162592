import { setupWorker } from "msw";
import { SetupWorkerApi } from "msw";
import { testFlag } from "services/testing";
import { isForcingMock } from "services/env";

import siteSearch from "./endpoints/siteSearch";
import allSearch from "./endpoints/allSearch";
import imageSearch from "./endpoints/imageSearch";
import externalSearch from "./endpoints/externalSearch";
import expertiseSearch from "./endpoints/expertiseSearch";
import sources from "./endpoints/sources";
import sharedDriveSearch from "./endpoints/sharedDriveSearch";
import sharedDriveGroups from "./endpoints/sharedDriveGroups";
import projectsSearch from "./endpoints/projectsSearch";

// List of all mocked handlers
const handlers = [
  siteSearch,
  allSearch,
  imageSearch,
  externalSearch,
  expertiseSearch,
  sources,
  sharedDriveGroups,
  sharedDriveSearch,
  projectsSearch,
];

let worker: SetupWorkerApi;
if (isForcingMock || testFlag("mockApi")) {
  worker = setupWorker(...handlers);
}
export { worker };
