import styled from "styled-components/macro";
import { Typography } from "@material-ui/core";

interface TextInterface {
  hasLoginError: boolean;
}

export default styled(({ hasLoginError, ...props }) => (
  <Typography {...props} />
))<TextInterface>`
  margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
  font-size: 12px;
  color: ${({ hasLoginError, theme }) =>
    hasLoginError ? theme.palette.error.main : theme.palette.text.primary};
`;
