import { ReactNode } from "react";
import Container from "./Container";
import Title from "./Title";

export default function Section({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
}
