import { atom } from "recoil";
import { Result } from "./search/all/AllSearch";

/**
 * Keeps track of the current selected image
 * (used to display it in the modal popup)
 */
export default atom<Result | null>({
  key: "SelectedImage",
  default: null,
});
