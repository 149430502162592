import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import Query from "state/Query";
import AllSearchPage from "state/search/all/AllSearchPage";
import RedirectState from "state/Redirect";
import ExpertiseCurrentProject from "state/search/expertise/ExpertiseCurrentProject";
import ExpertiseProjectSearch from "state/search/expertise/ExpertiseProjectSearch";
import ExpertiseSearchPage from "state/search/expertise/ExpertiseSearchPage";
import ImageSearchPage from "state/search/image/ImageSearchPage";

/**
 * Loads the querystring in the url
 */
function LoadQueryString() {
  const location = useLocation();
  const setQuery = useSetRecoilState(Query);
  const setRedirectedState = useSetRecoilState(RedirectState);
  const setExpertiseProjectSearch = useSetRecoilState(ExpertiseProjectSearch);
  const resetAllPage = useResetRecoilState(AllSearchPage);
  const resetImagesPage = useResetRecoilState(ImageSearchPage);
  const resetExpertiseSearchPage = useResetRecoilState(ExpertiseSearchPage);
  const resetExpertiseCurrentProject = useResetRecoilState(
    ExpertiseCurrentProject
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirect = params.get("redirect") === "true" || false;
    if (redirect) {
      const SPSiteName = params.get("SPSiteName") || "";
      const SPSiteUrl = params.get("SPSiteUrl") || "";

      setRedirectedState({ redirected: true, SPSiteName, SPSiteUrl });
    }
    setQuery(params.get("query") || "");
    setExpertiseProjectSearch(params.get("project") === "true");
    resetExpertiseSearchPage();
    resetAllPage();
    resetImagesPage();
    resetExpertiseCurrentProject();
  }, [
    location,
    setQuery,
    resetAllPage,
    resetImagesPage,
    resetExpertiseSearchPage,
    resetExpertiseCurrentProject,
    setExpertiseProjectSearch,
    setRedirectedState,
  ]);

  return null;
}

export default LoadQueryString;
