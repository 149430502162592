import { useRecoilPreviousValue } from "services/hooks";
import AllSearch, {
  useSearchSource,
  Response,
} from "state/search/all/AllSearch";
import ResultsCountControl from "components/PageControls/ResultsCount";

function ResultsCount() {
  const allSearch = useRecoilPreviousValue<Response, Response>(
    AllSearch(0),
    []
  );
  const spoResult = useSearchSource(allSearch, "SPO");
  const numResults = spoResult?.totalRows || 0;
  return <ResultsCountControl count={numResults} />;
}

export default ResultsCount;
