import { SetterOrUpdater } from "recoil";
import { useTranslation } from "react-i18next";
import { FileIcon, defaultStyles, DefaultExtensionType } from "react-file-icon";
import ListItemText from "@material-ui/core/ListItemText";

import { RefinerEntry } from "state/search/all/AllSearch";
import LoadingDisplay from "components/LoadingDisplay";
import DropDown from "components/DropDown";

import List from "./List";
import ListItem from "./ListItem";
import Icon from "./Icon";
import FileTypeDisplay from "./FileTypeDisplay";
import Header from "./Header";
import Button from "./Button";
import { amplitudeTracker } from "services/helpers";
import { AMPLITUDE_EVENTS } from "services/constants";

export interface SelectionOption {
  label?: string;
  value?: string;
}

function FileType({
  entries,
  fileTypeState: [fileType, setFileType],
}: {
  entries: Array<RefinerEntry> | null;
  fileTypeState: [
    SelectionOption | undefined,
    SetterOrUpdater<SelectionOption | undefined>
  ];
}) {
  const { t } = useTranslation();
  const extension = fileType?.label as DefaultExtensionType;
  return (
    <DropDown
      label={
        fileType ? (
          <FileTypeDisplay>
            <Icon size={16}>
              <FileIcon
                radius={0}
                extension={extension}
                {...defaultStyles[extension]}
              />
            </Icon>
            {fileType.label}
          </FileTypeDisplay>
        ) : (
          t("File Type")
        )
      }
      amplitudeFunc={() => amplitudeTracker(AMPLITUDE_EVENTS.CLICK_FILE_TYPE)}
      align="left"
    >
      {({ close }) => {
        function clear() {
          amplitudeTracker(AMPLITUDE_EVENTS.CLEAR_FILE_TYPE);
          setFileType(undefined);
          close();
        }
        return (
          <>
            <Header>
              <Button
                variant="text"
                onClick={clear}
                disabled={fileType === undefined}
              >
                {t("Clear File Type")}
              </Button>
            </Header>
            <List aria-label="filetype">
              {entries ? (
                entries.map((entry, index) => {
                  const option: SelectionOption = {
                    label: entry.RefinementName,
                    value: entry.RefinementValue,
                  };
                  const extension =
                    entry.RefinementName as DefaultExtensionType;

                  function chooseType() {
                    amplitudeTracker(AMPLITUDE_EVENTS.SET_FILTER_DOC_TYPE, {
                      filterType: option.label,
                    });
                    setFileType(option);
                    close();
                  }

                  return (
                    <ListItem
                      selected={(fileType || {}).value === option.value}
                      button
                      onClick={chooseType}
                      key={index}
                    >
                      <Icon>
                        <FileIcon
                          radius={0}
                          extension={extension}
                          {...defaultStyles[extension]}
                        />
                      </Icon>
                      <ListItemText primary={option.label} />
                    </ListItem>
                  );
                })
              ) : (
                <LoadingDisplay style={{ minHeight: "100px" }} />
              )}
            </List>
          </>
        );
      }}
    </DropDown>
  );
}

export default FileType;
