import { useEffect } from "react";
import { isLocalEnvironment } from "services/env";
import { useRecoilValue, useSetRecoilState } from "recoil";

import env from "services/env";
import { testFlag } from "services/testing";
import RedirectCode from "state/auth/RedirectCode";
import TestLoginUser from "state/auth/TestLoginUser";
import WasLoggedIn from "state/WasLoggedIn";

/**
 * Enables auto-login for a local dev user if a saved token is found
 */
function LoadSavedToken() {
  const setRedirectCode = useSetRecoilState(RedirectCode);
  const testLoginUser = useRecoilValue(TestLoginUser);
  const setWasLoggedIn = useSetRecoilState(WasLoggedIn);
  useEffect(() => {
    const localToken = localStorage.getItem("token");
    const shouldSkipLogin =
      testFlag("skipLogin") || testLoginUser || env.REACT_APP_SKIP_LOGIN;
    if ((isLocalEnvironment && localToken) || shouldSkipLogin) {
      setRedirectCode("skip");
      setWasLoggedIn(true);
    }
  }, [setRedirectCode, testLoginUser, setWasLoggedIn]);
  return null;
}

export default LoadSavedToken;
