import { ReactNode, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { ThemeProvider as StyledThemeProvider } from "styled-components/macro";
import { ThemeProvider as MaterialThemeProvider } from "@material-ui/core/styles";

import SelectedTheme from "state/SelectedTheme";
import lightTheme from "theme/light";
import darkTheme from "theme/dark";

function InjectTheme({ children }: { children: ReactNode }) {
  const selectedTheme = useRecoilValue(SelectedTheme);
  const theme = selectedTheme === "light" ? lightTheme : darkTheme;

  useEffect(
    () => localStorage.setItem("theme", selectedTheme),
    [selectedTheme]
  );

  return (
    <MaterialThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </MaterialThemeProvider>
  );
}

export default InjectTheme;
