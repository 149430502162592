import { useMemo } from "react";
import { selectorFamily } from "recoil";
import { AxiosResponse } from "axios";

import { useAPI } from "services/network";
import Query from "state/Query";

import ExpertiseSearchRefiner from "./ExpertiseSearchRefiner";
import ExpertiseProjectSearch from "./ExpertiseProjectSearch";
import ExpertiseSortBy from "./ExpertiseSortBy";

export const endpoint = "/api/v1/SearchApi/expertiseSearch";
export const spoGroup = "SPOExpertise";

export const LineOfBusinessRefiner = "RefinableString100";
export const BusinessRefiner = "RefinableString101";
export type RefinerName = "RefinableString100" | "RefinableString101";
export type Source = "SPOExpertise" | "ExpertiseProjects";

export interface Result {
  // Shared properties
  userAlias: string | null;
  displayUrl: string | null;
  parentUrl: string | null;
  thumbnailUrl: string | null;
  iconUrl: string;
  title: string;
  path: string;
  summary: string | null;
  resultType: null;
  size: number;
  write: null;
  rank: number;

  // ExpertiseProjects
  projectCount: number;
  filePath: null;
  userEmail: null;
  createdDate: string;
  modifiedDate: string;

  // SPOExpertise
  aboutMe: string | null;
  expertiseType: null;
  expertiseArea: null;
  interests: string | null;
  memberships: null;
  pastProjects: string | null;
  assignments: string | null;
  sipAddress: string | null;
  skills: string | null;
  workEmail: string | null;
  preferredName: string | null;
  pictureUrl: string | null;
  firstName: string | null;
  lastName: string | null;
  jobTitle: string | null;
  business: string | null;
  lineOfBusiness: string | null;
  lineOfBusinessCode: string | null;
  responsibilities: string | null;
  hitHighlightedProperties: string | null;
  currentProjectInfo: {
    title: string | null;
    role: string | null;
    location: string | null;
    responsibilityArea: string | null;
    startDate: string | null;
    endDate: string | null;
  };
  docId: number;
  dlcDocId: null;
  siteUrl: null;
  webUrl: null;
  ServerRedirectedEmbedURL: null;
  ServerRedirectedPreviewURL: null;
  ServerRedirectedURL: null;
  Author: null;
  Description: null;
  SiteName: null;
  siteTitle: null;
  people: null;
}

export type Results = {
  source: Source;
  results: Array<Partial<Result>>;
  refinerResults?: Array<{
    name: RefinerName;
    entries: Array<{
      RefinementCount: number;
      RefinementName: string;
      RefinementToken: string;
      RefinementValue: string;
    }>;
  }>;
  rowCount: number;
  totalRows: number;
  totalRowsIncludingDuplicates: number;
  spellingSuggestion?: string | null;
  hasMoreResults?: boolean;
};

interface SortItem {
  property: string;
  direction: "0" | "1";
}

export interface Request {
  page: number;
  queryText: string;
  refiner: string;
  scope: "Expertise";
  sortList?: Array<SortItem>;
  source: "SPOExpertise" | "SPOExpertise,ExpertiseProjects";
  additionalProperties: {};
}
export interface Response extends Array<Results> {}

/**
 * Fetches the expertise-search result from the backend for a given page
 */
export default selectorFamily({
  key: "ExpertiseSearch",
  get:
    (page: number) =>
    async ({ get }) => {
      const queryText = get(Query);
      const refiner = get(ExpertiseSearchRefiner);
      const expertiseProjectSearch = get(ExpertiseProjectSearch);
      const sortBy = get(ExpertiseSortBy);
      const scope = "Expertise";
      const source =
        page > 0 ? "SPOExpertise" : "SPOExpertise,ExpertiseProjects";

      const requestBody: Request = {
        page,
        queryText,
        refiner,
        scope,
        source,
        additionalProperties: {},
      };

      if (sortBy) {
        requestBody.sortList = [sortBy];
      }

      if (expertiseProjectSearch) {
        requestBody.additionalProperties = {
          isProjectSearch: true,
          projectName: queryText,
          startRow: 0,
        };
      }

      return (
        await useAPI.post<Request, AxiosResponse<Response>>(
          endpoint,
          requestBody
        )
      ).data;
    },
});

/**
 * Hook to retrieve specific source results from the response
 * @param response The response data from the backend
 * @param source The source data you want to retrieve
 * @returns The array of results (or null if still loading)
 */
export function useSearchSource(response: Response | null, source: Source) {
  return useMemo(() => {
    return response
      ? response.find((item) => item && item.source === source) || null
      : null;
  }, [response, source]);
}

/**
 * Hook to retrieve specific refiner from the source results
 * @param result The result data from the backend
 * @param refiner The refiner name you want to retrieve
 * @returns The array of results (or null if still loading)
 */
export function useSearchRefiner(result: Results | null, refiner: RefinerName) {
  return useMemo(() => {
    if (!result) {
      return null;
    }
    if (result.refinerResults) {
      return (
        result.refinerResults.find((item) => item.name === refiner)?.entries ||
        null
      );
    }
    return null;
  }, [result, refiner]);
}
