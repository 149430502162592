import { rest } from "msw";

import { siftUrl } from "services/network";
import { apiDelay, apiStatus, apiResponse } from "services/testing";
import {
  Response,
  endpoint,
} from "state/search/sharedDrives/SharedDriveGroups";

import sharedDriveGroups from "../data/sharedDriveGroups";

export default rest.get<undefined, Response>(
  `${siftUrl}${endpoint}/*`,
  function (req, res, ctx) {
    return res(
      ctx.delay(apiDelay(endpoint, 1300)),
      ctx.status(apiStatus(endpoint, 200)),
      ctx.json(apiResponse(endpoint, sharedDriveGroups))
    );
  }
);
