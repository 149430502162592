import { Typography } from "@material-ui/core";
import { ReactNode } from "react";
import styled from "styled-components/macro";

const fontSize = "12px";

const Label = styled(Typography)`
  font-weight: bold;
  font-size: ${fontSize};
`;

const Container = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
  width: 100%;
`;

function Property({ label, children }: { label: string; children: ReactNode }) {
  return (
    <Container>
      <Label>{label}</Label>
      {children}
    </Container>
  );
}

export default Property;
