import styled from "styled-components";

export default styled.p`
  text-align: center;
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;
