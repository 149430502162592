import styled from "styled-components/macro";

export default styled.div`
  display: grid;
  white-space: nowrap;
  font-size: 13px;
  place-self: center;
  place-items: center;
  grid-auto-flow: column;
  grid-column-gap: ${({ theme }) => `${theme.spacing(1)}px`};
`;
