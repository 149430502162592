export const AMPLITUDE_EVENTS = {
  INPUT_SEARCH: "Input_search",
  TAB_ALL: "Tab_All",
  TAB_IMAGES: "Tab_Images",
  TAB_EXTERNAL: "Tab_External",
  TAB_SHARED_DRIVES: "Tab_Shared_Drives",
  TAB_EXPERTISE: "Tab_Expertise",
  EXPORT_TO_EXCEL: "Export_To_Excel",
  CLICK_FILE_TYPE: "Click_File_Type",
  CLEAR_FILE_TYPE: "Clear_File_Type",
  SET_FILTER_DOC_TYPE: "Set_Filter_Doc_Type",
  CLICK_LAST_MODIFIED: "Click_Last_Modified",
  APPLY_DATE_RANGE: "Apply_Date_Range",
  CLEAR_DATE_RANGE: "Clear_Date_Range",
  FILTER_BY_SOURCE_LIBRARY: "Filter_By_Source_Library",
  SELECTING_NEW_SOURCE_FILTER: "Selecting_New_Source_Filter",
  RESULT_OPENED: "Result_opened",
  EXPAND_RESULT_CARD: "Expand_result_card",
};

export const REDIRECTING_CONSTANTS = {
  HEADER_TEXT: "Redirecting to USE...",
  PHRASE_TEXT: "Your search is being redirected to Unified Search Experience.",
  FLEXBOX_SIZE: 12,
  CIRCULAR_PROGRESS_SIZE: "64px",
};

export const REFINER_BANNER_CONSTANTS = {
  SEARCH_TEXT: "You are currently searching within",
  CLEAR_FILTER_TEXT: "Clear filter",
  STYLED_DROPDOWN_LINE_HEIGHT: 22,
  SKY_SOLID_50: "#eef6f9",
  SKY_DEFAULT: "#0097BB",
};

export const ITEM_CONSTANTS = {
  CHECKMARK_TEST_ID: "Checkmark",
  SUBDIRECTORY_ARROW_TEST_ID: "SubdirectoryArrow",
  SUBDIRECTORY_ARROW_COLOR: "#757575",
};

export const MAIL_BUTTON_CONSTANTS = {
  SKY_DEFAULT: "#0097BB",
  text: "Request Georeferencing",
  mailToReceiver: "CHN-RMZSBO-TAO-SSW-Services@shell.com",
};
