import { animated, useSpring } from "@react-spring/web";
import { formatNumber } from "services/helpers";

function NumberAnimation({ to, ...props }: { to: number }) {
  const countAnimation = useSpring({
    from: { num: 0 },
    to: { num: to },
    config: { duration: 1000 },
  });
  return (
    <animated.span {...props}>
      {countAnimation.num.to((val) => formatNumber(Math.floor(val)))}
    </animated.span>
  );
}

export default NumberAnimation;
