import LandingSearch from "./LandingSearch";

// The page path relative to the application root
const path = "/";

function LandingPage() {
  return <LandingSearch />;
}

export { path };
export default LandingPage;
