import { Button } from "@material-ui/core";
import chroma from "chroma-js";
import styled, { css } from "styled-components/macro";

const activeStyle = css`
  background: rgba(0, 0, 0, 0.1);
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export default styled(({ active, ...props }) => <Button {...props} />).attrs({
  variant: "text",
  size: "small",
  color: "secondary",
})<{ active: boolean }>`
  & + & {
    margin-left: ${({ theme }) => `${theme.spacing(1)}px`};
  }
  color: ${({ theme }) =>
    chroma(theme.palette.getContrastText(theme.palette.secondary.main))
      .alpha(0.7)
      .css()};
  ${({ active }) => active && activeStyle}
  border-radius: ${({ theme }) =>
    `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`};
  padding-left: ${({ theme }) => `${theme.spacing(1)}px`};
  padding-right: ${({ theme }) => `${theme.spacing(1)}px`};
`;
