import { Typography } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(Typography).attrs({ component: "div" })`
  font-size: 12px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  .highlight {
    font-weight: bold;
  }
`;
