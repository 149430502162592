import { Container } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(Container).attrs({ maxWidth: "sm" })`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-row-gap: ${({ theme }) => `${theme.spacing(6)}px`};
  place-items: center;
  text-align: center;
  position: relative;
`;
