import styled from "styled-components/macro";

export default styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  place-items: start;
  max-height: 100%;
  overflow: auto;
  padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
`;
