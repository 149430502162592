import styled from "styled-components/macro";
import { ListItem } from "@material-ui/core";

export default styled(ListItem)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  white-space: nowrap;
  font-size: 13px;
  padding-top: ${({ theme }) => `${theme.spacing(0.75)}px`};
  padding-bottom: ${({ theme }) => `${theme.spacing(0.75)}px`};
  place-self: center;
  text-transform: uppercase;
  & > * {
    & > * {
      font-size: 12px;
    }
    min-width: auto;
  }
`;
