import { useMemo } from "react";
import { selectorFamily } from "recoil";
import { AxiosResponse } from "axios";

import { useRecoilPreviousValue } from "services/hooks";
import { useAPI } from "services/network";
import Query from "state/Query";

import AllSearchRefiner from "./AllSearchRefiner";
import { RefinerTypes } from "components/Refiners/types/RefinerTypes";

export const endpoint = "/api/v1/SearchApi/allSearch";
export const spoGroup = "all";

export type Refiner = {
  name: string;
  displayName?: string;
  entries: Array<RefinerEntry>;
};

export type Result = {
  Author: string;
  Description: string | null;
  ServerRedirectedEmbedURL: string | null;
  ServerRedirectedPreviewURL: string | null;
  ServerRedirectedURL: string | null;
  SiteName: string;
  displayUrl: string | null;
  dlcDocId: string | null;
  docId: number;
  iconUrl: string;
  parentUrl: string;
  path: string;
  people: string | null;
  rank: number;
  resultType: string;
  siteTitle: string;
  siteUrl: string;
  size: number;
  summary: string;
  thumbnailUrl: string | null;
  title: string;
  webUrl: string | null;
  write: string;
  captionText?: string | null;
  contentTypeId?: string;
  documentFileName?: string;
  driveId?: string;
  driveItemId?: string;
  fileType?: string;
  imageName?: string;
  listId?: string;
  listItemId?: string;
  ocrText?: string | null;
  parentFileName?: string;
  pictureHeight?: number;
  pictureThumbnailURL?: string;
  pictureURL?: string;
  pictureWidth?: number;
  siteId?: string;
  slideNumber?: string;
  slideText?: string | null;
  textAfter?: string | null;
  textBefore?: null;
  uniqueId?: string;
  webId?: string;
};

export interface RefinerEntry {
  RefinementCount?: number;
  RefinementName: string;
  RefinementToken: string;
  RefinementValue?: string;
}

type Source = "SPO" | "SPOImage";
export type AllSourceResult = {
  source: Source;
  results: Array<Result>;
  refinerResults: Array<Refiner>;
  rowCount: number;
  spellingSuggestion: string | null;
  totalRows: number;
  totalRowsIncludingDuplicates: number;
};

export interface Request {
  page: number;
  queryText: string;
  refiner?: string;
  scope: "All" | "Expertise";
  source: "SPO" | "SPO,SPOImage";
}
export interface Response extends Array<AllSourceResult> {}

/**
 * Fetches the all-search result from the backend for a given page
 */
const AllSearch = selectorFamily({
  key: "AllSearch",
  get:
    (page: number) =>
    async ({ get }) => {
      const queryText = get(Query);
      const refiner = get(AllSearchRefiner);
      const scope = "All";
      const source = page > 0 ? "SPO" : "SPO,SPOImage";
      const requestBody: Request = {
        page,
        queryText,
        refiner,
        scope,
        source,
      };
      const response = (
        await useAPI.post<Request, AxiosResponse<Response>>(
          endpoint,
          requestBody
        )
      ).data;
      return response;
    },
});

/**
 * Returns the list of file types found in page 0
 * @returns
 */
export function useFileTypeEntries(source: Source = "SPO") {
  const spoResult = useSearchSource(
    useRecoilPreviousValue(AllSearch(0), null),
    source
  );
  return useMemo(() => {
    const refiner = spoResult?.refinerResults.find(
      (refiner) => refiner.name === "FileType"
    );
    return refiner ? refiner.entries : null;
  }, [spoResult]);
}

/**
 * Returns the list of refiners
 * @returns
 */
export function useRefiners(source: Source = "SPO") {
  const spoResult = useSearchSource(
    useRecoilPreviousValue(AllSearch(0), null),
    source
  );
  return useMemo(() => {
    return spoResult?.refinerResults?.reduce((result,r)=> {
      if(RefinerTypes[r.name as keyof typeof RefinerTypes])
        result.push(<Refiner>{...r, displayName: RefinerTypes[r.name as keyof typeof RefinerTypes]});
      return result;
    }, [] as Refiner[]);
  }, [spoResult]);
}

/**
 * Hook to retrieve specific source results from the response
 * @param source The source data you want to retrieve
 * @returns The array of results (or null if still loading)
 */
export function useSearchSource(allSearch: Response | null, source: Source) {
  return useMemo(
    () =>
      allSearch
        ? allSearch.find((item) => item && item.source === source) || null
        : null,
    [allSearch, source]
  );
}

export default AllSearch;
