import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { FileIcon, defaultStyles } from "react-file-icon";
import { useTheme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Close";

import ExternalActiveItem from "state/search/external/ExternalActiveItem";
import ExternalResultDisplayWidth from "state/search/external/ExternalResultDisplayWidth";
import { Record } from "state/search/external/ExternalSearch";

import Paper from "./Paper";
import Icon from "./Icon";
import Title from "./Title";
import Content from "./Content";
import ShowMore from "./ShowMore";
import Information from "./Information";
import IconLabel from "./IconLabel";
import Abstract from "./Abstract";
import ResultDisplay from "./ResultDisplay";
import DetailDisplay from "./DetailDisplay";

export function getInfo(result: Record, label: string) {
  return result.items?.find((item) => item.label === label)?.data as string;
}

export function highlight(value: string) {
  return (value || "")
    .replaceAll("&lt;highlight&gt;", '<span class="highlight">')
    .replaceAll("&lt;/highlight&gt;", "</span>");
}

function Result({ result }: { result: Record }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fileExtension = "cue";
  const [activeItem, setActiveItem] = useRecoilState(ExternalActiveItem);
  const setResultDisplayWidth = useSetRecoilState(ExternalResultDisplayWidth);
  const isActive = activeItem === result;
  const [container, setContainer] = useState<HTMLDivElement>();

  function toggleActive() {
    if (activeItem === result) {
      setActiveItem(null);
    } else if (container) {
      setActiveItem(result);
    }
  }

  function resultDisplayCallback(element: HTMLDivElement) {
    if (element) {
      setResultDisplayWidth(element.getBoundingClientRect().width);
    }
  }

  return (
    <ResultDisplay
      ref={(element: HTMLDivElement | null) => element && setContainer(element)}
    >
      <Paper active={isActive} onClick={toggleActive}>
        <Content>
          <IconLabel>
            <Icon>
              <FileIcon
                radius={0}
                extension={""}
                foldColor={
                  result.header.pubType !== "Periodical"
                    ? theme.palette.primary.main
                    : undefined
                }
                {...defaultStyles[fileExtension]}
              />
            </Icon>
            {result.header.pubType}
          </IconLabel>
          <Information>
            <Title
              href={result.pLink}
              target="_blank"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{
                __html: highlight(getInfo(result, "Title")),
              }}
            />
            <div>{highlight(getInfo(result, "Journal Title"))}</div>
            <Abstract
              dangerouslySetInnerHTML={{
                __html: highlight(getInfo(result, "Abstract")),
              }}
            />
          </Information>
        </Content>
        <ShowMore>
          <IconButton
            size="small"
            aria-label={t("See details")}
            component="div"
          >
            {isActive ? <CloseIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </ShowMore>
      </Paper>
      <div ref={resultDisplayCallback}>
        {isActive && <DetailDisplay result={activeItem} />}
      </div>
    </ResultDisplay>
  );
}

export default Result;
