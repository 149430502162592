import { useTrail, animated } from "@react-spring/web";
import { useRef, useEffect } from "react";

import { useRecoilValueOr } from "services/hooks";
import ExternalSearch from "state/search/external/ExternalSearch";

import Container from "./Container";
import Result from "./Result";
import NoResults from "./NoResults";

function ResultsPage({ page }: { page: number }) {
  const results = useRecoilValueOr(ExternalSearch(page), null)?.searchResult
    .data.records;
  const previousResult = useRef(results);
  useEffect(() => {
    previousResult.current = results;
  }, [results]);
  const newResult = previousResult.current !== results;

  const trail = useTrail(results?.length || 0, {
    config: { duration: 100 },
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: newResult,
  });

  return results ? (
    <Container>
      {trail.map((style, index) => {
        return (
          <animated.div key={index} style={style}>
            <Result result={results[index]} />
          </animated.div>
        );
      })}
    </Container>
  ) : (
    <NoResults />
  );
}

export default ResultsPage;
