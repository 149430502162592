import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";

import { useHasQuery } from "state/Query";

import PageContainer from "components/PageContainer";
import HeaderBar from "components/HeaderBar";
import PageControls from "components/PageControls";
import PleaseEnterQuery from "components/PleaseEnterQuery";
import LoadingDisplay from "components/LoadingDisplay";
import ErrorDisplay from "components/ErrorDisplay";

import ResultsCount from "./controls/ResultsCount";
import DateRange from "./controls/DateRange";
import FileType from "./controls/FileType";
import Export from "./controls/Export";
import SharedDrives from "./controls/SharedDrives";

import Results from "./Results";
import ReportData from "components/ReportData";

// The page path relative to the application root
const path = "/shared";

function SharedDrivesPage() {
  const { t } = useTranslation();
  const hasQuery = useHasQuery();

  if (!hasQuery) {
    return <PleaseEnterQuery />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("Shared Drives")} - {t("APP_NAME")}
        </title>
      </Helmet>
      <HeaderBar>
        <PageControls
          left={[<ResultsCount />]}
          center={[<DateRange />, <FileType />, <Export />, <ReportData />]}
          right={[<SharedDrives />]}
        />
      </HeaderBar>
      <PageContainer>
        <ErrorBoundary FallbackComponent={ErrorDisplay}>
          <Suspense
            fallback={<LoadingDisplay style={{ minHeight: "200px" }} />}
          >
            <Results />
          </Suspense>
        </ErrorBoundary>
      </PageContainer>
    </>
  );
}

export { path };
export default SharedDrivesPage;
