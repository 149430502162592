import { Paper } from "@material-ui/core";
import { animated } from "@react-spring/web";
import chroma from "chroma-js";
import styled, { css } from "styled-components/macro";

const lightNormalStyle = css`
  border-color: ${({ theme }) =>
    chroma
      .mix(theme.palette.text.primary, theme.palette.background.paper, 0.7)
      .css()};
`;
const lightActiveStyle = css`
  border-color: ${({ theme }) =>
    chroma
      .mix(theme.palette.text.primary, theme.palette.background.paper, 0.4)
      .css()};
`;
const darkNormalStyle = css`
  border-color: ${({ theme }) =>
    chroma
      .mix(theme.palette.text.primary, theme.palette.background.paper, 0.9)
      .css()};
`;
const darkActiveStyle = css`
  border-color: ${({ theme }) =>
    chroma
      .mix(theme.palette.text.primary, theme.palette.background.paper, 0.6)
      .css()};
`;

export default animated(styled(Paper).attrs({ elevation: 0 })<{
  open: boolean;
}>`
  position: relative;
  border: 1px solid transparent;
  background: ${({ theme }) => theme.palette.background.paper};
  ${({ open, theme }) => {
    if (theme.palette.type === "light") {
      return open ? lightActiveStyle : lightNormalStyle;
    }
    return open ? darkActiveStyle : darkNormalStyle;
  }}
  &:hover {
    ${({ theme }) => {
      if (theme.palette.type === "light") {
        return lightActiveStyle;
      }
      return darkActiveStyle;
    }}
  }
`);
