import { selector } from "recoil";
import { AxiosResponse } from "axios";

import { useAPI } from "services/network";
import { addParentRef, Node } from "state/treeTypes";

export const endpoint = "/api/v1/SiteInfoApi/getSiteJsonData";

export interface SourceNode extends Node {
  checked?: boolean;
  hasChildren?: boolean;
  sites?: Array<string>;
  driveId?: string;
  imageLibraryUrl?: string;
  libraryUrl?: string;
  siteUrl?: string;
}

export interface Params {
  t: number;
}
export interface Response extends Array<SourceNode> {}

export function getSite(path: string | undefined) {
  return path ? path.split("/").reverse()[0] : "";
}

export function getSitePaths(
  nodes: Array<SourceNode>,
  pathIds: Set<string> = new Set()
): Array<string> {
  nodes.forEach((node) => {
    if (node.siteUrl) {
      pathIds.add(getSite(node.siteUrl));
    }
    if (node.children && node.children.length) {
      getSitePaths(node.children, pathIds);
    }
  });
  return Array.from(pathIds).filter((x) => x);
}

export function getSiteList(
  nodes: Array<SourceNode>,
  siteList: Set<string> = new Set()
): Array<string> {
  nodes.forEach((node) => {
    node.sites?.forEach((site) => siteList.add(getSite(site)));
    if (node.children && node.children.length) {
      getSiteList(node.children, siteList);
    }
  });
  return Array.from(siteList).filter((x) => x);
}

/**
 * Fetches the sharepoint sites and/or libraries (source) tree from the backend
 */
export default selector({
  key: "SourceTree",
  get: async () => {
    const params: Params = {
      t: new Date().getTime(),
    };
    return (
      await useAPI.get<Params, AxiosResponse<Response>>(endpoint, { params })
    ).data.map((source) => addParentRef(source));
  },
});
