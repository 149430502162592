import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { config, useSpring } from "@react-spring/web";
import ReplayIcon from "@material-ui/icons/Replay";

import Layout from "./Layout";
import Container from "./Container";
import Message from "./Message";
import Button from "./Button";
import ErrorIcon from "./ErrorIcon";

interface MessageProps {
  error: { message: string };
  resetErrorBoundary: () => void;
}

export default function ErrorDisplay({
  error,
  resetErrorBoundary,
}: FallbackProps | MessageProps) {
  const { t } = useTranslation();
  const spring = useSpring({
    from: { opacity: 0, transform: "scale(0.9)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: (key) => ({
      ...config.gentle,
      clamp: key === "opacity" ? true : false,
    }),
  });
  return (
    <Layout>
      <Container data-testid="error-display" elevation={0} style={spring}>
        <Message>
          <ErrorIcon color="error" />
          {error?.message || t("An unknown error occured")}
        </Message>
        <Button startIcon={<ReplayIcon />} onClick={resetErrorBoundary}>
          {t("Retry")}
        </Button>
      </Container>
    </Layout>
  );
}
