import styled from "styled-components/macro";
import { ReactComponent as SearchIcon } from "./search-icon.svg";

export default styled(SearchIcon)`
  max-height: 30px;
  margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
  circle,
  line {
    stroke: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.background.default)};
  }
`;
