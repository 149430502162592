import { atom } from "recoil";

export type ExpertiseSortBy =
  | {
      direction: "0" | "1";
      property: string;
      label: string;
    }
  | undefined;

/**
 * The sorting option selection for the expertise page
 */
export default atom<ExpertiseSortBy>({
  key: "ExpertiseSortBy",
  default: undefined,
});
