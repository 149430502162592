import styled from "styled-components";

export default styled.h2`
  text-align: center;
  margin: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;
