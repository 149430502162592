import { useRecoilValue } from "recoil";
import { config, useSprings } from "@react-spring/web";

import ImageSearch from "state/search/image/ImageSearch";

import ImageCard from "./ImageCard";
import ImageDialog from "./ImageDialog";
import NoResults from "./NoResults";

function Images({ page }: { page: number }) {
  const { results: images } = useRecoilValue(ImageSearch(page));

  const springs = useSprings(
    images.length,
    images.map((item, index) => ({
      config: { ...config.gentle, clamp: true },
      from: { opacity: 0 },
      to: { opacity: 1 },
      delay: index * 20,
    }))
  );

  return images.length ? (
    <>
      {springs.map((style, index) => {
        return <ImageCard image={images[index]} key={index} style={style} />;
      })}
      <ImageDialog images={images} />
    </>
  ) : (
    <NoResults />
  );
}

export default Images;
