import ReactDOM from "react-dom";
import "translations/i18n";

import AppSetup from "App/AppSetup";
import App from "App";

// Start MSW for endpoint mocking
const { worker } = require("./mocks/browser");
worker && worker.start({ onUnhandledRequest: "bypass" });

ReactDOM.render(
  <AppSetup>
    <App />
  </AppSetup>,
  document.getElementById("root")
);
