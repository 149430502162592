import styled from "styled-components/macro";
import { Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const Container = styled(Typography)`
  background: rgba(0, 0, 0, 0.05);
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  grid-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center start;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.hint};
`;

export default function Notice({ children }: { children: string }) {
  return (
    <Container>
      <InfoIcon />
      {children}
    </Container>
  );
}
