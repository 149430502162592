import styled, { css } from "styled-components/macro";
import Paper from "@material-ui/core/Paper";
import chroma from "chroma-js";

const light = css`
  border: ${({ theme }) =>
    `1px solid ${chroma
      .mix(theme.palette.background.paper, theme.palette.text.primary, 0.4)
      .css()}`};
`;

const dark = css`
  border: ${({ theme }) =>
    `1px solid ${chroma(theme.palette.background.paper).darken(0.5).css()}`};
`;

export default styled(Paper).attrs({ elevation: 0 })`
  width: 100%;
  ${({ theme }) => (theme.palette.type === "light" ? light : dark)}
`;
