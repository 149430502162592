import { rest } from "msw";

import { useUrl } from "services/network";
import { apiDelay, apiStatus, apiResponse } from "services/testing";
import { Params, Response, endpoint } from "state/sources/SourceTree";

import sources from "../data/sources";

export default rest.get<Params, Response>(
  `${useUrl}${endpoint}`,
  function (req, res, ctx) {
    return res(
      ctx.delay(apiDelay(endpoint, 1300)),
      ctx.status(apiStatus(endpoint, 200)),
      ctx.json(apiResponse(endpoint, sources))
    );
  }
);
