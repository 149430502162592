import styled from "styled-components/macro";

export default styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  place-items: center left;
  place-self: end center;
  width: 100%;
  border-radius: ${({ theme }) =>
    `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`};
`;
