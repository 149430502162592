import { useState, useCallback, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Result } from "state/search/all/AllSearch";
import SelectedImage from "state/SelectedImage";
import LoadingDisplay from "components/LoadingDisplay";

import Container from "./Container";
import ImageEl from "./Image";
import SlideLink from "./SlideLink";
import ErrorDisplay from "./ErrorDisplay";

function ImageCard({ image, style, ...props }: { image: Result; style?: any }) {
  const { t } = useTranslation();
  const [width, setWidth] = useState(0);
  const theme = useTheme();
  const containerCallback = useCallback(
    (image: HTMLImageElement) => {
      if (image) {
        const width = image.getBoundingClientRect().width;
        setWidth(width - 2 - theme.spacing(2) * 2);
      }
    },
    [theme]
  );
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const setSelectedImage = useSetRecoilState(SelectedImage);

  function selectImage() {
    setSelectedImage(image);
  }

  useEffect(() => {
    if (image.pictureThumbnailURL) {
      const newImg = new Image();
      newImg.onload = () => {
        setLoaded(true);
      };
      newImg.onerror = () => setError(true);
      newImg.src = image.pictureThumbnailURL;
    }
  }, [image]);

  return (
    <Container style={style} {...props} ref={containerCallback}>
      {loaded ? (
        <ImageEl
          src={image.pictureThumbnailURL}
          title={t("View image details")}
          onClick={selectImage}
        />
      ) : error ? (
        <ErrorDisplay onClick={selectImage}>{t("Error loading")}</ErrorDisplay>
      ) : (
        <LoadingDisplay style={{ height: "160px" }} />
      )}
      <SlideLink image={image} width={width} />
    </Container>
  );
}

export default ImageCard;
