import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  gap: 16px;
  min-height: 40px;
  min-width: 215px;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

export default Container;
