import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { config, useSpring } from "@react-spring/web";
import { Divider } from "@material-ui/core";

import { Result } from "state/search/expertise/ExpertiseSearch";
import { formatDateTime } from "services/helpers";
import { useGetPath } from "services/hooks";
import { path as ExpertisePath } from "pages/ExpertisePage";

import Pill from "pages/ExpertisePage/SearchResults/Results/ResultsPage/Result/Pill";
import Container from "./Container";
import Section from "./Section";
import Message from "./Message";
import Items from "./Items";
import Label from "./Label";
import Text from "./Text";
import ResponsibilityArea from "./ResponsibilityArea";
import Skills from "./Skills";
import PillLink from "./PillLink";

export default function Details({ person }: { person: Partial<Result> }) {
  const { t } = useTranslation();
  const getPath = useGetPath();
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { ...config.gentle, clamp: true },
  });

  const hasProject = Boolean(person.currentProjectInfo?.startDate);
  const skills = useMemo(() => {
    if (person.skills?.includes(";")) {
      return person.skills.split(";");
    }
  }, [person]);
  const assignments = useMemo(() => {
    if (person.assignments?.includes(";")) {
      return person.assignments.split(";");
    }
  }, [person]);

  if (!hasProject && !skills && !assignments) {
    return <Message style={spring}>{t("No details found")}</Message>;
  }

  return (
    <Container style={spring}>
      {hasProject && (
        <Section title={t("Current Project")}>
          <Items>
            <Label>{t("Project Title")}</Label>
            <Divider orientation="vertical" />
            <Text title={person.currentProjectInfo?.title || ""}>
              {person.currentProjectInfo?.title ? (
                <Link
                  to={getPath(ExpertisePath, {
                    query: `EFCurrentProjectTitle:"${person.currentProjectInfo.title}"`,
                  })}
                >
                  {person.currentProjectInfo.title}
                </Link>
              ) : (
                <span>-</span>
              )}
            </Text>

            <Label>{t("Project Start")}</Label>
            <Divider orientation="vertical" />
            <Text title={person.currentProjectInfo?.startDate || ""}>
              {person.currentProjectInfo?.startDate
                ? formatDateTime(
                    person.currentProjectInfo.startDate,
                    "LLLL do yyyy"
                  )
                : "-"}
            </Text>

            <Label>{t("Location")}</Label>
            <Divider orientation="vertical" />
            <Text title={person.currentProjectInfo?.location || ""}>
              {person.currentProjectInfo?.location ? (
                <Link
                  to={getPath(ExpertisePath, {
                    query: `EFCurrentProjectLocation:"${person.currentProjectInfo.location}"`,
                  })}
                >
                  {person.currentProjectInfo.location}
                </Link>
              ) : (
                <span>-</span>
              )}
            </Text>

            <Label>{t("Role")}</Label>
            <Divider orientation="vertical" />
            <Text title={person.currentProjectInfo?.role || ""}>
              {person.currentProjectInfo?.role ? (
                <Link
                  to={getPath(ExpertisePath, {
                    query: `EFCurrentProjectRole:"${person.currentProjectInfo.role}"`,
                  })}
                >
                  {person.currentProjectInfo.role}
                </Link>
              ) : (
                <span>-</span>
              )}
            </Text>

            <Label>{t("Responsibility")}</Label>
            <Divider orientation="vertical" />
            {person.currentProjectInfo?.responsibilityArea ? (
              <ResponsibilityArea>
                {person.currentProjectInfo?.responsibilityArea}
              </ResponsibilityArea>
            ) : (
              <span>-</span>
            )}
          </Items>
        </Section>
      )}

      {skills && (
        <Section title={t("Skills")}>
          <Skills>
            {skills.map((skill, index) => (
              <PillLink
                key={index}
                to={getPath(ExpertisePath, { query: `Skills:"${skill}"` })}
              >
                <Pill title={skill}>{skill}</Pill>
              </PillLink>
            ))}
          </Skills>
        </Section>
      )}

      {assignments && (
        <Section title={t("Assignments")}>
          <Skills>
            {assignments.map((assignment, index) => (
              <PillLink
                key={index}
                to={getPath(ExpertisePath, {
                  query: `Assignments:"${assignment}"`,
                })}
              >
                <Pill key={index} title={assignment}>
                  {assignment}
                </Pill>
              </PillLink>
            ))}
          </Skills>
        </Section>
      )}
    </Container>
  );
}
