import { DialogContent } from "@material-ui/core";
import { animated } from "@react-spring/web";
import styled from "styled-components/macro";

export default animated(styled(DialogContent)`
  position: relative;
  padding: ${({ theme }) => `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`};
  display: flex;
  flex-direction: row;
`);
