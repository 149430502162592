import { ReactNode, Children } from "react";
import { useSetRecoilState } from "recoil";
import { Container, Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import PageControlsBottom from "state/layout/PageControlsBottom";

import Paper from "./Paper";
import Control from "./Control";
import Left from "./Left";
import Center from "./Center";
import Right from "./Right";

export interface PageControlsProps {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
}

function PageControls(props: PageControlsProps) {
  const { left, center, right } = props;
  const setPageControlBottom = useSetRecoilState(PageControlsBottom);
  const theme: Theme = useTheme();
  function containerCallback(element: HTMLDivElement) {
    if (element) {
      const rect = element.getBoundingClientRect();
      setPageControlBottom(rect.bottom + theme.spacing(2));
    }
  }
  return (
    <Container ref={containerCallback}>
      <Paper>
        {left && (
          <Left>
            {Children.map(left, (child) => (
              <Control>{child}</Control>
            ))}
          </Left>
        )}
        {center && (
          <Center>
            {Children.map(center, (child) => (
              <Control>{child}</Control>
            ))}
          </Center>
        )}
        {right && (
          <Right>
            {Children.map(right, (child) => (
              <Control>{child}</Control>
            ))}
          </Right>
        )}
      </Paper>
    </Container>
  );
}

export default PageControls;
