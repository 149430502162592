import styled from "styled-components/macro";
import { animated } from "@react-spring/web";

export default animated(styled.header`
  grid-area: header;
  display: grid;
  background: ${({ theme }) => theme.palette.header};
  position: relative;
  z-index: 20;
`);
