import { rest } from "msw";

import { useUrl } from "services/network";
import { apiDelay, apiStatus, apiResponse } from "services/testing";
import {
  Request,
  Response,
  endpoint,
} from "state/search/expertise/ExpertisePersonProjects";

import projects from "../data/expertiseSearch/userProjects";
import projectDetails from "../data/expertiseSearch/userProjectDetails";

export default rest.post<Request, Response>(
  `${useUrl}${endpoint}`,
  function (req, res, ctx) {
    const projectsResponse: Response = req.body.projectName
      ? /* project detail (getting a random detail set) */
        projectDetails[Math.floor(Math.random() * projectDetails.length)]
      : /* project list (getting a random set) */
        projects[Math.floor(Math.random() * projects.length)];
    return res(
      ctx.delay(apiDelay(endpoint, 1500)),
      ctx.status(apiStatus(endpoint, 200)),
      ctx.json(apiResponse(endpoint, projectsResponse))
    );
  }
);
