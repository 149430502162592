import { useTranslation } from "react-i18next";
import { useGetImageLocation } from "services/hooks";

import { Result } from "state/search/all/AllSearch";
import Button from "./Button";
import Label from "./Label";

function SlideLink({ image, width }: { image: Result; width: number }) {
  const { t } = useTranslation();
  const { addr, label } = useGetImageLocation(image);

  return addr && label ? (
    <Button
      target="_blank"
      rel="noopener noreferrer"
      href={addr}
      title={label}
      width={width}
      variant="text"
    >
      <Label>
        {t("Page")} {image.slideNumber}
      </Label>
      <Label>{label}</Label>
    </Button>
  ) : null;
}

export default SlideLink;
