import { useEffect, useState, HTMLProps } from "react";
import styled from "styled-components/macro";
import chroma from "chroma-js";
import { animated } from "@react-spring/web";
import classNames from "classnames";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";

const Img = animated(styled(InnerImageZoom)`
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border: ${({ theme }) =>
    `1px solid ${chroma
      .mix(theme.palette.background.paper, theme.palette.text.primary, 0.2)
      .css()}`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border: ${({ theme }) =>
    `1px solid ${chroma
      .mix(theme.palette.background.paper, theme.palette.text.primary, 0.2)
      .css()}`};
  align-self: center;
  flex: 0 0 auto;
  max-height: 75vh;
  max-width: 50vw;
  grid-area: image;
  position: relative;
  z-index: 1;

  opacity: 0;
  transition: opacity 0.3s ease;
  &.rendered {
    height: auto;
    opacity: 1;
  }
`);

function Image(props: HTMLProps<HTMLImageElement>) {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRendered(true);
    }, 300);
  }, []);

  return (
    <Img
      className={classNames(rendered && "rendered")}
      {...props}
      zoomScale={1}
    />
  );
}

export default Image;
