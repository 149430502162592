import { atom } from "recoil";
import { Node } from "state/treeTypes";

/**
 * Stores the applied selected shared drives
 */
export default atom<Set<Node>>({
  key: "AppliedSharedDrives",
  default: new Set<Node>(),
});
