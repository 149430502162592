import styled from "styled-components/macro";

export default styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  white-space: nowrap;
  font-size: 13px;
  place-self: center;
  place-items: center;
  text-transform: uppercase;
`;
