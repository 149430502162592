import Container from "@material-ui/core/Container";
import styled from "styled-components/macro";

export default styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-top: ${({ theme }) => `${theme.spacing(1)}px`};
  padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
`;
