import styled from "styled-components";

const Text = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  white-space: nowrap;
  margin: 9px 12px 9px 0;
`;

export default Text;
