import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { useRecoilPreviousValue } from "services/hooks";
import AllSearch, {
  Response,
  useSearchSource,
  useSearchRefiner,
  BusinessRefiner,
} from "state/search/expertise/ExpertiseSearch";

import ExpertiseBusiness from "state/search/expertise/ExpertiseBusiness";
import SelectionDropdown from "components/PageControls/SelectionDropdown";

export default function Business() {
  const { t } = useTranslation();
  const expertiseBusinessState = useRecoilState(ExpertiseBusiness);
  const spoResult = useSearchSource(
    useRecoilPreviousValue(AllSearch(0), [] as Response),
    "SPOExpertise"
  );
  const options = useSearchRefiner(spoResult, BusinessRefiner)?.map(
    (result) => ({
      label: result.RefinementName,
      value: result.RefinementToken,
    })
  );
  return (
    <SelectionDropdown
      label={t("Business")}
      options={options}
      state={expertiseBusinessState}
    />
  );
}
