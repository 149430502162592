import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { capitalizeFirst } from "services/helpers";
import SiftRegion from "state/SiftRegion";
import DropDown from "components/DropDown";

import TreeFilterControl from "./TreeFilter";
import ErrorDisplay from "./ErrorDisplay";

export interface Dirty {
  isDirty: boolean;
  setDirty: () => void;
}

function SharedDrives() {
  const { t } = useTranslation();
  const region = useRecoilValue(SiftRegion);
  return (
    <DropDown
      label={
        <>
          {t(
            region
              ? `Shared Drives (${capitalizeFirst(region)})`
              : `Shared Drives`
          )}
        </>
      }
      align="right"
      ErrorDisplay={ErrorDisplay}
    >
      {({ close }) => <TreeFilterControl close={close} />}
    </DropDown>
  );
}

export default SharedDrives;
