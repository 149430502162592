import { ButtonBase } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(ButtonBase).attrs({ component: "div" })<{
  haschildren: "yes" | "no";
}>`
  display: grid;
  grid-template-columns: ${({ haschildren }) =>
    haschildren === "yes" ? "38px 38px 1fr" : `38px 1fr`};
  place-items: center;
  text-align: left;
`;
