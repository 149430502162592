import { SubdirectoryArrowRight } from "@material-ui/icons";
import { ITEM_CONSTANTS } from "services/constants";
import styled from "styled-components";

const SubdirectoryArrow = styled(SubdirectoryArrowRight)`
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
  color: ${ITEM_CONSTANTS.SUBDIRECTORY_ARROW_COLOR};
`;

export default SubdirectoryArrow;
