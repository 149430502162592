import { Button } from "@material-ui/core";
import { HTMLProps } from "react";
import styled from "styled-components/macro";

interface Interface extends HTMLProps<HTMLAnchorElement> {
  width: number;
}

export default styled(Button).attrs({
  component: "a",
})<Interface>`
  display: block;
  padding: ${({ theme }) => `${theme.spacing(1)}px`};
  width: ${({ width }) => `${width}px`};
  min-width: 100%;
  overflow: hidden;
  &:hover {
    text-decoration: none;
  }
`;
