import styled from "styled-components/macro";
import { config, animated, useSpring } from "@react-spring/web";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { randomInt } from "services/helpers";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";

const Icon = animated(styled(CheckCircleIcon)`
  height: 20px;
  color: ${({ theme }) => theme.palette.success.main};
  cursor: default;
`);

function AllIcon() {
  const { t } = useTranslation();
  const spring = useSpring({
    from: { opacity: 0, transform: "translateX(-20px)" },
    to: { opacity: 1, transform: "translateX(0px)" },
    config: config.gentle,
    delay: randomInt(0, 300),
  });
  return (
    <Tooltip
      title={t("You have access to ALL of these sites and libraries") as string}
    >
      <Icon style={spring} />
    </Tooltip>
  );
}

export default AllIcon;
