import { TextField } from "@material-ui/core";
import { animated } from "@react-spring/web";
import styled from "styled-components/macro";

export default animated(styled(TextField).attrs({ fullWidth: true })`
  position: relative;
  width: 100%;
  display: block;
  z-index: 1;
  background: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.text.primary};
  input {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
`);
