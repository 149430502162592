import { selector } from "recoil";
import { AxiosResponse } from "axios";

import { useAPI } from "services/network";
import User from "state/auth/User";
export const endpoint = "/api/v1/SiftApi/sources";

export interface SharedDriveGroups extends Array<ISource> { }
export interface Response {
  found: boolean;
  _source: {
    groups: Array<string>;
  };
}
export interface SharedDriveSourcesResponse {
  hits: {
    hits: {
      _index: string;
      _source: ISource
    }[];
  };
}

export interface ISource {
  index_name: string;
  source: string;
}

/**
 * Fetches the shared drives groups from the sift backend
 */
export default selector<SharedDriveGroups>({
  key: "SharedDriveGroups",
  get: async ({ get }) => {
    const user = get(User);
    const { data } = await useAPI.get<undefined, AxiosResponse<any>>(endpoint)
    const indexes: ISource[] = data.hits.hits.map((x: any) => x._source)
    return indexes;
  },
});
