import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { useRecoilPreviousValue } from "services/hooks";
import AllSearch, {
  Response,
  useSearchSource,
  useSearchRefiner,
  LineOfBusinessRefiner,
} from "state/search/expertise/ExpertiseSearch";

import ExpertiseLineOfBusiness from "state/search/expertise/ExpertiseLineOfBusiness";
import SelectionDropdown from "components/PageControls/SelectionDropdown";

export default function LineOfBusiness() {
  const { t } = useTranslation();
  const expertiseLineOfBusinessState = useRecoilState(ExpertiseLineOfBusiness);
  const spoResult = useSearchSource(
    useRecoilPreviousValue(AllSearch(0), [] as Response),
    "SPOExpertise"
  );
  const options = useSearchRefiner(spoResult, LineOfBusinessRefiner)?.map(
    (result) => ({
      label: result.RefinementName,
      value: result.RefinementToken,
    })
  );
  return (
    <SelectionDropdown
      label={t("Line of Business")}
      options={options}
      state={expertiseLineOfBusinessState}
    />
  );
}
