import styled from "styled-components/macro";

const DateDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  place-items: center end;
  width: 100%;
`;

export default DateDiv