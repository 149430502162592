import styled from "styled-components/macro";

export default styled.div<{ isRoot: boolean }>`
  position: relative;
  display: grid;
  grid-auto-flow: row;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid transparent;
  &:hover {
    border: ${({ theme, isRoot }) =>
      `1px solid ${isRoot ? "transparent" : theme.palette.divider}`};
  }
`;
