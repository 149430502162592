import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import IsResizingViewport from "state/layout/IsResizingViewport";
import ViewportDimensions, {
  getWidth,
  getHeight,
} from "state/layout/ViewportDimensions";

/**
 * Save and sync viewport dimensions so that various layout
 * dimensions/components are computed correctly.
 */
function SyncViewportDimensions() {
  const setViewportDimensions = useSetRecoilState(ViewportDimensions);
  const setIsResizingViewport = useSetRecoilState(IsResizingViewport);
  useEffect(() => {
    let resetTimeout: NodeJS.Timeout;
    function onResize() {
      setIsResizingViewport(true);
      setViewportDimensions({
        width: getWidth(),
        height: getHeight(),
      });
      clearTimeout(resetTimeout);
      resetTimeout = setTimeout(() => setIsResizingViewport(false), 100);
    }
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, [setViewportDimensions, setIsResizingViewport]);
  return null;
}

export default SyncViewportDimensions;
