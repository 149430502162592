import styled from "styled-components/macro";

export default styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  & + & {
    margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
  }
`;
