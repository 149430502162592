import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { useHasQuery } from "state/Query";
import ImageSearch, { useRefiners } from "state/search/image/ImageSearch";
import ImageSearchPage from "state/search/image/ImageSearchPage";

import PageContainer from "components/PageContainer";
import HeaderBar from "components/HeaderBar";
import PageControls from "components/PageControls";
import PleaseEnterQuery from "components/PleaseEnterQuery";
import LoadingDisplay from "components/LoadingDisplay";

import ResultsCount from "./controls/ResultsCount";
import DateRange from "./controls/DateRange";
import FileType from "./controls/FileType";
import Export from "./controls/Export";
import Sources from "./controls/Sources";

import Container from "./Container";
import Images from "./Images";
import LoadMore from "./LoadMore";
import ReportData from "components/ReportData";
import Refiners from "components/Refiners";

// The page path relative to the application root
const path = "/images";

function ImagesPage() {
  const { t } = useTranslation();
  const hasQuery = useHasQuery();
  const firstPageLoadable = useRecoilValueLoadable(ImageSearch(0));
  const imageSearchPage = useRecoilValue(ImageSearchPage);
  const pageList = imageSearchPage ? [...Array(imageSearchPage).keys()] : [0];
  const hasMorePages = useMemo(() => {
    if (firstPageLoadable.state !== "hasValue") {
      return false;
    } else {
      const spoImageResult = firstPageLoadable.contents;
      if (spoImageResult) {
        const currentRow = imageSearchPage * spoImageResult.rowCount;
        return currentRow < spoImageResult.totalRows;
      }
    }
    return false;
  }, [imageSearchPage, firstPageLoadable]);

  if (!hasQuery) {
    return <PleaseEnterQuery />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("Images")} - {t("APP_NAME")}
        </title>
      </Helmet>
      <HeaderBar>
        <PageControls
          left={[<ResultsCount />]}
          // center={[<DateRange />, <FileType />, <Export />, <ReportData />, 
          // <Refiners isLoading={ firstPageLoadable.state === "loading" } spoGroup={"Image"} useSharePointRefiners={useRefiners} />]}
          center={[<DateRange />, <FileType />, <Export />, <ReportData />]}
          right={[<Sources />]}
        />
      </HeaderBar>
      <PageContainer>
        {firstPageLoadable.state !== "loading" ? (
          <>
            <Container>
              {pageList.map((page) => (
                <Images page={page} key={page} />
              ))}
            </Container>
            {hasMorePages && <LoadMore />}
          </>
        ) : (
          <LoadingDisplay style={{ minHeight: "200px" }} />
        )}
      </PageContainer>
    </>
  );
}

export { path };
export default ImagesPage;
