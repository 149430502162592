import styled from "styled-components/macro";
import Paper from "@material-ui/core/Paper";
import { themeBorder } from "services/helpers";

export default styled(({ active, ...props }) => <Paper {...props} />).attrs({
  elevation: 0,
})<{ active: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;
  ${themeBorder}
  cursor: pointer;
`;
