import { atom } from "recoil";

export interface ViewportDimensions {
  width: number;
  height: number;
}

export function getWidth() {
  return Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
}

export function getHeight() {
  return Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
}

/**
 * Stores the viewport dimensions
 */
export default atom<ViewportDimensions>({
  key: "ViewportDimensions",
  default: {
    width: getWidth(),
    height: getHeight(),
  },
});
