import { Response } from "state/search/all/AllSearch";

const documents: Response = [
  {
    source: "SPO",
    rowCount: 1,
    totalRows: 1,
    totalRowsIncludingDuplicates: 1,
    refinerResults: [
      {
        entries: [
          {
            RefinementCount: 0,
            RefinementName: "Older than a year",
            RefinementToken: "range(min, 2020-08-27T00:00:00.0000000Z)",
            RefinementValue: "Before 2020-08-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 1,
            RefinementName: "Past Year",
            RefinementToken:
              "range(2020-08-27T00:00:00.0000000Z, 2021-07-27T00:00:00.0000000Z)",
            RefinementValue:
              "From 2020-08-27T00:00:00.0000000Z up to 2021-07-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Month",
            RefinementToken:
              "range(2021-07-27T00:00:00.0000000Z, 2021-08-26T00:00:00.0000000Z)",
            RefinementValue:
              "From 2021-07-27T00:00:00.0000000Z up to 2021-08-26T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past 24 hours",
            RefinementToken:
              'range(2021-08-26T00:00:00.0000000Z, max, to="le")',
            RefinementValue: "2021-08-26T00:00:00.0000000Z or later",
          },
        ],
        name: "Write",
      },
      {
        entries: [
          {
            RefinementCount: 1,
            RefinementName: "xlsx",
            RefinementToken: '"ǂǂ786c7378"',
            RefinementValue: "xlsx",
          },
        ],
        name: "FileType",
      },
    ],
    results: [
      {
        docId: 991203535879395620,
        dlcDocId: "AAFAA4420-217905067-17045",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa4420",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA4420",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAFAA4420/_layouts/15/Doc.aspx?sourcedoc={207c1aa9-ac8c-4a20-af1b-c353103ec365}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "N.Frewin@shell.com;Baksmaty, Leslie O SIEP-PTX/S/UQ",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aafaa4420",
        siteTitle: "Subsurface Technology Collaboration Area",
        people:
          "Leslie.Baksmaty@shell.com | Baksmaty, Leslie O SIEP-PTX/S/UQ | 693A30232E667C6D656D626572736869707C6C65736C69652E62616B736D617479407368656C6C2E636F6D i:0#.f|membership|leslie.baksmaty@shell.com;N.Frewin@shell.com;Baksmaty, Leslie O SIEP-PTX/S/UQ",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAFAA4420/_layouts/15/Doc.aspx?sourcedoc={207c1aa9-ac8c-4a20-af1b-c353103ec365}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA4420/Subsurface Interpretation Technology/Dauphin Sinan",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.xlsx.svg",
        title: "2021_04_07_Dauphane Runway_PPT",
        path: "https://eu001-sp.shell.com/sites/AAFAA4420/Subsurface Interpretation Technology/Dauphin Sinan/2021_04_07_Dauphane Runway_PPT.xlsx",
        summary:
          "1 Mozambique Angoche <strong>Basin</strong> Cretaceous-Tertiary DW <ddd/> 1 2 1 1 US GOM - Shadow <strong>Basin</strong> OIL Venture  2 3 1 1 <ddd/> ...",
        resultType: "xlsx",
        size: 197344,
        write: "2021-05-03T17:31:29.0000000Z",
        rank: 0.0,
      },
    ],
    spellingSuggestion: "",
  },
];

export default documents;
