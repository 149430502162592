import styled from "styled-components/macro";

export default styled.div`
  max-height: 300px;
  overflow: auto;
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  border-top: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  background: rgba(0, 0, 0, 0.04);
`;
