import { config, useSpring } from "@react-spring/web";
import LoadingDisplay from "components/LoadingDisplay";
import Container from "./Container";

export default function Loading() {
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { ...config.gentle, clamp: true },
    delay: 300,
  });

  return (
    <Container style={spring}>
      <LoadingDisplay />
    </Container>
  );
}
