import { Typography } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled.div`
  height: 160px;
  display: grid;
  place-items: center;
`;

const Message = styled(Typography)`
  font-size: 13px;
  font-weight: bold;
  cursor: default;
`;

function ErrorDisplay({
  children,
  onClick,
}: {
  children: string;
  onClick: () => void;
}) {
  return (
    <Container onClick={onClick}>
      <Message color="textSecondary">{children}</Message>
    </Container>
  );
}

export default ErrorDisplay;
