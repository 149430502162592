import { red } from "@material-ui/core/colors";

/**
 * The deployment process uses window.ENV while local builds access
 * environment variables via process.env
 *
 * This module abstracts that difference out.
 */

const windowENV = (window as any).ENV;
const env = Boolean(windowENV) ? windowENV : process.env;

const isLocalEnvironment = env.REACT_APP_ENVIRONMENT === "local";
const isDevEnvironment = env.REACT_APP_ENVIRONMENT === "dev";
const isStagingEnvironment = env.REACT_APP_ENVIRONMENT === "staging";
const isForcingMock = Boolean(env.REACT_APP_FORCE_MOCK);

if (isLocalEnvironment) {
  // Print out a welcome message in the local environment
  console.log(
    `%cUnified Search Engine%c\n%cTeam PHO 🍜`,
    `background: ${red[600]}; color: white; padding: 10px 15px; border-radius: 3px 3px 3px 0; font-size: 16px; font-weight: bold; margin-top: 20px; border-bottom: 1px solid ${red[900]}; border-top: 1px solid ${red[300]};`,
    ``,
    `background: ${red[700]}; color: white; padding: 5px 15px; border-radius: 0 0 3px 3px; margin-bottom: 20px; border-bottom: 1px solid black;`
  );
  console.table(
    Object.keys(env)
      .filter((key) => !key.match(/^WDS/))
      .reduce((obj: any, key: any) => {
        obj[key] = env[key];
        return obj;
      }, {})
  );
}

export default env;
export {
  isLocalEnvironment,
  isDevEnvironment,
  isStagingEnvironment,
  isForcingMock,
};
