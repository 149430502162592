import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { SourceNode } from "state/sources/SourceTree";
import Label from "./Label";
import SiteAccess from "./SiteAccess";

function SourceLabel({ node, children, ...props }: any) {
  const source: SourceNode = node;
  const isSite = !node.libraryUrl;
  return (
    <Label {...props}>
      <span>{children}</span>
      {isSite && (
        <ErrorBoundary FallbackComponent={() => null}>
          <Suspense fallback={null}>
            <SiteAccess node={source} />
          </Suspense>
        </ErrorBoundary>
      )}
    </Label>
  );
}

export default SourceLabel;
