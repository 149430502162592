import styled, {
  css,
  FlattenSimpleInterpolation,
} from "styled-components/macro";
import PageContainer from "components/PageContainer";
import { Layout } from "state/search/expertise/ExpertiseLayout";

const layoutStyle: { [key: string]: FlattenSimpleInterpolation } = {
  column: css`
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "results top-projects";
  `,
  row: css`
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "top-projects"
      "results";
  `,
};

export default styled(PageContainer)<{ layout: Layout }>`
  display: grid;
  grid-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  ${({ layout }) => layoutStyle[layout]}
`;
