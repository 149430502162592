import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { config, useSprings } from "@react-spring/web";
import { Divider } from "@material-ui/core";

import ExpertisePersonProjectDetail from "state/search/expertise/ExpertisePersonProjectDetail";
import { Project } from "state/search/expertise/ExpertisePersonProjects";

import Container from "./Container";
import ProjectDisplay from "./ProjectDisplay";
import Message from "./Message";

export default function MoreInfo({
  project,
  userAlias,
}: {
  project: Project;
  userAlias: string;
}) {
  const { t } = useTranslation();
  const projectDetails = useRecoilValue(
    ExpertisePersonProjectDetail({
      alias: userAlias,
      projectName: project.ProjectName as string,
    })
  );
  const springs = useSprings(
    projectDetails.length,
    projectDetails.map((project, index) => ({
      config: { ...config.gentle, clamp: true },
      from: { opacity: 0 },
      to: { opacity: 1 },
      delay: index * 20,
    }))
  );
  return (
    <>
      <Divider />
      <Container>
        {projectDetails.length ? (
          springs.map((style, index) => (
            <ProjectDisplay
              project={projectDetails[index]}
              style={style}
              key={index}
            />
          ))
        ) : (
          <Message>{t("No details found")}</Message>
        )}
      </Container>
    </>
  );
}
