import { useTrail, animated } from "@react-spring/web";
import { useRef, useEffect } from "react";

import { useRecoilValueOr } from "services/hooks";
import AllSearch, { useSearchSource } from "state/search/all/AllSearch";

import Container from "./Container";
import Result from "./Result";
import NoResults from "./NoResults";

function ResultsPage({ page }: { page: number }) {
  const allSearch = useRecoilValueOr(AllSearch(page), null);
  const spoResult = useSearchSource(allSearch || [], "SPO");
  const previousResult = useRef(spoResult);
  useEffect(() => {
    previousResult.current = spoResult;
  }, [spoResult]);
  const newResult = previousResult.current !== spoResult;

  const trail = useTrail(spoResult?.results.length || 0, {
    config: { duration: 100 },
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: newResult,
  });

  return spoResult && spoResult.rowCount ? (
    <Container>
      {trail.map((style, index) => (
        <animated.div key={index} style={style}>
          <Result result={spoResult.results[index]} />
        </animated.div>
      ))}
    </Container>
  ) : (
    <NoResults />
  );
}

export default ResultsPage;
