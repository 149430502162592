import { useRecoilValue } from "recoil";

import PageControlsBottom from "state/layout/PageControlsBottom";
import ExternalResultDisplayWidth from "state/search/external/ExternalResultDisplayWidth";
import { Record } from "state/search/external/ExternalSearch";

import { getInfo, highlight } from "..";

import Paper from "./Paper";
import Title from "./Title";
import Abstract from "./Abstract";

export interface DetailDisplayInterface {
  result: Record;
}

export type Placement = "default" | "top" | "bottom";

export default function DetailDisplay(props: DetailDisplayInterface) {
  const { result } = props;
  const pageControlsBottom = useRecoilValue(PageControlsBottom);
  const resultDisplayWidth = useRecoilValue(ExternalResultDisplayWidth);

  return (
    <Paper
      placement="top"
      width={resultDisplayWidth}
      topOffset={pageControlsBottom}
    >
      <Title
        href={result.pLink}
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{
          __html: highlight(getInfo(result, "Title")),
        }}
      />
      <Abstract
        dangerouslySetInnerHTML={{
          __html: highlight(getInfo(result, "Abstract")),
        }}
      />
    </Paper>
  );
}
