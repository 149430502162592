import { createTheme } from "@material-ui/core/styles";
import baseTheme, { palette } from "./baseTheme";

export default createTheme({
  ...baseTheme,
  palette: {
    ...palette,
    type: "light",
    header: "#D5D5D5",
    headerText: "#515C6C",
    background: {
      default: "#e9e9e9",
      paper: "#F2F2F2",
    },
  },
});
