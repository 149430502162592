import { useMemo } from "react";
import { GetRecoilValue, selectorFamily } from "recoil";
import { AxiosResponse } from "axios";
import Query from "state/Query";
import DateRange from "state/DateRange";
import FileType from "state/FileType";
import { useRecoilPreviousValue } from "services/hooks";
import { useAPI } from "services/network";

import { format } from "date-fns";
import { RefinerEntry } from "../all/AllSearch";
import SharedDrivesServerQuery from "./SharedDrivesServerQuery";
import AppliedSharedDrives from "./AppliedSharedDrives";

export const endpoint = "/api/v1/SiftApi/search";
export const spoGroup = "shared-drives";

export type SourceEntryKey = keyof SourceEntry;
export interface SourceEntry {
  "es-entity": string;
  "file-accessed_date": string;
  "file-modified_date": string;
  "file-size": number;
  hash: string;
  index: string;
  "meta-entity_date": number;
  "meta-source": string;
  "file-content": string;
  "file-content_hash": string;
  "file-author": string;
  "file-created_date": string;
  "file-display_path": string;
  "file-name": string;
  "file-extension": string;
  "file-path"?: string;
  "file-readable_extension": string;
  "tax-geoscience_taxonomy": Array<{
    level: number;
    value: string;
    ancestors: Array<string>;
  }>;
  keyword_organization?: Array<string>;
  "meta-url": string;
  "cds_wellbore_document-description": string;
  "cds_wellbore_document-identifier": string;
  "cds_wellbore-uwbi": string;
  "cds_wellbore-wellbore_name": string;
  "cds_wellbore_document-report_file_format": string;
  "cds_wellbore-uwi": string;
  "cds_wellbore-common_name": string;
  "cds_wellbore-current_operator": string;
  "cds_wellbore-original_operator": string;
  "cds_wellbore-current_status": string;
  "cds_wellbore-spud_date": string;
  "cds_wellbore-completed_date"?: string;
  "cds_wellbore-water_depth"?: number;
  "cds_wellbore-total_measured_depth": number;
  "cds_wellbore-true_vertical_depth"?: number;
  "cds_wellbore-location": {
    lat: number;
    lon: number;
  };
  "cds_wellbore-initial_purpose": string;
  "cds_wellbore-field_id"?: string;
  "es-acquisition_time": string;
}

export interface Hit {
  _index: string;
  _type: string;
  _id: string;
  _score: number;
  _source: SourceEntry;
  highlight?: {
    "file-content": Array<string>;
  };
}

export interface ISearch {
  searchText: string;
  pageNumber: number;
  pageSize: number;
  startDate?: string;
  endDate?: string;
  fileType?: string;
  selectedSharedDriveSources?: string[];
}

export interface Request {
  query: any;
}
export interface Response {
  took: number;
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
  };
  hits: {
    total: {value: number};
    max_score: number;
    hits: Array<Hit>;
  };
  aggregations: {
    filetype: {
      doc_count_error_upper_bound: number;
      sum_other_doc_count: number;
      buckets: Array<{
        key: string;
        doc_count: number;
      }>;
    };
    write: {
      buckets: Array<{
        key: string;
        to: number;
        to_as_string: string;
        doc_count: number;
        from?: number;
        from_as_string?: string;
      }>;
    };
  };
}

export function issueRequest(endpoint: string, siftQuery: string) {
  return useAPI.post<Request, AxiosResponse<Response>>(endpoint, siftQuery, {
    headers: {
      "Content-Type": "application/json; charst=UTF-8",
    },
  });
}

export function serverSeachRequest(endpoint: string, params: ISearch) {
  return useAPI.post<Request, AxiosResponse<Response>>(endpoint, params, {
    headers: {
      "Content-Type": "application/json; charst=UTF-8",
    },
  });
}

export function getServerParams(get: GetRecoilValue, searchText: string, pageNumber: number, pageSize: number) {
  const payload: ISearch = {
    searchText: searchText,
    pageNumber: pageNumber,
    pageSize: pageSize
  }

  const dateRange = get(DateRange(spoGroup));
  const fileType = get(FileType(spoGroup));

  if (dateRange && dateRange.length) {
    payload.startDate = format(dateRange[0].startDate, "yyyy-MM-dd")
    payload.endDate = format(dateRange[0].endDate, "yyyy-MM-dd")
  }

  fileType?.value && (payload.fileType = fileType.value)
  return payload;
}

let totalResultCount: number;

/**
 * Fetches the shared drives search results from the backend
 */
const SharedDrives = selectorFamily({
  key: "SharedDrives",
  get:
    (page: number) =>
      async ({ get }) => {
        const serverQuery = {...get(SharedDrivesServerQuery)};
        const maxPageSize = 20;

        serverQuery.pageSize = maxPageSize
        serverQuery.pageNumber = page
        serverQuery.selectedSharedDriveSources = Array.from(get(AppliedSharedDrives))
        .map(s=> s.text );

        const { data: response } = await serverSeachRequest(endpoint, serverQuery);
        const castResponse = response;
        totalResultCount = response.hits.total.value;
        return castResponse;
      },
});

export interface ExtensionMap {
  [key: string]: string;
}

export const keyExtension: ExtensionMap = {
  "PDF Documents": "pdf",
  "Excel Spreadsheets": "xls",
  "Word Documents": "doc",
  "Text Files": "txt",
  "Powerpoint Presentations": "ppt",
  "Emails": "msg",
  "Unknown": "others",
  "XML Documents": "xml"
};

/**
 * Returns the list of file extensions found in page 0
 * @returns
 */
export function useFileTypeEntries(): Array<RefinerEntry> | null {
  const sharedDrives = useRecoilPreviousValue(SharedDrives(0), null);
  return useMemo(() => {
    const refiner = sharedDrives?.aggregations.filetype.buckets.map(
      (fileType) => ({
        RefinementCount: fileType.doc_count,
        RefinementName: keyExtension[fileType.key],
        RefinementToken: fileType.key,
        RefinementValue: fileType.key,
      })
    );
    return refiner ? refiner : null;
  }, [sharedDrives]);
}

export default SharedDrives;
