import { selector } from "recoil";
import JWTDecode from "jwt-decode";

import env from "services/env";
import { testFlag } from "services/testing";
import Token, { Token as IToken } from "state/auth/Token";

import TestLoginUser from "./TestLoginUser";

export interface User {
  name: string;
  unique_name: string;
  family_name: string;
  given_name: string;
  ipaddr: string;
  tid: string;
}

export default selector<User>({
  key: "User",
  get: async ({ get }) => {
    const shouldSkip =
      testFlag("skipLogin") || get(TestLoginUser) || env.REACT_APP_SKIP_LOGIN;
    if (shouldSkip) {
      return {
        name: "testuser",
        unique_name: "testuser@shell.com",
        family_name: "User",
        given_name: "Test",
        ipaddr: "0.0.0.0",
        tid: "00000000-0000-0000-0000-000000000000",
      };
    }
    const token = (await get(Token)) as IToken;
    const user = JWTDecode(token.access_token as string);
    return user as User;
  },
});
