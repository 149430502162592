import { animated } from "@react-spring/web";
import styled, { css } from "styled-components/macro";

interface ContainerProps {
  open: boolean;
  border: boolean;
}

const borderedStyle = css<ContainerProps>`
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border: ${({ theme, open }) =>
    `1px solid ${open ? theme.palette.primary.main : theme.palette.divider}`};
`;

export default animated(styled.div<ContainerProps>`
  position: relative;
  padding: ${({ theme }) =>
    `0 ${theme.spacing(1.25)}px 0 ${theme.spacing(2)}px`};
  ${({ border }) => border && borderedStyle}
  cursor: pointer;
  &:hover {
    border-color: ${({ theme, open }) =>
      open ? theme.palette.primary.main : theme.palette.text.primary};
  }
`);
