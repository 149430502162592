import { selector } from "recoil";
import ExpertiseBusiness from "./ExpertiseBusiness";
import ExpertiseLineOfBusiness from "./ExpertiseLineOfBusiness";

/**
 * The refiner/filter that is used during the ExpertiseSearch request
 */
export default selector<string>({
  key: "ExpertiseSearchRefiner",
  get: ({ get }) => {
    let refiner = "";
    const businessLine = get(ExpertiseLineOfBusiness);
    const business = get(ExpertiseBusiness);
    const refiners = [];

    if (businessLine) {
      refiners.push(
        `refinablestring100:${(businessLine.value as string).replaceAll(
          '"',
          ""
        )}`
      );
    }
    if (business) {
      refiners.push(
        `refinablestring101:${(business.value as string).replaceAll('"', "")}`
      );
    }

    if (refiners.length === 1) {
      refiner = refiners[0];
    } else if (refiners.length > 1) {
      refiner = `and(${refiners.join(",")})`;
    }

    return refiner;
  },
});
