import styled from "styled-components/macro";

export default styled.div`
  grid-area: dates;
  display: grid;
  grid-template-rows: 1fr 1fr;
  border-left: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing(1)}px`};
`;
