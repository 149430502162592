import { ReactNode } from "react";
import Value from "./Value";

export default function Property({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) {
  return (
    <div>
      <div>{label}</div>
      <Value>{value}</Value>
    </div>
  );
}
