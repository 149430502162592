import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import SelectionDropdown from "components/PageControls/SelectionDropdown";
import ExpertiseSortBy, {
  ExpertiseSortBy as IExpertiseSortBy,
} from "state/search/expertise/ExpertiseSortBy";

export const options: Array<IExpertiseSortBy> = [
  {
    direction: "0",
    property: "FirstName",
    label: "First Name",
  },
  {
    direction: "0",
    property: "LastName",
    label: "Last Name",
  },
  {
    direction: "1",
    property: "EFCurrentProjectStartDate",
    label: "Project Start Date - Recent",
  },
  {
    direction: "0",
    property: "EFCurrentProjectStartDate",
    label: "Project Start Date - Oldest",
  },
];

function SortBy() {
  const { t } = useTranslation();
  const sortByState = useRecoilState(ExpertiseSortBy);
  const [sortBy] = sortByState;
  return (
    <SelectionDropdown
      label={sortBy ? t("Sort") : t("Sort By")}
      options={options}
      state={sortByState as any}
    />
  );
}

export default SortBy;
