import { atom } from "recoil";

/**
 * When set to true the user is auto-logged in as
 * a test user
 */
export default atom({
  key: "TestLoginUser",
  default: false,
});
