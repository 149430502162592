import project1 from "./project1";
import project2 from "./project2";
import project3 from "./project3";
import project4 from "./project4";
import project5 from "./project5";
import project6 from "./project6";
import project7 from "./project7";
import project8 from "./project8";
import project9 from "./project9";

const projectDetails = [
  project1,
  project2,
  project3,
  project4,
  project5,
  project6,
  project7,
  project8,
  project9,
];

export default projectDetails;
