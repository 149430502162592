import styled from "styled-components/macro";

export default styled.div`
  text-align: right;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  place-items: center end;
  grid-template-areas: "check submit";
`;
