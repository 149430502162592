import { atom } from "recoil";

export type ThemeChoice = "light" | "dark";

/**
 * Stores the selected theme
 */
export default atom<ThemeChoice>({
  key: "SelectedTheme",
  default: (localStorage.getItem("theme") as ThemeChoice) || "light",
});
