import { Typography } from "@material-ui/core";
import chroma from "chroma-js";
import { HTMLProps } from "react";
import styled from "styled-components/macro";

export default styled(Typography).attrs({ component: "a" })<
  HTMLProps<HTMLAnchorElement>
>`
  font-size: 12px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: ${({ theme }) => theme.palette.path};
  &:visited {
    color: ${({ theme }) => chroma(theme.palette.path).darken(0.75).css()};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.path};
    text-decoration: underline;
  }
`;
