import { animated } from "@react-spring/web";
import styled from "styled-components/macro";

export default animated(styled.div`
  max-width: 300px;
  width: 100%;
  height: 36px;
  position: relative;
  z-index: 1000;
  position: absolute;
  top: 100%;
  margin-top: ${({ theme }) => `${theme.spacing(5)}px`};
`);
