import { ButtonBase } from "@material-ui/core";
import styled from "styled-components/macro";

export default styled(ButtonBase).attrs({ component: "div" })`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  grid-template-areas: "user info";
  width: 100%;
  place-self: start;
`;
