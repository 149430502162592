import { useTranslation } from "react-i18next";
import { useCopyToClipboard } from "react-use";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";

import Container from "./Container";
import Label from "./Label";
import Path from "./Path";

export default function CopyPath({ copyUrl, children }: { copyUrl: string, children: string }) {
  const { t } = useTranslation();
  const [copyState, copyToClipboard] = useCopyToClipboard();
  function copyPath() {
    copyToClipboard(copyUrl);
  }

  let label = t("Copy Path");
  let state: "ready" | "error" | "copied" = "ready";
  if (copyState.error) {
    state = "error";
    label = t("Copy Error");
  } else if (copyState.value) {
    state = "copied";
    label = t("Copied!");
  }

  return (
    <Container
      title={`${t("Copy Path")}: ${copyUrl}`}
      state={state}
      onClick={copyPath}
    >
      {state === "copied" ? (
        <CheckIcon fontSize="small" />
      ) : (
        <FileCopyIcon fontSize="small" />
      )}
      <Label>{label}</Label>
      <Path>{children}</Path>
    </Container>
  );
}
