import { atomFamily } from "recoil";
import { addDays } from "date-fns";

export interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

/**
 * Stores a date range
 */
export default atomFamily({
  key: "DateRange",
  default: (key: string): [DateRange] | null =>
    key.match(/-selected$/)
      ? [
          {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: "selection",
          },
        ]
      : null,
});
