import { REFINER_BANNER_CONSTANTS } from "services/constants";
import styled from "styled-components";

const SearchText = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${REFINER_BANNER_CONSTANTS.SKY_DEFAULT};
  font-weight: 400;
  margin-right: 4px;
`;

export default SearchText;
