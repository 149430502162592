import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Divider, Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import EmailIcon from "@material-ui/icons/Email";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { Result } from "state/search/expertise/ExpertiseSearch";
import { useGetPath } from "services/hooks";
import { path as ExpertisePath } from "pages/ExpertisePage";

import Pill from "pages/ExpertisePage/SearchResults/Results/ResultsPage/Result/Pill";
import PillLink from "./PillLink";
import Content from "./Content";
import TitleRow from "./TitleRow";
import ContactRow from "./ContactRow";
import Tags from "./Tags";
import Link from "./Link";
import JobTitle from "./JobTitle";

export default function Info({
  person,
  active,
}: {
  person: Partial<Result>;
  active: "true" | "false";
}) {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const getPath = useGetPath();
  const lineOfBusiness = `${person.lineOfBusiness} (${person.lineOfBusinessCode})`;
  function stopProp(event: MouseEvent) {
    event.stopPropagation();
  }
  return (
    <Content>
      <TitleRow>
        <Link
          href={person.path || ""}
          target="_blank"
          rel="noopener noreferrer"
          title={person.preferredName || ""}
          onClick={stopProp}
          textColor={theme.palette.getContrastText(
            theme.palette.background.default
          )}
        >
          {person.preferredName}
        </Link>
        {active === "true" ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </TitleRow>
      <ContactRow>
        <Link
          href={`mailto:${person.workEmail}`}
          title={person.workEmail || ""}
          target="_blank"
          rel="noopener noreferrer"
          onClick={stopProp}
        >
          {person.workEmail}
        </Link>
        <Divider orientation="vertical" flexItem />
        <IconButton
          aria-label="email"
          size="small"
          component="a"
          href={`mailto:${person.workEmail}`}
          onClick={stopProp}
          target="_blank"
          rel="noopener noreferrer"
          title={person.workEmail || ""}
        >
          <EmailIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="chat"
          size="small"
          component="a"
          href={`sip:${person.sipAddress}`}
          onClick={stopProp}
          target="_blank"
          rel="noopener noreferrer"
          title={t("Start chat")}
        >
          <ChatBubbleIcon fontSize="inherit" />
        </IconButton>
      </ContactRow>
      <JobTitle>{person.jobTitle || ""}</JobTitle>
      <Tags>
        <PillLink
          to={getPath(ExpertisePath, {
            query: `Business:"${person.business}"`,
          })}
        >
          <Pill title={person.business || ""}>{person.business}</Pill>
        </PillLink>

        <PillLink
          to={getPath(ExpertisePath, {
            query: `LineOfBusiness:"${lineOfBusiness}"`,
          })}
        >
          <Pill title={lineOfBusiness || ""}>{lineOfBusiness}</Pill>
        </PillLink>
      </Tags>
    </Content>
  );
}
