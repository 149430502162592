interface TestOptions {
  skipLogin?: boolean;
  fakeLogin?: boolean;
  mockApi?: boolean;
  apiDelay?: number;
}

const testOptionsStorageKey = "testOptions";

function hasTestOptions(): boolean {
  return Boolean(localStorage.getItem(testOptionsStorageKey));
}

function getTestOptions(): TestOptions {
  const testOptions = localStorage.getItem(testOptionsStorageKey);
  return testOptions ? JSON.parse(testOptions) : {};
}

function hasTestOption(key: keyof TestOptions): boolean {
  return key in getTestOptions();
}

function getTestOption(key: keyof TestOptions) {
  return getTestOptions()[key];
}

/**
 * Returns boolean indicating if the provided key exists, is truthy, and
 * the application is currently being tested
 * @param key The test option/flag to check for
 */
function testFlag(key: keyof TestOptions) {
  return Boolean(hasTestOptions() && getTestOption(key));
}

function getOtherResponseDelay(endpoint: string | string[]): number | null {
  const otherEndpointDelays = localStorage.getItem("delay:others");
  if (otherEndpointDelays) {
    const { delay, endpoint: exclude } = JSON.parse(otherEndpointDelays) as any;
    if (Array.isArray(exclude) && !exclude.includes(endpoint)) {
      return delay;
    } else if (endpoint !== exclude) {
      return delay;
    }
  }
  return null;
}

function getTestResponse(endpoint: string): any {
  const testResponse = localStorage.getItem(`response:${endpoint}`);
  return testResponse ? JSON.parse(testResponse) : null;
}

/**
 * Return the test apiDelay value, or the delay provided if test value not present
 * @param endpoint The endpoint to return the delay for
 * @param delay The delay returned if it a testing value isn't there
 */
function apiDelay(endpoint: string, delay: number): number {
  const testResponse = getTestResponse(endpoint);
  const otherResponseDelay = getOtherResponseDelay(endpoint);
  const testDelay = hasTestOption("apiDelay")
    ? (getTestOption("apiDelay") as number)
    : delay;

  if (testResponse) {
    // the endpoint has an explicit test override
    return testResponse.delay ?? testDelay;
  }
  return otherResponseDelay ?? testDelay;
}

/**
 * Returns the test/overriden endpoint status/response code if one was supplied
 * @param endpoint The endpoint to return the status for
 * @param code The response code to return
 */
function apiStatus(endpoint: string, code: number) {
  const testResponse = getTestResponse(endpoint);
  return testResponse ? testResponse.code ?? code : code;
}

/**
 * Returns the test/overriden endpoint response body if one was supplied
 * @param endpoint The endpoint to return the response for
 * @param response The response body to return
 */
function apiResponse(endpoint: string, response: any) {
  const testResponse = getTestResponse(endpoint);
  return testResponse ? testResponse.data ?? response : response;
}

export {
  testOptionsStorageKey,
  getTestOption,
  testFlag,
  apiDelay,
  apiStatus,
  apiResponse,
};
