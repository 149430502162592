import { Response } from "state/search/expertise/ExpertisePersonProjects";

const documents: Response = [
  {
    UserAlias: null,
    ProjectName: "Dourado",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2016-02-01T07:54:38.291091",
    ModifiedDate: "2017-01-13T07:32:47.3976",
  },
  {
    UserAlias: null,
    ProjectName: "COL_Master",
    ProjectSource: "nDI",
    ProjectCount: 37,
    DataPath: null,
    DataType: null,
    CreatedDate: "2015-01-13T17:33:50.826982",
    ModifiedDate: "2015-07-27T16:10:57.884278",
  },
  {
    UserAlias: null,
    ProjectName: "Work_Basic_v02",
    ProjectSource: "nDI",
    ProjectCount: 10,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-09-26T11:29:41.21177",
    ModifiedDate: "2014-10-23T11:40:08.492304",
  },
  {
    UserAlias: null,
    ProjectName: "Uruguay",
    ProjectSource: "nDI",
    ProjectCount: 20,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-09-16T14:05:06.29365",
    ModifiedDate: "2014-09-16T14:15:23.724479",
  },
  {
    UserAlias: null,
    ProjectName: "New Project",
    ProjectSource: "nDI",
    ProjectCount: 17,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-04-23T15:05:53.757203",
    ModifiedDate: "2014-09-16T14:05:54.364466",
  },
  {
    UserAlias: null,
    ProjectName: "Work_Basic",
    ProjectSource: "nDI",
    ProjectCount: 9,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-01-24T17:03:17.060397",
    ModifiedDate: "2014-01-24T17:03:40.90024",
  },
  {
    UserAlias: null,
    ProjectName: "Work_Basic_2D",
    ProjectSource: "nDI",
    ProjectCount: 8,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-01-24T16:56:04.749189",
    ModifiedDate: "2014-01-24T17:02:38.264756",
  },
  {
    UserAlias: null,
    ProjectName: "URG_ASSET_WORK",
    ProjectSource: "nDI",
    ProjectCount: 9,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-12-20T18:41:02.848027",
    ModifiedDate: "2014-01-22T14:11:45.757052",
  },
  {
    UserAlias: null,
    ProjectName: "Legacy_Data_From_John_Mulders_USJMU8",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-11-25T14:40:21.857502",
    ModifiedDate: "2013-11-25T14:41:12.239277",
  },
  {
    UserAlias: null,
    ProjectName: "Col_SRM_Polygons",
    ProjectSource: "nDI",
    ProjectCount: 4,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-09-12T16:47:04.377321",
    ModifiedDate: "2013-12-12T19:45:36.660937",
  },
  {
    UserAlias: null,
    ProjectName: "Col_SRM_Interpretation",
    ProjectSource: "nDI",
    ProjectCount: 5,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-09-12T16:46:06.559932",
    ModifiedDate: "2013-12-12T20:05:54.888199",
  },
  {
    UserAlias: null,
    ProjectName: "BC10_UK_Regional",
    ProjectSource: "nDI",
    ProjectCount: 15,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-04-02T08:14:52.930073",
    ModifiedDate: "2013-04-02T08:20:12.452386",
  },
  {
    UserAlias: null,
    ProjectName: "Gnome_Int_Final",
    ProjectSource: "nDI",
    ProjectCount: 13,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-12-05T12:34:46",
    ModifiedDate: "2012-12-05T14:18:41",
  },
  {
    UserAlias: null,
    ProjectName: "Top_K_Int",
    ProjectSource: "nDI",
    ProjectCount: 4,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-10-11T14:22:48",
    ModifiedDate: "2012-10-11T14:24:35",
  },
  {
    UserAlias: null,
    ProjectName: "Manganga_Int",
    ProjectSource: "nDI",
    ProjectCount: 2,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-10-11T14:21:09",
    ModifiedDate: "2012-10-11T14:25:55",
  },
  {
    UserAlias: null,
    ProjectName: "Jubarte_Int",
    ProjectSource: "nDI",
    ProjectCount: 3,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-10-11T14:20:54",
    ModifiedDate: "2012-10-11T14:26:42",
  },
  {
    UserAlias: null,
    ProjectName: "3D_Merged",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-10-11T14:10:23",
    ModifiedDate: "2013-07-01T13:43:08.3408",
  },
  {
    UserAlias: null,
    ProjectName: "Massa_Int",
    ProjectSource: "nDI",
    ProjectCount: 10,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-10-11T13:54:41",
    ModifiedDate: "2012-10-11T14:28:51",
  },
  {
    UserAlias: null,
    ProjectName: "3D_PSDM_Seismic_Depth_12x12",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-10-11T13:08:41",
    ModifiedDate: "2012-10-11T14:18:49",
  },
  {
    UserAlias: null,
    ProjectName: "Gnome_Int",
    ProjectSource: "nDI",
    ProjectCount: 15,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-06-14T11:17:07",
    ModifiedDate: "2012-12-05T12:35:20",
  },
  {
    UserAlias: null,
    ProjectName: "3D_PSDM_Seismic_Time_12x12",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-06-14T11:16:09",
    ModifiedDate: "2012-10-11T14:18:52",
  },
  {
    UserAlias: null,
    ProjectName: "3D_PSDM_Seismic_Time_25x25",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-05-15T11:25:53",
    ModifiedDate: "2012-10-11T14:19:18",
  },
  {
    UserAlias: null,
    ProjectName: "Regional_Int",
    ProjectSource: "nDI",
    ProjectCount: 21,
    DataPath: null,
    DataType: null,
    CreatedDate: "2011-08-12T09:29:21",
    ModifiedDate: "2013-02-07T13:29:23.613753",
  },
];

export default documents;
