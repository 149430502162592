import styled from "styled-components";
import { Button } from "@material-ui/core";

const StyledButton = styled(Button)`
  width: 94px;
  height: 24px;
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  font-weight: 600;
`;

export default StyledButton;
