import { config, useSpring } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import { useChangedPage } from "services/hooks";
import Container from "./Container";
import Message from "./Message";

function PleaseEnterQuery() {
  const { t } = useTranslation();
  const changedPage = useChangedPage();
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.gentle,
    reset: changedPage,
  });
  return (
    <Container style={spring}>
      <Message>{t("Please enter a search query to begin")}</Message>
    </Container>
  );
}

export default PleaseEnterQuery;
