import { atom, useRecoilValue } from "recoil";

/**
 * Stores the user search query
 */
const Query = atom<string>({
  key: "Query",
  default: "",
});

/**
 * Hook which returns a boolean indicating whether or not the user has entered a query
 * @returns Boolean indicating whether or not the user has entered a query
 */
function useHasQuery(): boolean {
  return Boolean(useRecoilValue(Query));
}

export { useHasQuery };
export default Query;
