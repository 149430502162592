import { rest } from "msw";

import { useUrl } from "services/network";
import { apiDelay, apiStatus, apiResponse } from "services/testing";
import { Request, Response, endpoint } from "state/search/all/AllSearch";

import page1 from "../data/allSearch/page1";
import page2 from "../data/allSearch/page2";
import page3 from "../data/allSearch/page3";
import userDocuments from "../data/expertiseSearch/userDocuments";

const pages = [page1, page2, page3];

export default rest.post<Request, Response>(
  `${useUrl}${endpoint}`,
  function (req, res, ctx) {
    const page = req.body.page;
    let responseBody;

    if (req.body.scope === "Expertise") {
      // Specific user documents search from the Expertise page (pick a random set/response)
      responseBody =
        userDocuments[Math.floor(Math.random() * userDocuments.length)];
    } else {
      responseBody = pages[page % pages.length] || [];
    }

    return res(
      ctx.delay(apiDelay(endpoint, 1300)),
      ctx.status(apiStatus(endpoint, 200)),
      ctx.json(apiResponse(endpoint, responseBody))
    );
  }
);
