import { useSpring } from "@react-spring/web";
import { HTMLAttributes } from "react";

import Container from "./Container";
import Progress from "./Progress";

interface LoadingDisplayInterface extends HTMLAttributes<HTMLDivElement> {
  size?: number | string;
  css?: string;
}

function LoadingDisplay({ style, size, ...rest }: LoadingDisplayInterface) {
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Container
      style={{ ...spring, ...style }}
      {...rest}
      data-testid="loading-display"
    >
      <Progress size={size} />
    </Container>
  );
}

export default LoadingDisplay;
