import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import chroma from "chroma-js";

const Image = styled.img`
  max-height: 75vh;
  max-width: 50vw;
  grid-area: image;
  place-self: center;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border: ${({ theme }) =>
    `1px solid ${chroma
      .mix(theme.palette.background.paper, theme.palette.text.primary, 0.2)
      .css()}`};
`;

function LoadingPlaceholder({
  size: { width = 0, height = 0 },
}: {
  size: { width?: number; height?: number };
}) {
  const { t } = useTranslation();
  const canvas = useMemo(() => document.createElement("canvas"), []);
  const imageData = useMemo(() => {
    canvas.width = width;
    canvas.height = height;
    return canvas.toDataURL();
  }, [canvas, height, width]);
  return <Image src={imageData} alt={t("Loading image")} />;
}

export default LoadingPlaceholder;
