import { animated, useSpring } from "@react-spring/web";
import chroma from "chroma-js";
import styled from "styled-components/macro";

const Container = animated(styled.div<{ hidden: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${({ theme, hidden }) =>
    hidden
      ? theme.palette.background.paper
      : chroma(theme.palette.background.paper).alpha(0.7).css()};
  display: grid;
  place-items: center;
`);

function Overlay({ style, ...props }: any) {
  const spring = useSpring({ from: { opacity: 0 }, to: { opacity: 1 } });
  return <Container style={{ ...spring, ...style }} {...props} />;
}

export default Overlay;
