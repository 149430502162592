import { Response } from "state/search/expertise/ExpertisePersonProjects";

const projectDetails: Response = [
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/pbe_final_polys.flt",
    DataType: "Polygon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/Reg_bmod_Nov2014.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath: "/glb/am/sepco/proj/epw_onshore_east/usrhv5/NWPA_Onon_flt_polys",
    DataType: "Polygon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/pbe_polygons.flt",
    DataType: "Polygon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/regmaps.flt",
    DataType: "Fault",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/Utica2D_QI_events.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/pbe_mapping3.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/Tioga_Utica/Sabinsville/Polygons/rlh_shell_sabins3D_aoi.ply",
    DataType: "Polygon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/reg_maps_flts_500spacing.flt",
    DataType: "Polygon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/Tioga_Utica/Sabinsville/Faults/rlh_shell_sabins3D_aoi.flt",
    DataType: "Fault",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/reg_maps_flts_500spacing.flt",
    DataType: "Fault",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/pbe_mapping2.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/NWPA_shallow_hazards/new_poly_file.flt",
    DataType: "Polygon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/NWPA_shallow_hazards/nwpa_faults.flt",
    DataType: "Fault",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/rlh_July2015_bmod.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usjhn0/Interpretations_dir/pbe_mapping.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/pbe_mapping.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "CambrianPBE - USRHV5 (2)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/July2015bmod_finalgrids.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:13:50.512918",
    ModifiedDate: "2016-07-07T13:13:57.77142",
  },
];

export default projectDetails;
