import styled from "styled-components/macro";
import worldMap from "assets/login-background.png";

export default styled.div<{ isFocused: boolean }>`
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #333;
  display: grid;
  grid-template-rows: 1fr 1fr;
  place-items: center;
  padding: ${({ theme }) => `${theme.spacing(3)}px`};

  &::before {
    content: "";
    background: ${`url(${worldMap})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: transparent;
    transition: all 0.3s ease;
    background: ${({ isFocused }) => isFocused && `rgba(0, 0, 0, 0.5)`};
  }
`;
