import { useState, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { Result } from "state/search/expertise/ExpertiseSearch";
import LoadingDisplay from "components/LoadingDisplay";
import ErrorDisplay from "components/ErrorDisplay";

import Container from "./Container";
import Buttons from "./Buttons";
import Button from "./Button";
import Content from "./Content";
import Details from "./Details";
import Documents from "./Documents";
import Projects from "./Projects";

export default function DetailsDisplay({
  person,
}: {
  person: Partial<Result>;
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<number>(0);
  return (
    <Container onClick={(event) => event.stopPropagation()}>
      <Buttons>
        <Button active={selected === 0} onClick={() => setSelected(0)}>
          {t("Details")}
        </Button>
        <Button active={selected === 1} onClick={() => setSelected(1)}>
          {t("Documents")}
        </Button>
        <Button active={selected === 2} onClick={() => setSelected(2)}>
          {t("Projects")}
        </Button>
      </Buttons>
      <Content>
        <ErrorBoundary FallbackComponent={ErrorDisplay}>
          <Suspense
            fallback={<LoadingDisplay style={{ minHeight: "200px" }} />}
          >
            {
              [
                <Details person={person} />,
                <Documents person={person} />,
                <Projects person={person} />,
              ][selected]
            }
          </Suspense>
        </ErrorBoundary>
      </Content>
    </Container>
  );
}
