import { Response } from "state/search/all/AllSearch";

const data: Response = [
  {
    source: "SPO",
    rowCount: 10,
    totalRows: 1942473,
    totalRowsIncludingDuplicates: 1942473,
    refinerResults: [
      {
        entries: [
          {
            RefinementCount: 158096,
            RefinementName: "aspx",
            RefinementToken: '"ǂǂ61737078"',
            RefinementValue: "aspx",
          },
          {
            RefinementCount: 15468,
            RefinementName: "csv",
            RefinementToken: '"ǂǂ637376"',
            RefinementValue: "csv",
          },
          {
            RefinementCount: 129189,
            RefinementName: "doc",
            RefinementToken: '"ǂǂ646f63"',
            RefinementValue: "doc",
          },
          {
            RefinementCount: 81124,
            RefinementName: "docx",
            RefinementToken: '"ǂǂ646f6378"',
            RefinementValue: "docx",
          },
          {
            RefinementCount: 18557,
            RefinementName: "htm",
            RefinementToken: '"ǂǂ68746d"',
            RefinementValue: "htm",
          },
          {
            RefinementCount: 117303,
            RefinementName: "msg",
            RefinementToken: '"ǂǂ6d7367"',
            RefinementValue: "msg",
          },
          {
            RefinementCount: 767232,
            RefinementName: "pdf",
            RefinementToken: '"ǂǂ706466"',
            RefinementValue: "pdf",
          },
          {
            RefinementCount: 72929,
            RefinementName: "ppt",
            RefinementToken: '"ǂǂ707074"',
            RefinementValue: "ppt",
          },
          {
            RefinementCount: 75535,
            RefinementName: "pptx",
            RefinementToken: '"ǂǂ70707478"',
            RefinementValue: "pptx",
          },
          {
            RefinementCount: 29023,
            RefinementName: "txt",
            RefinementToken: '"ǂǂ747874"',
            RefinementValue: "txt",
          },
          {
            RefinementCount: 151375,
            RefinementName: "xls",
            RefinementToken: '"ǂǂ786c73"',
            RefinementValue: "xls",
          },
          {
            RefinementCount: 80724,
            RefinementName: "xlsm",
            RefinementToken: '"ǂǂ786c736d"',
            RefinementValue: "xlsm",
          },
          {
            RefinementCount: 186390,
            RefinementName: "xlsx",
            RefinementToken: '"ǂǂ786c7378"',
            RefinementValue: "xlsx",
          },
          {
            RefinementCount: 9441,
            RefinementName: "xml",
            RefinementToken: '"ǂǂ786d6c"',
            RefinementValue: "xml",
          },
          {
            RefinementCount: 34566,
            RefinementName: "zip",
            RefinementToken: '"ǂǂ7a6970"',
            RefinementValue: "zip",
          },
        ],
        name: "FileType",
      },
      {
        entries: [
          {
            RefinementCount: 1845930,
            RefinementName: "Older than a year",
            RefinementToken: "range(min, 2020-07-28T00:00:00.0000000Z)",
            RefinementValue: "Before 2020-07-28T00:00:00.0000000Z",
          },
          {
            RefinementCount: 87883,
            RefinementName: "Past Year",
            RefinementToken:
              "range(2020-07-28T00:00:00.0000000Z, 2021-06-28T00:00:00.0000000Z)",
            RefinementValue:
              "From 2020-07-28T00:00:00.0000000Z up to 2021-06-28T00:00:00.0000000Z",
          },
          {
            RefinementCount: 7840,
            RefinementName: "Past Month",
            RefinementToken:
              "range(2021-06-28T00:00:00.0000000Z, 2021-07-27T00:00:00.0000000Z)",
            RefinementValue:
              "From 2021-06-28T00:00:00.0000000Z up to 2021-07-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 820,
            RefinementName: "Past 24 hours",
            RefinementToken:
              'range(2021-07-27T00:00:00.0000000Z, max, to="le")',
            RefinementValue: "2021-07-27T00:00:00.0000000Z or later",
          },
        ],
        name: "Write",
      },
    ],
    results: [
      {
        docId: 17593002131469,
        dlcDocId: "AAAAX0037-4-1793",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaax0037",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAX0037",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={b37848c7-d1a9-4dc0-b98b-1524877af802}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "System Account;Ouwendijk, Dennis SITI-PTD/B/T",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaax0037",
        siteTitle: "Exploration Library Reports",
        people:
          "Dennis.Ouwendijk@shell.com | Ouwendijk, Dennis SITI-PTD/B/T | 693A30232E667C6D656D626572736869707C64656E6E69732E6F7577656E64696A6B407368656C6C2E636F6D i:0#.f|membership|dennis.ouwendijk@shell.com; | System Account | 5348415245504F494E545C73797374656D SHAREPOINT\\system;System Account",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={b37848c7-d1a9-4dc0-b98b-1524877af802}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION EASTERN_1",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "4001256284\\SUNDA STRAIT BASIN.PDF",
        path: "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION EASTERN_1/4001256284_SUNDA STRA_1.PDF",
        summary:
          "Ridge Andaiaan Ridge East Half Irrawaddy <strong>Basin</strong> (W. Burma) West Thailand <strong>Basin</strong> Gulf of Thailand <strong>Basin</strong> Mae Fang <strong>Basin</strong> North Sumatra <strong>Basin</strong> Central Sumatra <strong>Basin</strong> South <ddd/> ...",
        resultType: "PDF",
        size: 4984869,
        write: "2020-05-08T07:53:32.0000000Z",
        rank: 6127.73974609375,
      },
      {
        docId: 17593088464808,
        dlcDocId: "AAAAB0334-3-54877",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0334",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0334",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0334/_layouts/15/WopiFrame.aspx?sourcedoc={ec7cf324-dd1e-45ea-8d8b-70725101b6b5}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S;Ros, Arco AC SIEP-PTU/B/E",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0334",
        siteTitle: "EP Catalog - Europe Map Management - Restricted",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;Arco.Ros@shell.com | Ros, Arco AC SIEP-PTU/B/E | 693A30232E667C6D656D626572736869707C6172636F2E726F73407368656C6C2E636F6D i:0#.f|membership|arco.ros@shell.com;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0334/_layouts/15/WopiFrame.aspx?sourcedoc={ec7cf324-dd1e-45ea-8d8b-70725101b6b5}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0334/Restricted/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "IRELAND AND WEST OF BRITIAN BASIN SHAPES AND WELLS (encl. 57)",
        path: "https://eu001-sp.shell.com/sites/AAAAB0334/Restricted/EP200705203995022.pdf",
        summary:
          "Basement Highs Buried highs Carboniferous <strong>Basin</strong> Cretaceous <strong>Basin</strong> within older Mesozoic <strong>basins</strong> Cretaceous <strong>Basins</strong> Marginal highs Mesozoic <strong>basins</strong> Mesozoic <ddd/> ...",
        resultType: "pdf",
        size: 628462,
        write: "2015-10-19T21:17:44.0000000Z",
        rank: 6127.73828125,
      },
      {
        docId: 17592421822512,
        dlcDocId: "AAAAB0334-3-54455",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0334",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0334",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0334/_layouts/15/WopiFrame.aspx?sourcedoc={e1e7635a-7d7b-4201-beb9-4dbf5a951bec}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S;Ros, Arco AC SIEP-PTU/B/E",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0334",
        siteTitle: "EP Catalog - Europe Map Management - Restricted",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;Arco.Ros@shell.com | Ros, Arco AC SIEP-PTU/B/E | 693A30232E667C6D656D626572736869707C6172636F2E726F73407368656C6C2E636F6D i:0#.f|membership|arco.ros@shell.com;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0334/_layouts/15/WopiFrame.aspx?sourcedoc={e1e7635a-7d7b-4201-beb9-4dbf5a951bec}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0334/Restricted/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title:
          "IRELAND AND WEST OF BRITIAN PBE06 BASIN SHAPES & WELLS (encl. 2)",
        path: "https://eu001-sp.shell.com/sites/AAAAB0334/Restricted/EP200705203995002.pdf",
        summary:
          "Basement Highs Buried highs Carboniferous <strong>Basin</strong> Cretaceous <strong>Basin</strong> within older Mesozoic <strong>basins</strong> Cretaceous <strong>Basins</strong> Marginal highs Mesozoic <strong>basins</strong> Mesozoic <ddd/> ...",
        resultType: "pdf",
        size: 647981,
        write: "2015-10-19T21:10:26.0000000Z",
        rank: 6127.732421875,
      },
      {
        docId: 17592633083753,
        dlcDocId: "AAAAX0037-4-1220",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaax0037",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAX0037",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={9478eb0d-60d3-4edc-9c72-482bfc367113}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "System Account;Ouwendijk, Dennis SITI-PTD/B/T",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaax0037",
        siteTitle: "Exploration Library Reports",
        people:
          "Dennis.Ouwendijk@shell.com | Ouwendijk, Dennis SITI-PTD/B/T | 693A30232E667C6D656D626572736869707C64656E6E69732E6F7577656E64696A6B407368656C6C2E636F6D i:0#.f|membership|dennis.ouwendijk@shell.com; | System Account | 5348415245504F494E545C73797374656D SHAREPOINT\\system;System Account",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={9478eb0d-60d3-4edc-9c72-482bfc367113}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION - EASTER_3",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "2050090497\\BASIN INDEX MAP #600 - 689.PDF",
        path: "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION - EASTER_3/2050090497_BASIN INDE_1.PDF",
        summary:
          "Basin Cooper Basin Otway Basin Bass <strong>Basin</strong> Gippsland <strong>Basin</strong> Surat <strong>Basin</strong> Bowen <strong>Basin</strong> Sydney <strong>Basin</strong> Officer <strong>Basin</strong> Ngalia Trough Laura <strong>Basin</strong> Maryborough <strong>Basin</strong> Clarence-MOreton <ddd/> ...",
        resultType: "PDF",
        size: 5132997,
        write: "2020-05-08T07:20:00.0000000Z",
        rank: 6127.7236328125,
      },
      {
        docId: 17593219706512,
        dlcDocId: "AAAAB0156-3-13819",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0156",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0156",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0156/_layouts/15/WopiFrame.aspx?sourcedoc={0c6f9a66-0b07-4159-8fd8-3536dc97f479}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0156",
        siteTitle: "EP Catalog - Europe - Restricted",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0156/_layouts/15/WopiFrame.aspx?sourcedoc={0c6f9a66-0b07-4159-8fd8-3536dc97f479}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0156/Restricted/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "Geology and Hydrocarbon Potential of South Rewa Gondwana Basin",
        path: "https://eu001-sp.shell.com/sites/AAAAB0156/Restricted/Geology and Hydrocarbon Potential, Gondwana Basin.pdf",
        summary:
          "The following sub-<strong>basins</strong> were identified and are illustrated in Fig. I (a) Burhar sub-<strong>basin</strong>, ~ b) Jhager Khand sub-<strong>basin</strong>, (c) Singrauli Main sub-<strong>basin</strong>, (d) Mohar Jhingurdah  <ddd/> ...",
        resultType: "pdf",
        size: 1212288,
        write: "2005-10-28T13:33:57.0000000Z",
        rank: 6127.71533203125,
      },
      {
        docId: 17592862845487,
        dlcDocId: "AAAAB0156-3-12565",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0156",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0156",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0156/_layouts/15/WopiFrame.aspx?sourcedoc={bd73912b-6e94-4fde-82f6-1bc1217bff09}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0156",
        siteTitle: "EP Catalog - Europe - Restricted",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0156/_layouts/15/WopiFrame.aspx?sourcedoc={bd73912b-6e94-4fde-82f6-1bc1217bff09}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0156/Restricted/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title:
          "Evolution of geosynclinal and platform basins. Stratigraphy and evolution of the Ganga Basin, India",
        path: "https://eu001-sp.shell.com/sites/AAAAB0156/Restricted/20 Indian National Committee's.pdf",
        summary:
          "programmes with members on other <strong>basins</strong> are as follows: Vindhyan <strong>Basin</strong> Andaman and Nicobar <strong>Basin</strong> 1978 } to be worked out by the <ddd/> ...",
        resultType: "pdf",
        size: 769472,
        write: "2005-10-28T13:34:11.0000000Z",
        rank: 6127.67626953125,
      },
      {
        docId: 17592711126506,
        dlcDocId: "AAAAB0277-3-10890",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0277",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0277",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0277/_layouts/15/WopiFrame.aspx?sourcedoc={98770fd1-55d9-4613-9d2d-35349d4afd61}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0277",
        siteTitle: "Exploration Europe 02",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0277/_layouts/15/WopiFrame.aspx?sourcedoc={98770fd1-55d9-4613-9d2d-35349d4afd61}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0277/Lib01/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title:
          "Structural evolution of the Erris high and the east flank of the Rockall basin . Independent project report MSc course in basin evolution and dynamics 2000.",
        path: "https://eu001-sp.shell.com/sites/AAAAB0277/Lib01/0127.pdf",
        summary:
          "Traverse Along Axis of the Rocka1l <strong>Basin</strong> - Crustal <ddd/> Major Transfer Faults of the Rockall <strong>Basin</strong> Mesozoic <strong>Basins</strong> on the Western Margin of the Rockall <strong>Basin</strong> Seismic <ddd/> ...",
        resultType: "pdf",
        size: 35651317,
        write: "2005-12-15T16:16:11.0000000Z",
        rank: 6127.66455078125,
      },
      {
        docId: 17593046294075,
        dlcDocId: "AAAAB0332-3-1702",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0332",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0332",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0332/_layouts/15/WopiFrame.aspx?sourcedoc={0246302f-328b-44a2-b6c3-3049ed47b7ce}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S;Ros, Arco AC SIEP-PTU/B/E",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0332",
        siteTitle: "EP Catalog - SIEP Map Management",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;Arco.Ros@shell.com | Ros, Arco AC SIEP-PTU/B/E | 693A30232E667C6D656D626572736869707C6172636F2E726F73407368656C6C2E636F6D i:0#.f|membership|arco.ros@shell.com;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0332/_layouts/15/WopiFrame.aspx?sourcedoc={0246302f-328b-44a2-b6c3-3049ed47b7ce}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0332/Restricted/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title:
          "Global Unconventionals Assessment Heat Flow and Wells PSI Erlian Basin EP 2007-5119",
        path: "https://eu001-sp.shell.com/sites/AAAAB0332/Restricted/EP200911304351001.pdf",
        summary:
          "Henteyn and Onon-Argun Fold Belts Ordos <strong>Basin</strong> A <ddd/> SHELL EXPLORATION AND PRODUCTION Legend UG <strong>Basins</strong> Screened UG <strong>BASINS</strong> Hotspots Gas type <strong>Basin</strong> Centered Gas Tight Gas Shale Gas; Shale <ddd/> ...",
        resultType: "pdf",
        size: 1539413,
        write: "2009-11-06T13:10:44.0000000Z",
        rank: 6127.6611328125,
      },
      {
        docId: 17592781361904,
        dlcDocId: "AAAAB0156-3-14005",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0156",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0156",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0156/_layouts/15/WopiFrame.aspx?sourcedoc={aa107ee8-b11e-4e26-b512-aebc09a0fffb}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0156",
        siteTitle: "EP Catalog - Europe - Restricted",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0156/_layouts/15/WopiFrame.aspx?sourcedoc={aa107ee8-b11e-4e26-b512-aebc09a0fffb}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0156/Restricted/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "Chure Basin; Surkhet Basin",
        path: "https://eu001-sp.shell.com/sites/AAAAB0156/Restricted/Chure basin.pdf",
        summary:
          "114 SURKHET <strong>BASIN</strong> Prepared for the Atlas by N. B. Kayastha Department of Mines and Geology, Kathmandu The Surkhet <strong>Basin</strong> occupies a small area in the western <ddd/> Figure Ne 1 is based on <ddd/> ...",
        resultType: "pdf",
        size: 169481,
        write: "2005-10-28T13:33:41.0000000Z",
        rank: 6127.6552734375,
      },
      {
        docId: 17593024838949,
        dlcDocId: "AAAAB0332-3-1710",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0332",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0332",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0332/_layouts/15/WopiFrame.aspx?sourcedoc={0576a74b-e53d-42b7-991d-7b90212e5864}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S;Ros, Arco AC SIEP-PTU/B/E",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0332",
        siteTitle: "EP Catalog - SIEP Map Management",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;Arco.Ros@shell.com | Ros, Arco AC SIEP-PTU/B/E | 693A30232E667C6D656D626572736869707C6172636F2E726F73407368656C6C2E636F6D i:0#.f|membership|arco.ros@shell.com;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0332/_layouts/15/WopiFrame.aspx?sourcedoc={0576a74b-e53d-42b7-991d-7b90212e5864}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0332/Restricted/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title:
          "Global Unconventionals Assessment Landsat image and High Resolution Availability, Erlian Basin, EP2007-5119",
        path: "https://eu001-sp.shell.com/sites/AAAAB0332/Restricted/EP200911303139001.pdf",
        summary:
          "SHELL EXPLORATION AND PRODUCTION Legend UG <strong>Basins</strong> Screened UG <strong>BASINS</strong> Hotspots Gas type <strong>Basin</strong> Centered Gas Tight Gas Shale Gas Earthsat 150m <ddd/> ...",
        resultType: "pdf",
        size: 11497617,
        write: "2015-10-13T20:05:52.0000000Z",
        rank: 6127.64404296875,
      },
    ],
    spellingSuggestion: "",
  },
];

export default data;
