import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FileIcon, defaultStyles, DefaultExtensionType } from "react-file-icon";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { useGetPath } from "services/hooks";
import {
  amplitudeTracker,
  formatBytes,
  formatDateTime,
  timeZone,
} from "services/helpers";
import { Result as IResult } from "state/search/all/AllSearch";
import { path as ExpertisePath } from "pages/ExpertisePage";

import Paper from "./Paper";
import Icon from "./Icon";
import Meta from "./Meta";
import Title from "./Title";
import Path from "./Path";
import Summary from "./Summary";
import Content from "./Content";
import ShowMore from "./ShowMore";
import Property from "./Property";
import Information from "./Information";
import Authors from "./Authors";
import DisabledAuthor from "./DisabledAuthor";
import { AMPLITUDE_EVENTS } from "services/constants";

const disabledAuthors = ["System Account"];

function Result({ result }: { result: IResult }) {
  const { t } = useTranslation();
  const fileExtension = result.resultType.toLowerCase() as DefaultExtensionType;
  const [shouldShowAll, setShouldShowAll] = useState(false);
  const getPath = useGetPath();
  const authors = result?.Author?.split(";") ?? [];
  const folderName = result.path.split("/").reverse()[1];

  function toggleShowAll() {
    setShouldShowAll(!shouldShowAll);
  }

  return (
    <Paper active={shouldShowAll}>
      <Content>
        <Icon>
          <FileIcon
            radius={0}
            extension={fileExtension}
            {...defaultStyles[fileExtension]}
          />
        </Icon>
        <Information>
          <Title
            href={result.path || ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            {result.title}
          </Title>
          <Path
            onClick={() =>
              amplitudeTracker(AMPLITUDE_EVENTS.RESULT_OPENED, { resultLink: result.path })
            }
            href={result.path}
            target="_blank"
            rel="noopener noreferrer"
          >
            {result.path}
          </Path>
          <Summary dangerouslySetInnerHTML={{ __html: result.summary }} />
          <Collapse
            onEnter={() => amplitudeTracker(AMPLITUDE_EVENTS.EXPAND_RESULT_CARD)}
            in={shouldShowAll}
          >
            <Meta>
              <Property label={t("Author(s)")}>
                <Authors>
                  {authors.map((author, index) => {
                    const authorDisplay = disabledAuthors.includes(author) ? (
                      <DisabledAuthor>{author}</DisabledAuthor>
                    ) : (
                      <Link to={getPath(ExpertisePath, { query: author })}>
                        {author}
                      </Link>
                    );
                    const hasMoreAuthors = index < authors.length - 1;
                    return (
                      <Fragment key={index}>
                        {authorDisplay}
                        {hasMoreAuthors ? <span>,&nbsp;</span> : null}
                      </Fragment>
                    );
                  })}
                </Authors>
              </Property>
              <Property label={t("Last Modified Time")}>
                {formatDateTime(result.write)} ({timeZone})
              </Property>
              <Property label={t("Size")}>{formatBytes(result.size)}</Property>
              <Property label={t("Site Collection")}>
                <a
                  href={result.SiteName}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {result.siteTitle}
                </a>
              </Property>
              <Property label={t("Folder")}>
                <a
                  href={result.parentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {folderName}
                </a>
              </Property>
            </Meta>
          </Collapse>
        </Information>
      </Content>
      <ShowMore onClick={toggleShowAll}>
        <IconButton size="small" aria-label={t("See details")} component="div">
          {shouldShowAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </ShowMore>
    </Paper>
  );
}

export default Result;
