import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import DropDown from "components/DropDown";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { useLogoutUser } from "services/auth";
import UserState from "state/auth/User";
import SelectedTheme from "state/SelectedTheme";

import Container from "./Container";
import List from "./List";
import ListItem from "./ListItem";
import UserLabel from "./UserLabel";

function UserDisplay() {
  const { t } = useTranslation();
  const user = useRecoilValue(UserState);
  const logoutUser = useLogoutUser();
  const [selectedTheme, setSelectedTheme] = useRecoilState(SelectedTheme);

  function themeToggle() {
    setSelectedTheme(selectedTheme === "light" ? "dark" : "light");
  }

  return (
    <Container>
      <DropDown
        border={false}
        label={
          <UserLabel>
            <AccountCircleIcon />
            <span>{user.name}</span>
          </UserLabel>
        }
        align="right"
      >
        {({ close }) => (
          <List>
            <ListItem button data-testid="logout-button" onClick={themeToggle}>
              <Switch
                checked={selectedTheme === "dark"}
                onChange={themeToggle}
                name="theme"
                inputProps={{ "aria-label": "theme toggle" }}
              />
              <Typography>
                {selectedTheme === "light" ? t("Light theme") : t("Dark theme")}
              </Typography>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                close();
                logoutUser();
              }}
              data-testid="logout-button"
            >
              <ExitToAppIcon />
              <ListItemText primary={t("Logout")} />
            </ListItem>
          </List>
        )}
      </DropDown>
    </Container>
  );
}

export default UserDisplay;
