import { Response } from "state/search/expertise/ExpertisePersonProjects";

const projectDetails: Response = [
  {
    UserAlias: "USKKE9",
    ProjectName: "Utica_QI - USRHV5 (1)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/Utica2D_QI_events.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:14:18.891396",
    ModifiedDate: "2016-07-07T13:14:19.808386",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "Utica_QI - USRHV5 (1)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/pbe_final_polys.flt",
    DataType: "Polygon",
    CreatedDate: "2016-07-07T13:14:18.891396",
    ModifiedDate: "2016-07-07T13:14:19.808386",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "Utica_QI - USRHV5 (1)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/reg_bmod_zmap_final.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:14:18.891396",
    ModifiedDate: "2016-07-07T13:14:19.808386",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "Utica_QI - USRHV5 (1)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/reg_PA_OH_NY_WV_KY.int",
    DataType: "3Dhorizon",
    CreatedDate: "2016-07-07T13:14:18.891396",
    ModifiedDate: "2016-07-07T13:14:19.808386",
  },
  {
    UserAlias: "USKKE9",
    ProjectName: "Utica_QI - USRHV5 (1)",
    ProjectSource: null,
    ProjectCount: 0,
    DataPath:
      "/glb/am/sepco/proj/epw_onshore_east/usrhv5/nDI/reg_maps/reg_PA_OH_NY_WV_KY_flts.ply",
    DataType: "Polygon",
    CreatedDate: "2016-07-07T13:14:18.891396",
    ModifiedDate: "2016-07-07T13:14:19.808386",
  },
];

export default projectDetails;
