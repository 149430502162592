export interface Node {
  parent?: Node | null;
  children: Array<Node> | null;
  level: string;
  text: string;
  [k: string]: any;
}
export interface Tree extends Array<Node> {}

export function addParentRef(source: Node, parent: Node | null = null) {
  source.parent = parent;
  if (source.children) {
    source.children.forEach((child) => addParentRef(child, source));
  }
  return source;
}
