import styled from "styled-components/macro";

export default styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "icon information";
  grid-column-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  place-items: center start;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
`;
