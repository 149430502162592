import { useMemo } from "react";
import { selectorFamily } from "recoil";
import { AxiosResponse } from "axios";

import Query from "state/Query";
import { useAPI } from "services/network";
import { useRecoilPreviousValue } from "services/hooks";

import ImageSearchRefiner from "./ImageSearchRefiner";
import { Result, AllSourceResult, Refiner } from "../all/AllSearch";
import { RefinerTypes } from "components/Refiners/types/RefinerTypes";

export const endpoint = "/api/v1/SearchApi/imagesearch";
export const spoGroup = "image";

export interface ImageSourceResult extends AllSourceResult {
  source: "SPOImage";
  results: Array<Result>;
}

export interface Request {
  page: number;
  queryText: string;
  refiner: string;
  scope: "Image";
  source: "SPOImage";
}
export interface Response extends Array<ImageSourceResult> {}

/**
 * Fetches the image-search result from the backend for a given page
 */
const ImageSearch = selectorFamily({
  key: "ImageSearch",
  get:
    (page: number) =>
    async ({ get }) => {
      const queryText = get(Query);
      const refiner = get(ImageSearchRefiner);
      const scope = "Image";
      const source = "SPOImage";
      const requestBody: Request = {
        page,
        queryText,
        refiner,
        scope,
        source,
      };
      return (
        await useAPI.post<Request, AxiosResponse<Response>>(
          endpoint,
          requestBody
        )
      ).data[0];
    },
});

function useFileTypeEntries() {
  const spoResult = useRecoilPreviousValue(ImageSearch(0), null);
  return useMemo(() => {
    const refiner = spoResult?.refinerResults.find(
      (refiner) => refiner.name === "FileType"
    );
    return refiner ? refiner.entries : null;
  }, [spoResult]);
}

/**
 * Returns the list of refiners
 * @returns
 */
export function useRefiners() {
  const spoResult = useRecoilPreviousValue(ImageSearch(0), null);
  return useMemo(() => {
    return spoResult?.refinerResults?.reduce((result,r)=> {
      if(RefinerTypes[r.name as keyof typeof RefinerTypes])
        result.push(<Refiner>{...r, displayName: RefinerTypes[r.name as keyof typeof RefinerTypes]});
      return result;
    }, [] as Refiner[]);
  }, [spoResult]);
}

export { useFileTypeEntries };
export default ImageSearch;
