import { Response } from "state/search/all/AllSearch";

const documents: Response = [
  {
    source: "SPO",
    rowCount: 2,
    totalRows: 2,
    totalRowsIncludingDuplicates: 2,
    refinerResults: [
      {
        entries: [
          {
            RefinementCount: 1,
            RefinementName: "msg",
            RefinementToken: '"ǂǂ6d7367"',
            RefinementValue: "msg",
          },
          {
            RefinementCount: 1,
            RefinementName: "pdf",
            RefinementToken: '"ǂǂ706466"',
            RefinementValue: "pdf",
          },
        ],
        name: "FileType",
      },
      {
        entries: [
          {
            RefinementCount: 2,
            RefinementName: "Older than a year",
            RefinementToken: "range(min, 2020-08-27T00:00:00.0000000Z)",
            RefinementValue: "Before 2020-08-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Year",
            RefinementToken:
              "range(2020-08-27T00:00:00.0000000Z, 2021-07-27T00:00:00.0000000Z)",
            RefinementValue:
              "From 2020-08-27T00:00:00.0000000Z up to 2021-07-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Month",
            RefinementToken:
              "range(2021-07-27T00:00:00.0000000Z, 2021-08-26T00:00:00.0000000Z)",
            RefinementValue:
              "From 2021-07-27T00:00:00.0000000Z up to 2021-08-26T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past 24 hours",
            RefinementToken:
              'range(2021-08-26T00:00:00.0000000Z, max, to="le")',
            RefinementValue: "2021-08-26T00:00:00.0000000Z or later",
          },
        ],
        name: "Write",
      },
    ],
    results: [
      {
        docId: 17600588924516,
        dlcDocId: "AAAAA9770-11-427",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaaa1351",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAA1351/KMPortal",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAA1351/KMPortal/_layouts/15/WopiFrame.aspx?sourcedoc={bdf5514c-992e-4309-b206-bb421fc596bc}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author:
          "Ricardo Silva;Grant, Colin J SI-UPX/N;Ouwendijk, Dennis SITI-PTD/B/T",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaaa1351",
        siteTitle: "KM Site",
        people:
          "Colin.J.Grant@shell.com | Grant, Colin J SI-UPX/N | 693A30232E667C6D656D626572736869707C636F6C696E2E6A2E6772616E74407368656C6C2E636F6D i:0#.f|membership|colin.j.grant@shell.com;Dennis.Ouwendijk@shell.com | Ouwendijk, Dennis SITI-PTD/B/T | 693A30232E667C6D656D626572736869707C64656E6E69732E6F7577656E64696A6B407368656C6C2E636F6D i:0#.f|membership|dennis.ouwendijk@shell.com;Ricardo Silva;Grant, Colin J SI-UPX/N",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/KMPortal/_layouts/15/WopiFrame.aspx?sourcedoc={bdf5514c-992e-4309-b206-bb421fc596bc}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/KMPortal/EC/Conjugate Margins",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "2018 Conjugate Margin Conference",
        path: "https://eu001-sp.shell.com/sites/AAAAA1351/KMPortal/EC/Conjugate Margins/2018 Conjugate Margins Conference - Program.pdf",
        summary:
          "Jurassic rift <strong>basins</strong> 44 Detailed Structure of a <ddd/> to a mid-Carboniferous <strong>basin</strong>, western Ireland <ddd/> ...",
        resultType: "pdf",
        size: 11978271,
        write: "2018-08-16T17:06:47.0000000Z",
        rank: 0.0,
      },
      {
        docId: 991203535460885783,
        dlcDocId: "AAAAA1605-2097-12849",
        siteUrl: "https://eu001-sp.shell.com/sites/aafaa1707",
        webUrl: "https://eu001-sp.shell.com/sites/AAFAA1707",
        ServerRedirectedEmbedURL: null,
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author:
          "Christine Feimer;System Account;ITS-APP-IMFFVMIG6-CSA-164396-SP (INMPO3)",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aafaa1707",
        siteTitle: "ER Projects and Technology",
        people:
          " | ITS-APP-IMFFVMIG6-CSA-164396-SP (INMPO3) | 693A30232E667C6D656D626572736869707C6974732D6170702D696D6666766D6967362D6373612D3136343339362D7370407368656C6C636F72702E6F6E6D6963726F736F66742E636F6D i:0#.f|membership|its-app-imffvmig6-csa-164396-sp@shellcorp.onmicrosoft.com; | System Account | 5348415245504F494E545C73797374656D SHAREPOINT\\system;Colin.J.Grant@shell.com | Grant, Colin J SI-UPX/N | 693A30232E667C6D656D626572736869707C636F6C696E2E6A2E6772616E74407368656C6C2E636F6D i:0#.f|membership|colin.j.grant@shell.com;Christine Feimer;System Account",
        displayUrl: null,
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAFAA1707/RTI Archived Documents/RTI Documents 2015-09 till 2020-06/RTI Documents/Characterizing Recent Successful Postrift Deepwater Turbidite Plays of the Atlantic basin",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.msg.svg",
        title:
          "Characterizing Recent Successful Postrift Deepwater Turbidite Plays of the Atlantic basin",
        path: "https://eu001-sp.shell.com/sites/AAFAA1707/RTI Archived Documents/RTI Documents 2015-09 till 2020-06/RTI Documents/Characterizing Recent Successful Postrift Deepwater Turbidite Plays of the Atlantic basin/Colin_J_Grant_Approval to show IONSPAN SEISMIC DATA_33304.msg",
        summary:
          "of the Atlantic <strong>Basin</strong>\r\n\r\nCO-AUTHORS AND AFFLIATION <ddd/> on Rift <strong>Basin</strong> Evolution\r\n\r\nLOCATION:\r\n\r\nBahrain <ddd/> ...",
        resultType: "msg",
        size: 802304,
        write: "2020-01-07T11:41:00.0000000Z",
        rank: 0.0,
      },
    ],
    spellingSuggestion: "",
  },
];

export default documents;
