import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import ExpertiseSearch, {
  useSearchSource,
} from "state/search/expertise/ExpertiseSearch";
import ExpertiseSearchPage from "state/search/expertise/ExpertiseSearchPage";
import LoadingDisplay from "components/LoadingDisplay";

import ResultsPage from "./ResultsPage";
import Container from "./Container";
import LoadMore from "./LoadMore";

function Results() {
  const firstPageLoadable = useRecoilValueLoadable(ExpertiseSearch(0));
  const expertiseSearchPage = useRecoilValue(ExpertiseSearchPage);
  const pageList = expertiseSearchPage
    ? [...Array(expertiseSearchPage).keys()]
    : [0];
  const spoResult = useSearchSource(
    firstPageLoadable.state === "hasValue" ? firstPageLoadable.contents : [],
    "SPOExpertise"
  );
  const hasMorePages = useMemo(() => {
    if (spoResult) {
      const currentRow = expertiseSearchPage * spoResult.rowCount;
      return currentRow < spoResult.totalRows;
    }
    return false;
  }, [expertiseSearchPage, spoResult]);

  return firstPageLoadable.state !== "loading" ? (
    <>
      <Container>
        {pageList.map((page) => (
          <ResultsPage page={page} key={page} />
        ))}
      </Container>
      {hasMorePages && <LoadMore />}
    </>
  ) : (
    <LoadingDisplay style={{ minHeight: "200px" }} />
  );
}

export default Results;
