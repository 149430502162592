import { useEffect, useState, HTMLProps } from "react";
import styled from "styled-components/macro";
import chroma from "chroma-js";
import { animated } from "@react-spring/web";
import classNames from "classnames";

const Img = animated(styled.img`
  max-width: 100%;
  max-height: 160px;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  cursor: pointer;
  border: ${({ theme }) =>
    `1px solid ${chroma
      .mix(theme.palette.background.paper, theme.palette.text.primary, 0.2)
      .css()}`};
  &:hover {
    border-color: ${({ theme }) =>
      chroma
        .mix(theme.palette.background.paper, theme.palette.text.primary, 0.3)
        .darken()
        .css()};
  }

  opacity: 0;
  height: 160px;
  transition: opacity 0.3s ease;
  &.rendered {
    height: auto;
    opacity: 1;
  }
`);

function Image(props: HTMLProps<HTMLImageElement>) {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRendered(true);
    }, 300);
  }, []);

  return <Img className={classNames(rendered && "rendered")} {...props} />;
}

export default Image;
