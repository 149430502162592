import { ButtonBase } from "@material-ui/core";
import chroma from "chroma-js";
import styled, { css } from "styled-components/macro";
import { NavButtonInterface } from "./index";

const leftStyle = css`
  left: 0;
`;
const rightStyle = css`
  right: 0;
`;

export default styled(ButtonBase)<NavButtonInterface>`
  display: grid;
  place-items: center;
  width: ${({ width }) => `${width}px`};
  position: absolute;
  top: 0;
  z-index: 2;
  height: 100%;
  background: ${({ theme }) =>
    chroma(theme.palette.background.paper).alpha(0.9).css()};
  box-shadow: ${({ theme }) => theme.shadows[3]};
  cursor: ${({ disableRipple }) => (disableRipple ? "default" : "pointer")};
  &:hover {
    background: ${({ theme, disableRipple }) =>
      !disableRipple &&
      chroma(theme.palette.background.paper).darken(0.125).alpha(0.9).css()};
  }
  ${({ placement }) => (placement === "left" ? leftStyle : rightStyle)}
`;
