import { ReactNode } from "react";
import { SetterOrUpdater } from "recoil";
import ListItemText from "@material-ui/core/ListItemText";

import LoadingDisplay from "components/LoadingDisplay";
import DropDown from "components/DropDown";

import List from "./List";
import ListItem from "./ListItem";
import ChoiceDisplay from "./ChoiceDisplay";
import Header from "./Header";
import Button from "./Button";
import Label from "./Label";
import Choice from "./Choice";

export interface SelectionOption {
  label?: string;
  value?: string;
}

export type SelectionOptionValue = Partial<SelectionOption> | undefined;

export default function SelectionDropdown<
  T extends SelectionOption | undefined
>({
  label = "Select",
  options,
  state: [choice, setChoice],
  clearLabel = "Clear Selection",
}: {
  label: ReactNode;
  clearLabel?: ReactNode;
  options: Array<T> | undefined | null;
  state: [T, SetterOrUpdater<T | undefined>];
}) {
  return (
    <DropDown
      label={
        <ChoiceDisplay>
          {choice ? (
            <>
              <Label>{label}:</Label>
              <Choice>{choice.label}</Choice>
            </>
          ) : (
            label
          )}
        </ChoiceDisplay>
      }
      align="left"
    >
      {({ close }) => {
        function clear() {
          setChoice(undefined);
          close();
        }
        return (
          <>
            <Header>
              <Button
                variant="text"
                onClick={clear}
                disabled={typeof choice === "undefined"}
              >
                {clearLabel}
              </Button>
            </Header>
            <List>
              {options && options.length ? (
                options.map((option, index) => {
                  function chooseOption() {
                    setChoice(option);
                    close();
                  }
                  return option ? (
                    <ListItem
                      selected={choice === option}
                      button
                      onClick={chooseOption}
                      key={index}
                    >
                      <ListItemText primary={option.label || option.value} />
                    </ListItem>
                  ) : null;
                })
              ) : (
                <LoadingDisplay style={{ minHeight: "100px" }} />
              )}
            </List>
          </>
        );
      }}
    </DropDown>
  );
}
