import { useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import SharedDrives, { Response } from "state/search/sharedDrives/SharedDrives";
import SharedDrivesPage from "state/search/sharedDrives/SharedDrivesPage";
import LoadingDisplay from "components/LoadingDisplay";

import ResultsPage from "./ResultsPage";
import LoadMore from "./LoadMore";

function Results() {
  const firstPageLoadable = useRecoilValueLoadable(SharedDrives(0));
  const sharedDrivesPage = useRecoilValue(SharedDrivesPage);
  const pageList = sharedDrivesPage ? [...Array(sharedDrivesPage).keys()] : [0];
  const results: Response | null =
    firstPageLoadable.state === "hasValue" ? firstPageLoadable.contents : null;
  const hasMorePages = useMemo(() => {
    if (results) {
      const rowCount = results.hits.hits.length;
      const currentRow = sharedDrivesPage * rowCount;
      return currentRow < results.hits.total.value;
    }
    return false;
  }, [sharedDrivesPage, results]);

  return firstPageLoadable.state !== "loading" ? (
    <>
      {pageList.map((page) => (
        <ResultsPage page={page} key={page} />
      ))}
      {hasMorePages && <LoadMore />}
    </>
  ) : (
    <LoadingDisplay style={{ minHeight: "200px" }} />
  );
}

export default Results;
