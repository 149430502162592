import { useEffect } from "react";
import theme from "theme/light";
import { isLocalEnvironment } from "services/env";

declare global {
  interface Window {
    theme: any;
  }
}

/**
 * Export the theme so it is available on the console during development
 */
function ExportThemeInDevelopment() {
  useEffect(() => {
    if (isLocalEnvironment) {
      window.theme = theme;
    }
  }, []);
  return null;
}

export default ExportThemeInDevelopment;
