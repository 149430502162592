import { Check } from "@material-ui/icons";
import { REFINER_BANNER_CONSTANTS } from "services/constants";
import styled from "styled-components";

const Checkmark = styled(Check)`
  height: 20px;
  width: 20px;
  color: ${REFINER_BANNER_CONSTANTS.SKY_DEFAULT};
`;

export default Checkmark;
