import styled from "styled-components/macro";

export default styled.a<{ href: string }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  font-weight: bold;

  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.background.paper)};
  &:visited,
  &:hover {
    color: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.background.paper)};
  }
`;
