import styled from "styled-components/macro";
import SearchIcon from "@material-ui/icons/Search";

export default styled(SearchIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.primary};
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  &:active {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
