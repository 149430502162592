import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export default styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
  &:visited {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: none;
  }
`;
