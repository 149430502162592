import { useTranslation } from "react-i18next";
import { FileIcon, defaultStyles, DefaultExtensionType } from "react-file-icon";
import { Link } from "react-router-dom";

import { useGetPath } from "services/hooks";
import { Result as IResult } from "state/search/all/AllSearch";
import { path as ExpertisePath } from "pages/ExpertisePage";

import Paper from "./Paper";
import Icon from "./Icon";
import Title from "./Title";
import Path from "./Path";
import Property from "./Property";
import Summary from "./Summary";
import Content from "./Content";
import Information from "./Information";
import Authors from "./Authors";
import DisabledAuthor from "./DisabledAuthor";

const disabledAuthors = ["System Account"];

export default function Document({ document }: { document: IResult }) {
  const { t } = useTranslation();
  const getPath = useGetPath();
  const fileExtension =
    document.resultType.toLowerCase() as DefaultExtensionType;
  const authors = document.Author.split(";");

  return (
    <Paper>
      <Content>
        <Icon>
          <FileIcon
            radius={0}
            extension={fileExtension}
            {...defaultStyles[fileExtension]}
          />
        </Icon>
        <Information>
          <Title
            href={document.displayUrl || ""}
            title={document.title || ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            {document.title}
          </Title>
          <Path
            href={document.path}
            title={document.path}
            target="_blank"
            rel="noopener noreferrer"
          >
            {document.path}
          </Path>
          <Summary dangerouslySetInnerHTML={{ __html: document.summary }} />
          <Property label={t("Author(s)")}>
            <Authors>
              {authors.map((author, index) => {
                const hasPrevAuthors = index > 0;
                return disabledAuthors.includes(author) ? (
                  <span key={index}>
                    {hasPrevAuthors ? ", " : null}
                    <DisabledAuthor>{author}</DisabledAuthor>
                  </span>
                ) : (
                  <span key={index}>
                    {hasPrevAuthors ? ", " : null}
                    <Link to={getPath(ExpertisePath, { query: author })}>
                      {author}
                    </Link>
                  </span>
                );
              })}
            </Authors>
          </Property>
        </Information>
      </Content>
    </Paper>
  );
}
