import { useTrail, animated } from "@react-spring/web";
import { useRef, useEffect } from "react";

import { useRecoilValueOr } from "services/hooks";
import SharedDrives from "state/search/sharedDrives/SharedDrives";

import Container from "./Container";
import Result from "./Result";
import NoResults from "./NoResults";

function ResultsPage({ page }: { page: number }) {
  const sharedDrives = useRecoilValueOr(SharedDrives(page), null);
  const previousResult = useRef(sharedDrives);
  useEffect(() => {
    previousResult.current = sharedDrives;
  }, [sharedDrives]);
  const newResult = previousResult.current !== sharedDrives;

  const trail = useTrail(sharedDrives?.hits.hits.length || 0, {
    config: { duration: 100 },
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: newResult,
  });

  return sharedDrives && sharedDrives.hits.total ? (
    <Container>
      {trail.map((style, index) => (
        <animated.div key={index} style={style}>
          <Result result={sharedDrives.hits.hits[index]} />
        </animated.div>
      ))}
    </Container>
  ) : (
    <NoResults />
  );
}

export default ResultsPage;
