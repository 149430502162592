import { selector } from "recoil";
import { format } from "date-fns";
import FileType from "state/FileType";
import Query from "state/Query";
import DateRange from "state/DateRange";
import { ISearch } from "./SharedDrives";
export const spoGroup = "shared-drives";

export default selector({
    key: "SharedDrivesServerQuery",
    get: ({ get }) => {
        const query = get(Query);
        const dateRange = get(DateRange(spoGroup));
        const fileType = get(FileType(spoGroup));

        const payload: ISearch = {
            searchText: query,
            pageNumber: 0,
            pageSize: 0,
        }

        if (dateRange && dateRange.length) {
            payload.startDate = format(dateRange[0].startDate, "yyyy-MM-dd")
            payload.endDate = format(dateRange[0].endDate, "yyyy-MM-dd")
        }

        fileType?.value && (payload.fileType = fileType.value)
        return payload;
    }
});