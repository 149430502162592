import axios from "axios";
import { access } from "fs";
import { stripAddress } from "services/helpers";
import env from "./env";
const useUrl = stripAddress(
  env.REACT_APP_BASE_API_HOST || "https://useuatapiapp.azurewebsites.net/"
);
const siftUrl = stripAddress(
  env.REACT_APP_SIFT_API_HOST || "https://sift.americas.shell.com/"
);

const useAPI = axios.create({
  baseURL: useUrl
});

const siftAPI = axios.create({
  baseURL: siftUrl,
});

export { useAPI, siftAPI, useUrl, siftUrl };
