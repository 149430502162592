import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { DateRange } from "state/DateRange";
import { getSite, SourceNode } from "state/sources/SourceTree";
import * as amplitude from "@amplitude/analytics-browser";

import { css } from "styled-components/macro";
import chroma from "chroma-js";
import { SelectionOption } from "components/PageControls/FileType";

const lightBorder = css<{ active?: boolean }>`
  border: ${({ theme, active }) =>
    active
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${chroma
          .mix(theme.palette.background.paper, theme.palette.text.primary, 0.4)
          .css()}`};
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const darkBorder = css<{ active?: boolean }>`
  border: ${({ theme, active }) =>
    active
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${chroma(theme.palette.background.paper)
          .darken(0.5)
          .css()}`};
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export interface ThemeBorderProps {
  active?: boolean;
}

const themeBorder = css<ThemeBorderProps>`
  ${({ theme }) => (theme.palette.type === "light" ? lightBorder : darkBorder)}
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
`;

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function formatDateTime(
  time: string,
  outputFormat: string = `LLL do yyyy - h:mm:ss aa`
) {
  return format(utcToZonedTime(time, timeZone), outputFormat);
}

/**
 * Add thousands separator to the input number
 * @param num Input number
 */
function formatNumber(num: number | undefined) {
  return (num || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 * Convert a byte value to its more human readable format
 * @param bytes
 * @param decimals
 */
function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

/**
 * Return the thing passed in
 * @param x The thing
 */
function identity<T>(x: T): T {
  return x;
}

/**
 * Extract the file name from a path
 * @param path The path
 */
function getFilename(path: string, withExtension: boolean = true) {
  // eslint-disable-next-line
  path = path.replace(/^.*[\\\/]/, "");

  if (!withExtension) {
    path = path.split(".").slice(0, -1).join(".");
  }

  return decodeURIComponent(path).toLowerCase();
}

/**
 * Strip/remove the protocol and trailing slash from a URL
 * @param url URL/Address to strip
 */
function stripAddress(url: string | undefined) {
  const matchedUrl = (url || "").match(/^(http|https):\/\//i);

  let protocol: string = "https://";
  if (matchedUrl) {
    protocol = matchedUrl[0];
  }

  return `${protocol}${(url || "")
    // remove the protocol
    .replace(/^(http|https):\/\//i, "")
    // remove the trailing slash
    .replace(/\/+$/, "")}`;
}

/**
 * Generate a random number between min and max
 * @param min minumum random number
 * @param max maximum random number
 */
function randomInt(min: number = 0, max: number = 9999999999) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function capitalizeFirst(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

function isValidImage(filename: string) {
  return filename
    .split("?")[0]
    .match(/(?:png|jpg|jpeg|gif|svg|apng|avif|webp)$/i);
}

/**
 * Check to make sure two sets contain the same values
 * @param as Set A
 * @param bs Set B
 * @returns boolean indicating equality
 */
function isEqualSet<S>(as: Set<S>, bs: Set<S>) {
  return as.size === bs.size && all(isIn(bs), as);
}

function all<S>(pred: (x: S) => boolean, as: Set<S>) {
  for (var a of as) {
    if (!pred(a)) {
      return false;
    }
  }
  return true;
}

function isIn<S>(as: Set<S>) {
  return (a: S) => as.has(a);
}

/**
 * Wrap specified word with the wrapper in the text body
 * @param text The text body
 * @param wordToWrap The word to wrap
 * @param wrapper The wrapping text
 * @returns text body with wrapped words
 */
function wrapWords(
  text: string,
  wordToWrap: string,
  wrapper: string = "<b>$&</b>"
) {
  return text.replace(new RegExp(`${wordToWrap}`, "gi"), wrapper);
}

/**
 * Converts a Set of SourceNode's into a refiner used to filter the backend request
 * @param sources The sources set
 * @returns The refiner string
 */
function sourcesToRefiner(sources: Set<SourceNode>) {
  let refiner: any[] = [];
  Array.from(sources).forEach((source) => {
    const sites: Array<string> | null = source.sites?.length
      ? source.sites
      : null;
    if (source.siteUrl) {
      refiner = refiner.concat(source.siteUrl.split("/")?.pop());
    } else if (sites !== null) {
      refiner = refiner.concat(sites.map((site) => `'${getSite(site)}'`));
    }
  });
  if (refiner.length === 0) {
    return "";
  }
  return refiner.length === 1
    ? `SPSiteUrl: ${refiner.toString()}`
    : `SPSiteUrl:or(${refiner.join(",")})`;
}

/**
 * Convert a file extension/type into a refiner value used to filter the backend request
 * @param fileType The string file extension (ex: csv)
 * @returns The refiner string
 */
function fileTypeToRefiner(fileType: SelectionOption | undefined): string {
  return fileType ? `filetype:equals(${fileType.value})` : "";
}

function dateRangeToRefiner(range: [DateRange] | null) {
  if (range) {
    const startDate = `${format(
      range[0].startDate,
      "yyyy-MM-dd"
    )}T00:00:00.000Z`;
    const endDate = `${format(range[0].endDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    return `write:range(${startDate},${endDate})`;
  }
  return null;
}

function noop() {}

const amplitudeTracker = (eventName: string, eventProperties?: {}) => {
  if (eventProperties) {
    amplitude.track(eventName, eventProperties);
  } else {
    amplitude.track(eventName);
  }
};

const SPSiteUrltoRefiner = (SPSiteURL: string | undefined) => {
  if (
    SPSiteURL !== undefined &&
    SPSiteURL.includes("{") &&
    SPSiteURL.includes("}")
  ) {
    return `DepartmentId: ${SPSiteURL}`;
  }
  if (SPSiteURL !== "" || undefined) {
    return `SPSiteUrl: ${SPSiteURL}`;
  }
  return "";
};

const mailToAddressChecker = (addr: string, originalDoc: string) => {
  const otherURL = encodeURIComponent(addr);
  if (
    otherURL[otherURL.length - 1] === "D" &&
    otherURL[otherURL.length - 2] === "3" &&
    otherURL[otherURL.length - 3] === "%"
  ) {
    return `Link to the original document library: ${originalDoc}`;
  } else {
    return `Link to the original document: ${addr}`;
  }
};

export {
  themeBorder,
  timeZone,
  formatDateTime,
  formatNumber,
  formatBytes,
  identity,
  getFilename,
  stripAddress,
  randomInt,
  noop,
  capitalizeFirst,
  isValidImage,
  isEqualSet,
  wrapWords,
  sourcesToRefiner,
  fileTypeToRefiner,
  dateRangeToRefiner,
  amplitudeTracker,
  SPSiteUrltoRefiner,
  mailToAddressChecker,
};
