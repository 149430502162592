import { useEffect } from "react";
import styled from "styled-components/macro";
import { ErrorBoundary } from "react-error-boundary";
import { Container as MuiContainer, ContainerProps } from "@material-ui/core";
import { animated, config, useSpring } from "@react-spring/web";
import { useRecoilValue } from "recoil";
import HeaderBarHeight from "state/layout/HeaderBarHeight";
import ErrorDisplay from "components/ErrorDisplay";

const Container = animated(styled(MuiContainer)<{ top: number }>`
  padding-top: ${({ top, theme }) => `${top ? top : theme.spacing(2)}px`};
  padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  transform-origin: 50% 50%;
  display: grid;
`);

function PageContainer({ children, ...props }: ContainerProps) {
  const headerBarHeight = useRecoilValue(HeaderBarHeight);
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: (prop) =>
      prop === "opacity" ? { ...config.gentle, clamp: true } : config.gentle,
    delay: 100,
  });

  useEffect(() => {
    const body = document.getElementById("body");
    if (body) {
      body.scrollTop = 0;
    }
  }, []);

  return (
    <Container style={spring} top={headerBarHeight}>
      <div {...props}>
        <ErrorBoundary FallbackComponent={ErrorDisplay}>
          {children}
        </ErrorBoundary>
      </div>
    </Container>
  );
}

export default PageContainer;
