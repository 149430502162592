import { useTheme } from "@material-ui/core";
import { useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { useRecoilValueOrNull } from "services/hooks";
import ViewportDimensions from "state/layout/ViewportDimensions";
import ExpertiseCurrentProject from "state/search/expertise/ExpertiseCurrentProject";
import ExpertiseSearch, {
  useSearchSource,
} from "state/search/expertise/ExpertiseSearch";
import { columnSize } from "../Projects";

import Button from "./Button";
import ChevronLeftIcon from "./ChevronLeft";
import ChevronRightIcon from "./ChevronRight";

export interface NavButtonInterface {
  placement: "left" | "right";
  width: number;
}

export default function NavButton({ placement, width }: NavButtonInterface) {
  const [currentProject, setCurrentProject] = useRecoilState(
    ExpertiseCurrentProject
  );
  const theme = useTheme();
  const expertiseSearch = useRecoilValueOrNull(ExpertiseSearch(0));
  const projectsSource = useSearchSource(
    expertiseSearch || [],
    "ExpertiseProjects"
  );
  const projects = useMemo(() => {
    return projectsSource?.results || [];
  }, [projectsSource]);
  let { width: viewportWidth } = useRecoilValue(ViewportDimensions);
  viewportWidth =
    viewportWidth > theme.breakpoints.values.xl
      ? theme.breakpoints.values.xl
      : viewportWidth;

  const currentProjectIndex = useMemo(
    () =>
      projects.reduce((projectIndex, project, index) => {
        return project.createdDate === currentProject ? index : projectIndex;
      }, 0),
    [currentProject, projects]
  );
  const numProjectsDisplayed = Math.ceil(
    (viewportWidth - 400) / (columnSize + theme.spacing(2))
  );
  const nextProjectId = useMemo(() => {
    const next =
      projects[currentProjectIndex + numProjectsDisplayed]?.createdDate;
    if (!next && projects.length < currentProjectIndex) {
      return projects[projects.length - numProjectsDisplayed]?.createdDate;
    }
    return next;
  }, [currentProjectIndex, projects, numProjectsDisplayed]);
  const prevProjectId = useMemo(() => {
    const prev =
      projects[currentProjectIndex - numProjectsDisplayed]?.createdDate;
    if (!prev && currentProjectIndex) {
      return projects[0]?.createdDate;
    }
    return prev;
  }, [currentProjectIndex, projects, numProjectsDisplayed]);

  const disabled = useMemo(() => {
    if (placement === "left") {
      return !prevProjectId;
    } else {
      return !nextProjectId;
    }
  }, [placement, nextProjectId, prevProjectId]);

  function next() {
    if (nextProjectId && !disabled) {
      setCurrentProject(nextProjectId);
    }
  }
  function prev() {
    if (prevProjectId && !disabled) {
      setCurrentProject(prevProjectId);
    }
  }

  function action() {
    if (placement === "left") {
      prev();
    } else {
      next();
    }
  }

  return (
    <Button
      placement={placement}
      width={width}
      onClick={action}
      disableRipple={disabled}
    >
      {placement === "left" ? (
        <ChevronLeftIcon disabled={disabled} />
      ) : (
        <ChevronRightIcon disabled={disabled} />
      )}
    </Button>
  );
}
