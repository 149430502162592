import { Button } from "@material-ui/core";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";

export default styled(Button).attrs({
  size: "small",
  component: NavLink,
  variant: "text",
})<NavLinkProps>`
  padding: ${({ theme }) => `${theme.spacing(0.75)}px`};
  text-decoration: none;
  color: ${({ theme }) => theme.palette.headerText};

  &:visited {
    color: ${({ theme }) => theme.palette.headerText};
  }

  &.active {
    background: ${({ theme }) => theme.palette.primary.main};
    color: white;
    font-weight: bold;
  }

  & + & {
    margin-left: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;
