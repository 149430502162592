import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { useHasQuery } from "state/Query";

import HeaderBar from "components/HeaderBar";
import PageControls from "components/PageControls";
import PleaseEnterQuery from "components/PleaseEnterQuery";
import LoadingDisplay from "components/LoadingDisplay";

import ResultsCount from "./controls/ResultsCount";
import DateRange from "./controls/DateRange";
import FileType from "./controls/FileType";
import Export from "./controls/Export";
import Sources from "./controls/Sources";

import PageContainer from "./PageContainer";
import Area from "./Area";
import Results from "./Results";
import Images from "./Images";
import ReportData from "components/ReportData";
import { useRecoilValueLoadable } from "recoil";
import AllSearch, { spoGroup, useRefiners } from "state/search/all/AllSearch";
import Refiners from "components/Refiners";

// The page path relative to the application root
const path = "/all";

function AllPage() {
  const { t } = useTranslation();
  const hasQuery = useHasQuery();
  const firstPageLoadable = useRecoilValueLoadable(AllSearch(0));

  if (!hasQuery) {
    return <PleaseEnterQuery />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("All")} - {t("APP_NAME")}
        </title>
      </Helmet>
      <HeaderBar>
        <PageControls
          left={[<ResultsCount />]}
          // center={[<DateRange />, <FileType />, <Export />, <ReportData />, 
          //   <Refiners isLoading={ firstPageLoadable.state === "loading" } spoGroup={spoGroup} useSharePointRefiners={useRefiners} />]}
          // right={[<Sources />]}
          center={[<DateRange />, <FileType />, <Export />, <ReportData />]}
          right={[<Sources />]}
        />
      </HeaderBar>
      <PageContainer>
        <Area area="results">
          <Results />
        </Area>
        <Area area="tabs">
          <Suspense
            fallback={<LoadingDisplay style={{ minHeight: "400px" }} />}
          >
            <Images />
          </Suspense>
        </Area>
      </PageContainer>
    </>
  );
}

export { path };
export default AllPage;
