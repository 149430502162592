import { Typography } from "@material-ui/core";
import { HTMLProps } from "react";
import styled from "styled-components/macro";

export default styled(Typography).attrs({ component: "a" })<
  HTMLProps<HTMLAnchorElement>
>`
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.background.paper)};
  &:visited,
  &:hover {
    color: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.background.paper)};
  }

  &:hover {
    text-decoration: underline;
  }
`;
