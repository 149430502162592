import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useHasQuery } from "state/Query";
import ExpertiseProjectSearch from "state/search/expertise/ExpertiseProjectSearch";
import ExpertiseLayout from "state/search/expertise/ExpertiseLayout";

import PleaseEnterQuery from "components/PleaseEnterQuery";
import HeaderBar from "components/HeaderBar";
import PageControls from "components/PageControls";

import ResultsCount from "./controls/ResultsCount";
import SortBy from "./controls/SortBy";
import Business from "./controls/Business";
import LineOfBusiness from "./controls/LineOfBusiness";
import ProjectSearch from "./controls/ProjectSearch";
import Layout from "./controls/Layout";

import PageContainer from "./PageContainer";
import TopProjects from "./TopProjects";
import SearchResults from "./SearchResults";

// The page path relative to the application root
export const path = "/expertise";

export default function ExpertisePage() {
  const { t } = useTranslation();
  const hasQuery = useHasQuery();
  const isProjectSearch = useRecoilValue(ExpertiseProjectSearch);
  const layout = useRecoilValue(ExpertiseLayout);

  if (!hasQuery) {
    return <PleaseEnterQuery />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("Expertise")} - {t("APP_NAME")}
        </title>
      </Helmet>
      <HeaderBar>
        <PageControls
          left={[<ResultsCount />]}
          center={
            isProjectSearch
              ? undefined
              : [<Business />, <LineOfBusiness />, <SortBy />]
          }
          right={[<Layout />, <ProjectSearch />]}
        />
      </HeaderBar>
      <PageContainer layout={layout}>
        <TopProjects layout={layout} />
        <SearchResults />
      </PageContainer>
    </>
  );
}
