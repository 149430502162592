import { Response } from "state/search/expertise/ExpertisePersonProjects";

const documents: Response = [
  {
    UserAlias: null,
    ProjectName: "DW_Regional - USGDEC (1) - USBMBC - USBES0",
    ProjectSource: "nDI",
    ProjectCount: 55,
    DataPath: null,
    DataType: null,
    CreatedDate: "2020-11-23T10:09:39.987366",
    ModifiedDate: "2020-12-03T16:07:46.041147",
  },
  {
    UserAlias: null,
    ProjectName: "New Project (1)",
    ProjectSource: "nDI",
    ProjectCount: 9,
    DataPath: null,
    DataType: null,
    CreatedDate: "2020-11-19T20:49:05.454532",
    ModifiedDate: "2021-04-06T10:12:42.965466",
  },
  {
    UserAlias: null,
    ProjectName: "AJS_EGoMSynthesis",
    ProjectSource: "nDI",
    ProjectCount: 5,
    DataPath: null,
    DataType: null,
    CreatedDate: "2014-01-02T17:30:07.211053",
    ModifiedDate: "2021-02-02T18:16:43.363891",
  },
  {
    UserAlias: null,
    ProjectName: "RegionalBasinModel",
    ProjectSource: "nDI",
    ProjectCount: 4,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-11-21T14:51:11.812242",
    ModifiedDate: "2013-11-21T15:09:25.793971",
  },
  {
    UserAlias: null,
    ProjectName: "AJS_Isopachs_8_23_13",
    ProjectSource: "nDI",
    ProjectCount: 5,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-08-23T10:03:44.978551",
    ModifiedDate: "2013-11-14T14:49:45.739424",
  },
  {
    UserAlias: null,
    ProjectName: "Orion Sculpt",
    ProjectSource: "nDI",
    ProjectCount: 12,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-08-01T15:17:22.57925",
    ModifiedDate: "2013-11-12T16:53:18.000432",
  },
  {
    UserAlias: null,
    ProjectName: "AaronErinOrion",
    ProjectSource: "nDI",
    ProjectCount: 4,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-07-15T16:17:40.221899",
    ModifiedDate: "2013-07-22T10:17:16.814726",
  },
  {
    UserAlias: null,
    ProjectName: "AaronErinEman",
    ProjectSource: "nDI",
    ProjectCount: 3,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-07-15T15:30:05.119314",
    ModifiedDate: "2013-07-15T16:01:27.88519",
  },
  {
    UserAlias: null,
    ProjectName: "AJS_REG_7_13b",
    ProjectSource: "nDI",
    ProjectCount: 43,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-07-01T13:17:54.01481",
    ModifiedDate: "2014-02-04T16:00:27.517083",
  },
  {
    UserAlias: null,
    ProjectName: "Orion",
    ProjectSource: "nDI",
    ProjectCount: 9,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-04-09T16:35:35.997908",
    ModifiedDate: "2013-09-27T11:07:47.222582",
  },
  {
    UserAlias: null,
    ProjectName: "Dummy Session",
    ProjectSource: "nDI",
    ProjectCount: 39,
    DataPath: null,
    DataType: null,
    CreatedDate: "2013-04-09T16:26:03.181606",
    ModifiedDate: "2013-04-09T16:26:18.795114",
  },
  {
    UserAlias: null,
    ProjectName: "Region3D HerBoxEman",
    ProjectSource: "nDI",
    ProjectCount: 32,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-12-03T15:03:02",
    ModifiedDate: "2013-05-30T15:23:28.020147",
  },
  {
    UserAlias: null,
    ProjectName: "AJS_E_ManateeC",
    ProjectSource: "nDI",
    ProjectCount: 23,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-05-15T14:00:07",
    ModifiedDate: "2013-03-08T10:56:17.474191",
  },
  {
    UserAlias: null,
    ProjectName: "AJS_E_ManateeB",
    ProjectSource: "nDI",
    ProjectCount: 18,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-02-21T15:17:18",
    ModifiedDate: "2013-07-10T18:19:42.651066",
  },
  {
    UserAlias: null,
    ProjectName: "AJS_E_manatee",
    ProjectSource: "nDI",
    ProjectCount: 2,
    DataPath: null,
    DataType: null,
    CreatedDate: "2012-02-21T15:06:47",
    ModifiedDate: "2012-11-30T14:09:46",
  },
  {
    UserAlias: null,
    ProjectName: "AJS_Pinnacle_Seismic_12_8_11",
    ProjectSource: "nDI",
    ProjectCount: 1,
    DataPath: null,
    DataType: null,
    CreatedDate: "2011-12-08T15:58:18",
    ModifiedDate: "2011-12-09T17:29:58",
  },
  {
    UserAlias: null,
    ProjectName: "AJS_Horizons_Pinnacle",
    ProjectSource: "nDI",
    ProjectCount: 37,
    DataPath: null,
    DataType: null,
    CreatedDate: "2011-12-08T15:34:58",
    ModifiedDate: "2011-12-08T15:39:53",
  },
  {
    UserAlias: null,
    ProjectName: "AJS_Pinnacle_Archive",
    ProjectSource: "nDI",
    ProjectCount: 42,
    DataPath: null,
    DataType: null,
    CreatedDate: "2011-11-17T17:38:07",
    ModifiedDate: "2011-12-08T15:41:25",
  },
];

export default documents;
