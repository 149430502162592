import { Response } from "state/search/all/AllSearch";

const documents: Response = [
  {
    source: "SPO",
    rowCount: 1,
    totalRows: 1,
    totalRowsIncludingDuplicates: 1,
    refinerResults: [
      {
        entries: [
          {
            RefinementCount: 1,
            RefinementName: "Older than a year",
            RefinementToken: "range(min, 2020-08-27T00:00:00.0000000Z)",
            RefinementValue: "Before 2020-08-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Year",
            RefinementToken:
              "range(2020-08-27T00:00:00.0000000Z, 2021-07-27T00:00:00.0000000Z)",
            RefinementValue:
              "From 2020-08-27T00:00:00.0000000Z up to 2021-07-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past Month",
            RefinementToken:
              "range(2021-07-27T00:00:00.0000000Z, 2021-08-26T00:00:00.0000000Z)",
            RefinementValue:
              "From 2021-07-27T00:00:00.0000000Z up to 2021-08-26T00:00:00.0000000Z",
          },
          {
            RefinementCount: 0,
            RefinementName: "Past 24 hours",
            RefinementToken:
              'range(2021-08-26T00:00:00.0000000Z, max, to="le")',
            RefinementValue: "2021-08-26T00:00:00.0000000Z or later",
          },
        ],
        name: "Write",
      },
      {
        entries: [
          {
            RefinementCount: 1,
            RefinementName: "docx",
            RefinementToken: '"ǂǂ646f6378"',
            RefinementValue: "docx",
          },
        ],
        name: "FileType",
      },
    ],
    results: [
      {
        docId: 17597597464605,
        dlcDocId: "AAAAA1351-724471295-156",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaaa1351",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={a0994024-bc1d-401a-a69e-8971651a5b93}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author:
          "Matthijs.Vanwinden2@shell.com;Erwin.Adams@shell.com;Luuk.L.Kleipool@shell.com;Ghassen.Chaari@shell.com;van Winden, Matthijs E SIEP-UPX/M/V",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaaa1351",
        siteTitle: "Exploration Conference",
        people:
          "Matthijs.Vanwinden2@shell.com | van Winden, Matthijs E SIEP-UPX/M/V | 693A30232E667C6D656D626572736869707C6D61747468696A732E76616E77696E64656E32407368656C6C2E636F6D i:0#.f|membership|matthijs.vanwinden2@shell.com;Matthijs.Vanwinden2@shell.com;Erwin.Adams@shell.com;Luuk.L.Kleipool@shell.com;Ghassen.Chaari@shell.com;van Winden, Matthijs E SIEP-UPX/M/V",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/_layouts/15/Doc.aspx?sourcedoc={a0994024-bc1d-401a-a69e-8971651a5b93}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.docx.svg",
        title: "2016 SIPMAP workshop Abstract Template",
        path: "https://eu001-sp.shell.com/sites/AAAAA1351/expconf/Exploration Conference 2020/Abstract Submission/UPX-M-V_M_vanWinden_Basal_Ara_Carbonate_Play_North_Oman.docx",
        summary:
          "Evaluating the remaining exploration potential and <ddd/> the Birba area of the South Oman Salt <strong>Basin</strong> (SOSB <ddd/> ...",
        resultType: "docx",
        size: 887056,
        write: "2020-04-03T15:02:00.0000000Z",
        rank: 0.0,
      },
    ],
    spellingSuggestion: "",
  },
];

export default documents;
