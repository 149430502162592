import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import CurrentTime from "state/CurrentTime";

/**
 * Periodically update the CurrentTime so that the user
 * token_expiration can take effect.
 */
function SyncCurrentTime() {
  const setCurrentTime = useSetRecoilState(CurrentTime);
  useEffect(() => {
    const currentTimeInterval = setInterval(
      () => setCurrentTime(new Date().getTime()),
      1000 // every 1 second
    );
    return () => clearInterval(currentTimeInterval);
  }, [setCurrentTime]);
  return null;
}

export default SyncCurrentTime;
