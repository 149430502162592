import { Response } from "state/search/all/AllSearch";

const data: Response = [
  {
    source: "SPO",
    rowCount: 10,
    totalRows: 1942478,
    totalRowsIncludingDuplicates: 1942478,
    refinerResults: [
      {
        entries: [
          {
            RefinementCount: 158096,
            RefinementName: "aspx",
            RefinementToken: '"ǂǂ61737078"',
            RefinementValue: "aspx",
          },
          {
            RefinementCount: 15468,
            RefinementName: "csv",
            RefinementToken: '"ǂǂ637376"',
            RefinementValue: "csv",
          },
          {
            RefinementCount: 129189,
            RefinementName: "doc",
            RefinementToken: '"ǂǂ646f63"',
            RefinementValue: "doc",
          },
          {
            RefinementCount: 81124,
            RefinementName: "docx",
            RefinementToken: '"ǂǂ646f6378"',
            RefinementValue: "docx",
          },
          {
            RefinementCount: 18557,
            RefinementName: "htm",
            RefinementToken: '"ǂǂ68746d"',
            RefinementValue: "htm",
          },
          {
            RefinementCount: 117307,
            RefinementName: "msg",
            RefinementToken: '"ǂǂ6d7367"',
            RefinementValue: "msg",
          },
          {
            RefinementCount: 767232,
            RefinementName: "pdf",
            RefinementToken: '"ǂǂ706466"',
            RefinementValue: "pdf",
          },
          {
            RefinementCount: 72929,
            RefinementName: "ppt",
            RefinementToken: '"ǂǂ707074"',
            RefinementValue: "ppt",
          },
          {
            RefinementCount: 75535,
            RefinementName: "pptx",
            RefinementToken: '"ǂǂ70707478"',
            RefinementValue: "pptx",
          },
          {
            RefinementCount: 29023,
            RefinementName: "txt",
            RefinementToken: '"ǂǂ747874"',
            RefinementValue: "txt",
          },
          {
            RefinementCount: 151375,
            RefinementName: "xls",
            RefinementToken: '"ǂǂ786c73"',
            RefinementValue: "xls",
          },
          {
            RefinementCount: 80724,
            RefinementName: "xlsm",
            RefinementToken: '"ǂǂ786c736d"',
            RefinementValue: "xlsm",
          },
          {
            RefinementCount: 186391,
            RefinementName: "xlsx",
            RefinementToken: '"ǂǂ786c7378"',
            RefinementValue: "xlsx",
          },
          {
            RefinementCount: 9441,
            RefinementName: "xml",
            RefinementToken: '"ǂǂ786d6c"',
            RefinementValue: "xml",
          },
          {
            RefinementCount: 34566,
            RefinementName: "zip",
            RefinementToken: '"ǂǂ7a6970"',
            RefinementValue: "zip",
          },
        ],
        name: "FileType",
      },
      {
        entries: [
          {
            RefinementCount: 1845932,
            RefinementName: "Older than a year",
            RefinementToken: "range(min, 2020-07-28T00:00:00.0000000Z)",
            RefinementValue: "Before 2020-07-28T00:00:00.0000000Z",
          },
          {
            RefinementCount: 87883,
            RefinementName: "Past Year",
            RefinementToken:
              "range(2020-07-28T00:00:00.0000000Z, 2021-06-28T00:00:00.0000000Z)",
            RefinementValue:
              "From 2020-07-28T00:00:00.0000000Z up to 2021-06-28T00:00:00.0000000Z",
          },
          {
            RefinementCount: 7840,
            RefinementName: "Past Month",
            RefinementToken:
              "range(2021-06-28T00:00:00.0000000Z, 2021-07-27T00:00:00.0000000Z)",
            RefinementValue:
              "From 2021-06-28T00:00:00.0000000Z up to 2021-07-27T00:00:00.0000000Z",
          },
          {
            RefinementCount: 823,
            RefinementName: "Past 24 hours",
            RefinementToken:
              'range(2021-07-27T00:00:00.0000000Z, max, to="le")',
            RefinementValue: "2021-07-27T00:00:00.0000000Z or later",
          },
        ],
        name: "Write",
      },
    ],
    results: [
      {
        docId: 17599776894824,
        dlcDocId: "AAAAA9061-3-37875",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaaa9061",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAA9061",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAA9061/_layouts/15/WopiFrame.aspx?sourcedoc={dcfe8d01-9d15-4283-ab82-cad8755c6437}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SharePoint App",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaaa9061",
        siteTitle: "EP Catalog - Europe sub-surface and wells restricted",
        people:
          " | SharePoint App | 693A30692E747C30303030303030332D303030302D306666312D636530302D3030303030303030303030307C617070407368617265706F696E74 i:0i.t|00000003-0000-0ff1-ce00-000000000000|app@sharepoint;SharePoint App",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAA9061/_layouts/15/WopiFrame.aspx?sourcedoc={dcfe8d01-9d15-4283-ab82-cad8755c6437}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAA9061/Restricted/Forms/AllItems.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title:
          "Petroleum geological atlas of the Southern Permian Basin area : SPBA GIS Maps and Database",
        path: "https://eu001-sp.shell.com/sites/AAAAA9061/Restricted/SPBA-HR.pdf",
        summary:
          "appraisal;Regional evaluation;<strong>Basin</strong> report;<strong>Basin</strong> evaluation;<strong>Basin</strong> study;<strong>Basin</strong> analysis;<strong>Basin</strong> determination;<strong>Basin</strong> interpretation;<strong>Basin</strong> review;<strong>Basin</strong> appraisal;<strong>Basin</strong> assessment <ddd/> ...",
        resultType: "pdf",
        size: 1072870894,
        write: "2020-05-26T09:01:18.0000000Z",
        rank: 6127.62255859375,
      },
      {
        docId: 17592585357994,
        dlcDocId: "AAAAA1351-61-1360",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaaa1351",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAA1351",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAA1351/_layouts/15/WopiFrame.aspx?sourcedoc={227ba14c-d595-4938-9963-10413bbb59cf}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author:
          "Michael Enachescu* and Stephen Kearsey, Memorial University of Newfoundland; John Hogg, EnCana; Paul Einarsson, and Sam Nader, GSI; Jerry Smee, ExAlta;Nalubiri, Agnes G SIEP-UPX",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaaa1351",
        siteTitle: "Exploration Excellence",
        people:
          "Agnes.Nalubiri@shell.com | Nalubiri, Agnes G SIEP-UPX | 693A30232E777C6575726F70655C61676E65732E6E616C7562697269 i:0#.w|europe\\agnes.nalubiri;Michael Enachescu* and Stephen Kearsey, Memorial University of Newfoundland; John Hogg, EnCana; Paul Einarsson, and Sam Nader, GSI; Jerry Smee, ExAlta;Nalubiri, Agnes G SIEP-UPX",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/_layouts/15/WopiFrame.aspx?sourcedoc={227ba14c-d595-4938-9963-10413bbb59cf}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAA1351/ETAF/Pre 2010 Deep Dive Files Document Set",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "2004_Enachescu_Orphan_Basin_Petroleum_System_SEG",
        path: "https://eu001-sp.shell.com/sites/AAAAA1351/ETAF/Pre 2010 Deep Dive Files Document Set/2004_Enachescu_Orphan_Basin_Petroleum_System_SEG.pdf",
        summary:
          "well ties and regional tectonics, the West Orphan <strong>Basin</strong> is gas prone while the East Orphan <strong>Basin</strong> has a petroleum system similar to <strong>basins</strong> on the Grand Banks and West Ireland <ddd/> ...",
        resultType: "pdf",
        size: 335602,
        write: "2004-08-20T22:43:30.0000000Z",
        rank: 6127.62109375,
      },
      {
        docId: 17592933472377,
        dlcDocId: "AAAAB0332-3-1716",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0332",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0332",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0332/_layouts/15/WopiFrame.aspx?sourcedoc={b33473f9-ec9c-49db-8e88-5d964ae4c653}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S;Ros, Arco AC SIEP-PTU/B/E",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0332",
        siteTitle: "EP Catalog - SIEP Map Management",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;Arco.Ros@shell.com | Ros, Arco AC SIEP-PTU/B/E | 693A30232E667C6D656D626572736869707C6172636F2E726F73407368656C6C2E636F6D i:0#.f|membership|arco.ros@shell.com;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0332/_layouts/15/WopiFrame.aspx?sourcedoc={b33473f9-ec9c-49db-8e88-5d964ae4c653}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0332/Restricted/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title:
          "Global Unconventionals Assessment, Gravity, Erlian Basin, EP 2007-5119",
        path: "https://eu001-sp.shell.com/sites/AAAAB0332/Restricted/EP200911302875001.pdf",
        summary:
          "and Onon-Argun Fold Belts 0 Ordos <strong>Basin</strong> A <ddd/> Low : -582.599976 Cross-sections (Tellus) UG <strong>Basins</strong> Screened UG <strong>BASINS</strong> Hotspots Gas type <strong>Basin</strong> Centered Gas Tight Gas Shale Gas; Shale <ddd/> ...",
        resultType: "pdf",
        size: 940113,
        write: "2015-10-13T20:06:02.0000000Z",
        rank: 6127.6142578125,
      },
      {
        docId: 17592765701060,
        dlcDocId: "AAAAB0277-3-10946",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0277",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0277",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0277/_layouts/15/WopiFrame.aspx?sourcedoc={7905c406-8ad6-44d6-a14d-b0d5e71c4ac5}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "kw97192;SP-P-EPCATALOG-S",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0277",
        siteTitle: "Exploration Europe 02",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;kw97192;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0277/_layouts/15/WopiFrame.aspx?sourcedoc={7905c406-8ad6-44d6-a14d-b0d5e71c4ac5}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0277/Lib01/Southeast Asia basin opportunities ; Bangladesh ; Bengal Basin Volume 10",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title:
          "Southeast Asia basin opportunities ; Bangladesh ; Bengal Basin Volume 10",
        path: "https://eu001-sp.shell.com/sites/AAAAB0277/Lib01/Southeast Asia basin opportunities ; Bangladesh ; Bengal Basin Volume 10/Energy Studies Catalogue.pdf",
        summary:
          "and economic viability of any country, <strong>basin</strong> or region in the world; helping you to <ddd/> histories of selected African lacustrine <strong>basins</strong> and reviews Africa from the standpoint of <ddd/> ...",
        resultType: "pdf",
        size: 1797149,
        write: "2004-06-25T14:41:33.0000000Z",
        rank: 6127.61181640625,
      },
      {
        docId: 17592580377407,
        dlcDocId: "AAAAX0037-4-1853",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaax0037",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAX0037",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={04c77cf0-9391-487e-a67e-f0bab1699831}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "System Account;Ouwendijk, Dennis SITI-PTD/B/T",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaax0037",
        siteTitle: "Global Library Collection - Site 6-1",
        people:
          "Dennis.Ouwendijk@shell.com | Ouwendijk, Dennis SITI-PTD/B/T | 693A30232E667C6D656D626572736869707C64656E6E69732E6F7577656E64696A6B407368656C6C2E636F6D i:0#.f|membership|dennis.ouwendijk@shell.com; | System Account | 5348415245504F494E545C73797374656D SHAREPOINT\\system;System Account",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={04c77cf0-9391-487e-a67e-f0bab1699831}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION WESTERN",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "4001256283\\400 BARENTS BASIN.PDF",
        path: "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION WESTERN/4001256283_400 BARENT.PDF",
        summary:
          "Agean Basin &amp; Thrace Basin Adriatic <strong>Basin</strong> Apulian-Gargano Platform Po <strong>Basin</strong> Thyrrenian <strong>Basin</strong> Castjlla La Vieja <strong>Basin</strong> Lusjtanjan <strong>Basin</strong> Guadalquivir <strong>Basin</strong> 30° 60° 90 P <ddd/> ...",
        resultType: "PDF",
        size: 1876513,
        write: "2020-05-08T07:16:12.0000000Z",
        rank: 6127.6083984375,
      },
      {
        docId: 17592670088781,
        dlcDocId: "AAAAB0324-5-5445",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0324",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0324",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0324/_layouts/15/WopiFrame.aspx?sourcedoc={be758ea8-24b0-4b13-8fcd-dc098853269c}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0324",
        siteTitle: "EPE Subsurface and Wells - Site 03",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0324/_layouts/15/WopiFrame.aspx?sourcedoc={be758ea8-24b0-4b13-8fcd-dc098853269c}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0324/Lib03/Forms/AllItems.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title:
          "Stratigraphy and tectonics of the vlieland basin TERSCHELLING-W- 1 18-12-1988",
        path: "https://eu001-sp.shell.com/sites/AAAAB0324/Lib03/00000351.pdf",
        summary:
          "it is suggested that the Vlieland <strong>basin</strong> is a pull-apart <strong>basin</strong>, related tectonically only within a regional context to the central North Sea graben and the Lower Saxony <strong>basin</strong> <ddd/> ...",
        resultType: "pdf",
        size: 1526147,
        write: "2015-06-20T20:10:01.0000000Z",
        rank: 6127.58251953125,
      },
      {
        docId: 17592296236378,
        dlcDocId: "AAAAB0277-3-5835",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0277",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0277",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0277/_layouts/15/WopiFrame.aspx?sourcedoc={60a526a5-76eb-4ae8-add4-4d6db8b59bf3}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0277",
        siteTitle: "Exploration Europe 02",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0277/_layouts/15/WopiFrame.aspx?sourcedoc={60a526a5-76eb-4ae8-add4-4d6db8b59bf3}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0277/Lib01/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "The Halibut Basin project",
        path: "https://eu001-sp.shell.com/sites/AAAAB0277/Lib01/00001E75.pdf",
        summary:
          "<strong>Basin</strong> subsidence together with a Shell interests eustatic rise in sea level during the Late Jurassic and Halibut <strong>Basin</strong> Project Cretaceous allowed thick sediments to <ddd/> ...",
        resultType: "pdf",
        size: 3398402,
        write: "2015-08-10T14:52:59.0000000Z",
        rank: 6127.5810546875,
      },
      {
        docId: 17592975810578,
        dlcDocId: "AAAAB0277-3-1418",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaab0277",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAB0277",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAB0277/_layouts/15/WopiFrame.aspx?sourcedoc={59c10881-17e2-44a8-97c2-20ebc663ce66}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "SP-P-EPCATALOG-S",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaab0277",
        siteTitle: "Exploration Europe 02",
        people:
          "SP-P-EPCATALOG-S@SHELL.com | SP-P-EPCATALOG-S | 693A30232E777C6575726F70655C73702D702D6570636174616C6F672D73 i:0#.w|europe\\sp-p-epcatalog-s;SP-P-EPCATALOG-S",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0277/_layouts/15/WopiFrame.aspx?sourcedoc={59c10881-17e2-44a8-97c2-20ebc663ce66}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAB0277/Lib01/Forms/All Items.aspx",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "Central North Sea. Forth approaches Basin. Basin review.",
        path: "https://eu001-sp.shell.com/sites/AAAAB0277/Lib01/00001B16.pdf",
        summary:
          "3 CONFIDENTIAL -Forth Approaches <strong>Basin</strong> Review INTRODUCTION The Forth Approaches <strong>Basin</strong> is the offshore continuation of the <ddd/> The structural history of the <strong>basin</strong> is complex <ddd/> ...",
        resultType: "pdf",
        size: 34426345,
        write: "2002-06-12T12:55:43.0000000Z",
        rank: 6127.57470703125,
      },
      {
        docId: 17592976777635,
        dlcDocId: "AAAAX0037-4-1533",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaax0037",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAX0037",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={8b3ebdbb-2d91-4e15-b0d2-5608c747d51e}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "System Account;Ouwendijk, Dennis SITI-PTD/B/T",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaax0037",
        siteTitle: "Global Library Collection - Site 6-1",
        people:
          "Dennis.Ouwendijk@shell.com | Ouwendijk, Dennis SITI-PTD/B/T | 693A30232E667C6D656D626572736869707C64656E6E69732E6F7577656E64696A6B407368656C6C2E636F6D i:0#.f|membership|dennis.ouwendijk@shell.com; | System Account | 5348415245504F494E545C73797374656D SHAREPOINT\\system;System Account",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={8b3ebdbb-2d91-4e15-b0d2-5608c747d51e}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION - WESTER_1",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "2050091136\\400 BARENTS BASIN.PDF",
        path: "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION - WESTER_1/2050091136_400 BARENT.PDF",
        summary:
          "Basin Gulf of Gascogne Aquitaine <strong>Basin</strong> 430 Rhone Bresse <strong>Basin</strong> 431 Vienna <strong>Basin</strong> 432 Pannonian <strong>Basin</strong> Transylvanlan <strong>Basin</strong> Agean <strong>Basin</strong> &amp; Thrace <strong>Basin</strong> Adriatic <strong>Basin</strong> 436 <ddd/> ...",
        resultType: "PDF",
        size: 3093096,
        write: "2020-05-08T07:16:15.0000000Z",
        rank: 6127.5673828125,
      },
      {
        docId: 17592666798774,
        dlcDocId: "AAAAX0037-4-1124",
        siteUrl: "https://eu001-sp.shell.com/sites/aaaax0037",
        webUrl: "https://eu001-sp.shell.com/sites/AAAAX0037",
        ServerRedirectedEmbedURL:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={28756793-b65d-42f4-abf4-eb2046a0ffcd}&action=interactivepreview",
        ServerRedirectedPreviewURL: null,
        ServerRedirectedURL: null,
        Author: "System Account;Ouwendijk, Dennis SITI-PTD/B/T",
        Description: null,
        SiteName: "https://eu001-sp.shell.com/sites/aaaax0037",
        siteTitle: "Exploration Library Reports",
        people:
          "Dennis.Ouwendijk@shell.com | Ouwendijk, Dennis SITI-PTD/B/T | 693A30232E667C6D656D626572736869707C64656E6E69732E6F7577656E64696A6B407368656C6C2E636F6D i:0#.f|membership|dennis.ouwendijk@shell.com; | System Account | 5348415245504F494E545C73797374656D SHAREPOINT\\system;System Account",
        displayUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/_layouts/15/WopiFrame.aspx?sourcedoc={28756793-b65d-42f4-abf4-eb2046a0ffcd}&action=interactivepreview",
        parentUrl:
          "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION - EASTER_1",
        thumbnailUrl: null,
        iconUrl: "/content/images/icon.pdf.svg",
        title: "2050092540\\TINDOUF BASIN BASIN STRAT COLUMN.PDF",
        path: "https://eu001-sp.shell.com/sites/AAAAX0037/EPX83/BASIN EVALUATION - EASTER_1/2050092540_TINDOUF BA.PDF",
        summary:
          "ANTICLINES ON THE NORTH FLANK OF THE <strong>BASIN</strong>; 2) BURIED DEVONIAN REEFS IN SOUTH PART OF <strong>BASIN</strong>; 31 CAMBRIAN SANDSTONE PINCHOUF ON PALEOSTRUCTURES ON SOUTH FLANK OF <strong>BASIN</strong> <ddd/> ...",
        resultType: "PDF",
        size: 10695676,
        write: "2020-05-08T07:47:15.0000000Z",
        rank: 6127.56689453125,
      },
    ],
    spellingSuggestion: "",
  },
];

export default data;
