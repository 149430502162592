import Container from "./Container";
import InfoIcon from "@material-ui/icons/Info";

export default function ErrorDisplay() {
  return (
    <Container>
      <InfoIcon /> Shared drive access not available
    </Container>
  );
}
