import { selector } from "recoil";
import axios, { AxiosResponse } from "axios";

import env from "services/env";
import { useAPI, siftAPI } from "services/network";
import {
  getPKCECodeVerifier,
  tokenUrl,
  redirectURI,
  scope,
} from "services/auth";
import RedirectCode from "./RedirectCode";
import TestLoginUser from "./TestLoginUser";

// https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow#successful-response-2
export interface Token {
  token_type: "Bearer";
  access_token: string;
  expires_in: number;
  expires_at: string;
  scope: string;
  refresh_token: string;
  id_token: string;
}

export interface TestToken {
  token_type: "Test";
  expires_at: string;
}

interface TokenRequest {
  client_id: string;
  scope: string;
  code: string;
  redirect_uri: string;
  grant_type: string;
  code_verifier: string;
}

export default selector<Token | TestToken | null>({
  key: "Token",
  get: async ({ get }): Promise<Token | TestToken | null> => {
    const code = get(RedirectCode);
    const localToken = localStorage.getItem("token");
    let token: Token | TestToken | null = null;

    if (get(TestLoginUser)) {
      // override login and enter as a test user
      token = {
        token_type: "Test",
        expires_at: "2120-11-10T00:27:25.183227499Z",
      };
    } else if (localToken) {
      token = JSON.parse(localToken);
    } else if (code) {
      token = {
        ...(
          await axios.post<TokenRequest, AxiosResponse<Token>>(
            tokenUrl,
            new URLSearchParams({
              client_id: env.REACT_APP_AAD_APP_CLIENTID,
              scope,
              code,
              redirect_uri: redirectURI,
              grant_type: "authorization_code",
              code_verifier: getPKCECodeVerifier() as string,
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
        ).data,
        token_type: "Bearer",
      };

      const currentTime = new Date().getTime();
      const secondsTillExpiration = token.expires_in * 1000;
      token.expires_at = new Date(
        currentTime + secondsTillExpiration
      ).toISOString();

      if (env.REACT_APP_ENVIRONMENT === "local") {
        // Save token data to localStorage (local dev only)
        localStorage.setItem("token", JSON.stringify(token));
      }
    } else {
      return new Promise(() => {});
    }

    if (token?.token_type === "Bearer") {
      useAPI.defaults.headers.common = {
        Authorization: `Bearer ${token.access_token}`,
      };
      siftAPI.defaults.headers.common = {
        Authorization: `Bearer ${token.access_token}`,
      };
    }
    return token;
  },
});
