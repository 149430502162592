import { atom } from "recoil";
import { SourceNode } from "./SourceTree";

/**
 * Keeps track of the current selected sources
 */
export default atom<Set<SourceNode>>({
  key: "SelectedSources",
  default: new Set<SourceNode>(),
});
