import { ChangeEvent } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Switch, Tooltip } from "@material-ui/core";

import { useChangeQueryString } from "services/hooks";
import ProjectSearch from "state/search/expertise/ExpertiseProjectSearch";

import Label from "./Label";

export default function ProjectSearchControl() {
  const { t } = useTranslation();
  const changeQueryString = useChangeQueryString();
  const projectSearch = useRecoilValue(ProjectSearch);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    changeQueryString({ project: String(event.target.checked) });
  }

  return (
    <Tooltip
      title={
        <>
          Only search people working
          <br />
          on respective projects.
        </>
      }
    >
      <Label>
        <Switch
          checked={projectSearch}
          onChange={handleChange}
          name="project-search"
        />
        <div>{t("Projects Search")}</div>
      </Label>
    </Tooltip>
  );
}
