import { Paper } from "@material-ui/core";
import { animated } from "@react-spring/web";
import styled from "styled-components/macro";

export default animated(styled(Paper)`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  margin: ${({ theme }) => `${theme.spacing(2)}px`};
  grid-row-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  place-items: center;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  background: ${({ theme }) => theme.palette.background.default};
  transform-origin: 50% 50%;
`);
